import { useIntlUtils } from "../../hooks/useIntlUtils";
import { isPageCreateBook } from "common/helpers";
import ChangeHostessModal from "components/modals/change-hostess/change-hostess";
import ChangeUserModal from "components/modals/change-user/change-user";
import ChangesParamsModal from "components/modals/changes-params/changes-params";
import ConfirmDialogModal from "components/modals/confirm-dialog/confirm-dialog";
import { EditStatus } from "components/modals/edit-status";
import { restaurantSelector } from "features/AppContex";
import { useDeleteUserMutation } from "features/api/user-api";
import { ChangesParams } from "models/common";
import {
  SettingsHostess,
  SettingsStatus,
  SettingsUser,
} from "models/settings.model";
import React from "react";
import { useSelector } from "react-redux";
import { TypeModal } from "types/rootState";

interface ModalProps {
  active?: boolean;
  closeModals: (isForce?: boolean) => void;
  activeTypeModal?: TypeModal;
  settingsParamsChanges?: ChangesParams[];
  settingsHostessModal?: SettingsHostess;
  settingsUserModal?: SettingsUser;
  settingsEditStatusModal?: SettingsStatus;
  confirmDialog?: { data: any; mode: string };
}

export default function LegacyModal(props: ModalProps) {
  const intlUtils = useIntlUtils();
  const {
    active,
    closeModals,
    activeTypeModal,
    settingsParamsChanges,
    settingsHostessModal,
    confirmDialog,
    settingsUserModal,
    settingsEditStatusModal,
  } = props;
  const [deleteUser] = useDeleteUserMutation();

  const handleClick = () => {
    if (!confirmDialog?.data) return;
    deleteUser(confirmDialog.data)
      .unwrap()
      .then(() => closeModals(true));
  };

  const restaurant = useSelector(restaurantSelector);
  return (
    <div
      className={`${active ? "active" : ""}
                ${isPageCreateBook() ? "create" : ""}
                ${activeTypeModal || ""}`}
      id="modal"
      onMouseDown={() => closeModals()}
    >
      <div>
        {activeTypeModal === TypeModal.changesParams &&
          settingsParamsChanges && (
            <ChangesParamsModal
              changesParams={settingsParamsChanges}
              closeModal={() => closeModals()}
            />
          )}

        {activeTypeModal === TypeModal.changeHostess && (
          <ChangeHostessModal
            closeModal={(isForceUpdate?: boolean) => closeModals(isForceUpdate)}
            intlUtils={intlUtils}
            settingsHostess={{
              ...settingsHostessModal,
              restId: settingsHostessModal?.restId || restaurant.restaurant_id,
            }}
          />
        )}

        {activeTypeModal === TypeModal.changeUser && settingsUserModal && (
          <ChangeUserModal
            closeModal={(isForceUpdate?: boolean) => closeModals(isForceUpdate)}
            intlUtils={intlUtils}
            settingsUser={settingsUserModal}
          />
        )}

        {activeTypeModal === TypeModal.confirm && confirmDialog && (
          <ConfirmDialogModal
            closeModal={() => closeModals()}
            data={confirmDialog.data}
            handleClick={() => handleClick()}
            mode={confirmDialog.mode}
          />
        )}

        {activeTypeModal === TypeModal.status && settingsEditStatusModal && (
          <EditStatus
            closeModal={(isForceUpdate?: boolean) => closeModals(isForceUpdate)}
            editStatus={settingsEditStatusModal.editStatus}
            intlUtils={intlUtils}
            isLoading={settingsEditStatusModal.isEditing}
            statuses={settingsEditStatusModal.allStatuses}
            statusId={settingsEditStatusModal.statusId}
          />
        )}
      </div>
    </div>
  );
}
