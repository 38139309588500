import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import { capitalizeString } from "../../../utils";
import styles from "./PlacementReport.module.scss";
import {
  defaultTimeInterval,
  exportFormat,
  initial,
  timeIntervals,
  validate,
} from "./utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import Checkbox from "common/components/checkbox";
import { ICONS } from "common/helpers";
import { Modal } from "components/modal";
import { restaurantSelector } from "features/AppContex";
import { usePlacementReportMutation } from "features/api/reports";
import { useWorkingTimeOptions } from "hooks/useWorkingTimeOptions";
import moment from "moment";
import { useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { DatePicker, SelectBasic } from "ui-kit";

export interface ReportPlacementProps {
  closeModal: () => void;
  isOpen: boolean;
}

export function ReportPlacement({ isOpen, closeModal }: ReportPlacementProps) {
  const { intl, getIntlDateOf, getIntlSelectEntity } = useIntlUtils();
  const [interval, setInterval] = useState(defaultTimeInterval);
  const timeOptions = useWorkingTimeOptions(
    isOpen ? interval.value : skipToken,
  );
  const [download] = usePlacementReportMutation();
  const { restaurant_id } = useSelector(restaurantSelector);

  const onSubmit = useCallback(
    async ({
      startDate,
      endDate,
      startTime,
      endTime,
      useVisitTime,
      isConfirmed,
      format,
    }) => {
      await download({
        restaurant_id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        start_time: startTime?.label || "",
        end_time: endTime?.label || "",
        use_visit_time: useVisitTime,
        is_confirmed: isConfirmed,
        format: format.label,
        interval: interval.value,
      }).unwrap();
    },
    [restaurant_id, interval],
  );

  return (
    <Form initialValues={initial} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, invalid }) => (
        <Modal
          isOpen={isOpen}
          title={intl.formatMessage({ id: ETranslations.SEATING_REPORT })}
          onClose={closeModal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Content className={styles.placement}>
              <div className={cn(styles["date-time"], styles["titled-block"])}>
                <h3 className={cn(styles.title, styles.required)}>
                  {getIntlSelectEntity(ETranslations.PLURAL_PERIOD)}
                </h3>
                <div>
                  <Field name="startDate">
                    {({ input }) => (
                      <DatePicker
                        placeholderText={getIntlDateOf(ETranslations.OF_START)}
                        selected={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <div className={styles.separ}>—</div>
                  <Field name="endDate">
                    {({ input }) => (
                      <DatePicker
                        placeholderText={getIntlDateOf(ETranslations.OF_END)}
                        selected={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={cn(styles["date-time"], styles["titled-block"])}>
                <h3 className={cn(styles.title, styles.required)}>
                  {getIntlSelectEntity(ETranslations.TIME_SLOTS)}
                </h3>
                <div>
                  <SelectBasic
                    options={timeIntervals}
                    placeholder={intl.formatMessage({
                      id: ETranslations.TIME_SLOT,
                    })}
                    value={interval}
                    changedMaxSize
                    // @ts-ignore
                    onChange={setInterval}
                  />
                </div>
              </div>
              <div className={styles["titled-block"]}>
                <h3 className={cn(styles.title, styles.required)}>
                  {capitalizeString(
                    intl.formatMessage({ id: ETranslations.FROM }),
                  )}{" "}
                  -{" "}
                  {capitalizeString(
                    intl.formatMessage({ id: ETranslations.TO }),
                  )}
                </h3>
                <div>
                  <Field name="startTime">
                    {({ input }) => (
                      <SelectBasic
                        maxMenuHeight={200}
                        options={timeOptions}
                        placeholder={intl.formatMessage({
                          id: ETranslations.FROM,
                        })}
                        value={input.value}
                        changedMaxSize
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <div className={styles.separ}>—</div>
                  <Field name="endTime">
                    {({ input }) => (
                      <SelectBasic
                        maxMenuHeight={200}
                        options={timeOptions}
                        placeholder={intl.formatMessage({
                          id: ETranslations.TO,
                        })}
                        value={input.value}
                        changedMaxSize
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={styles["titled-block"]}>
                <h3 className={styles.title}>
                  {intl.formatMessage({ id: ETranslations.OPTIONS })}
                </h3>
                <div style={{ gap: 10 }}>
                  <Field name="isConfirmed" type="checkbox">
                    {({ input }) => (
                      <Checkbox
                        className={styles.customCheckbox}
                        indent="confirmed"
                        text={intl.formatMessage({
                          id: ETranslations.ONLY_CONFIRMED,
                        })}
                        value={input.checked}
                        useStopPropagation
                        onChange={input.onChange}
                      />
                    )}
                  </Field>

                  <Field name="useVisitTime" type="checkbox">
                    {({ input }) => (
                      <Checkbox
                        className={styles.customCheckbox}
                        indent="visit-time"
                        text={intl.formatMessage({
                          id: ETranslations.STAY_TIME_EXCLUDE,
                        })}
                        value={input.checked}
                        useStopPropagation
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={styles["titled-block"]}>
                <h3 className={cn(styles.title, styles.required)}>
                  {intl.formatMessage({ id: ETranslations.FORMAT })}
                </h3>
                <div>
                  <Field name="format">
                    {({ input }) => (
                      <SelectBasic
                        options={exportFormat}
                        placeholder={intl.formatMessage({
                          id: ETranslations.FORMAT,
                        })}
                        value={input.value}
                        changedMaxSize
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </Modal.Content>
            <Modal.Footer>
              <button
                className="primary"
                disabled={invalid || submitting}
                type="submit"
              >
                {intl.formatMessage({ id: ETranslations.UPLOAD })}
                {submitting && <img alt="" src={ICONS.loadingSmall} />}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Form>
  );
}
