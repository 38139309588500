import { ELocales } from '../types/commons';

/**
 * Environment config
 *
 * Для определения нового параметра конфига нужно добавить новое свойство
 * в данный объект. Значения для разных сред будет сформировано из значений
 * переменных окружения конкретного стенда на этапе сборки CI.
 */
export const environmentConfig = {
  BRAND: "WRF",
  theme: "wrf",
  tenant: "",
  defaultLocale: ELocales.ru_RU,
  supportEmail: "",
  supportPhone: "",
  brand_title: "",
  telephonyEnabled: false,
  depositEnabled: true,
  clientsHasEmail: true,
  defaultPhoneNumber: "",
  clientsHasLoyalty: true,
  hasVIPClients: true,
  userAutoHostessSet: false,
  autoGuestId: NaN,
  CI_COMMIT_SHORT_SHA: "local-develop",
  hasRequests: true,
  hasMultiLanguage: false,
  hasLogo: true,
  currency: "₽",
  hasStatusFiltersInHallScheme: false,
  fullCalendarKey: "0381783123-fcs-1679056143",
  shiftModule: false,
  requestGrid: false,
  isPrintAvailable: false,
  vipSign: false,
  dragAndDrop: true,
  HSE_ENABLED: true,
  isReportEnabled: true,
  newBookingDesign: false,
  sortStatusInDashboard: false,
  grayConfirmedStatusColor: false,
};

export type Config = Partial<typeof environmentConfig>;
