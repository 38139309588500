import { Layout } from "components/Layout";
import { Auth } from "components/auth/auth";
import { HallContainer } from "components/hall-scheme/redux/HallContainer";
import { config } from "config";
import { BookingOrders } from "containers/BookingOrders";
import { Dashboard } from "containers/Dashboard";
import { Dialog, Dialogs } from "containers/Dialogs";
import {
  BookingChatWebView,
  loaderBookingChatWebView,
} from "containers/Dialogs/BookingChatWebView/BookingChatWebView";
import { loader as dialogLoader } from "containers/Dialogs/Dialog/Dialog";
import { FeedList } from "containers/Dialogs/FeedList/FeedList";
import {
  EditRecordFromDialog,
  loader as editRecordLoader,
} from "containers/Dialogs/ReservationFromDialog/EditRecordFromDialog";
import { ReservationFromDialog } from "containers/Dialogs/ReservationFromDialog/ReservationFromDialog";
import { Guests } from "containers/Guests";
import { HallScheme } from "containers/HallScheme";
import { RequestsGrid } from "containers/RequestsGrid";
import { ScheduleLanding } from "containers/ScheduleLanding";
import { Search } from "containers/Search";
import { Settings } from "containers/Settings";
import { LayoutWebView } from "features/WebView";
import { WebViewProvider } from "features/WebView/provider";
import { createBrowserRouter, redirect } from "react-router-dom";

import { AppProvider } from "./providers/AppProvider";
import { RootProvider, rootLoader } from "./root";

export const router = createBrowserRouter([
  // ---------------------------------------------------------
  // WebViews: страницы для встраивания в нативное приложение
  {
    path: "/webviews",
    element: (
      <WebViewProvider mock={false} debug={false}>
        <RootProvider noAuth={true}>
          <AppProvider />
        </RootProvider>
      </WebViewProvider>
    ),
    children: [
      {
        element: (
          <LayoutWebView getPageName={(pathname) => pathname.split("/")[2]} />
        ),
        children: [
          // Чат по заявке
          {
            path: "dialogs/bookings/:clientId/:bookingId",
            loader: loaderBookingChatWebView,
            element: <BookingChatWebView />,
          },
          // Раздел "Диалоги"
          {
            path: "dialogs",
            element: <Dialogs />,
            children: [
              {
                path: ":dialogId",
                loader: dialogLoader,
                element: <Dialog />,
                children: [{ index: true, element: <FeedList /> }],
              },
            ],
          },
        ],
      },
    ],
  },
  // ---------------------------------------------------------
  // Обычные страницы
  {
    path: "/",
    loader: rootLoader, //переместить ли?
    shouldRevalidate: ({ nextUrl }) => nextUrl.pathname === "/", //ревалидация, если идем в компоненте ниже, но с url /,
    element: <RootProvider />,
    children: [
      {
        element: <AppProvider />,
        children: [
          {
            element: <Layout />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
              },
              {
                path: "/hall-scheme",
                element: <HallScheme />,
              },
              {
                path: "/create-booking",
                element: <HallContainer isCreateBookingOpened />,
              },
              {
                path: "/hall",
                element: <HallContainer />,
              },
              {
                path: "/requests",
                element: <BookingOrders />,
              },
              {
                path: "/guests",
                element: <Guests />,
              },
              {
                path: "/grid",
                loader: () =>
                  config.requestGrid ? null : redirect("/dashboard"),
                element: <RequestsGrid />,
              },
              {
                path: "/dialogs",
                element: <Dialogs />,
                children: [
                  {
                    path: ":dialogId",
                    loader: dialogLoader,
                    element: <Dialog />,
                    children: [
                      { index: true, element: <FeedList /> },
                      {
                        path: "create-booking",
                        element: <ReservationFromDialog />,
                      },
                      {
                        path: "edit/:record",
                        loader: editRecordLoader,
                        element: <EditRecordFromDialog />,
                      },
                      { path: "*", loader: () => redirect("/dialogs") },
                    ],
                  },
                ],
              },
              {
                path: "/schedule-landing",
                element: <ScheduleLanding />,
              },
              {
                path: "/settings",
                element: <Settings />,
              },
              {
                path: "/search",
                element: <Search />,
              },
            ],
          },
        ],
      },
      {
        path: "/login",
        element: <Auth />,
      },
      { path: "*", loader: () => redirect("/dashboard") },
    ],
  },
]);
