import { config } from "config";
import { useAppUsers } from "contexts/AppUsersContext";
import {
  dateSelector,
  placeSelector,
  restaurantSelector,
} from "features/AppContex";
import { useFromProxyActions } from "features/BookingFormProxy";
import { selectFormBooking } from "features/BookingFormProxy/selectors";
import { Contact, FormBooking } from "features/BookingFormProxy/types";
import { useGuestListActions } from "features/GuestsList";
import { useHallSchemaActions } from "features/HallSchema";
import { activeTablesSelector } from "features/HallSchema/selectors";
import { timelineSelectors } from "features/Timeline";
import { invalidateHallSlots } from "features/api/hallschema-api";
import useClosestInterval from "hooks/useClosestInterval";
import { useQueryParam } from "hooks/useQueryParam";
import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  type Value as E164Number,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useUnmount, useUpdateEffect } from "react-use";
import type { Client } from "types/client";

import { FormProxyProps } from "../types";
import { getTimeOption } from "../utils";

const getClientOrContactInfo = <T extends Client | Contact>(
  client: T,
  phone: T extends Client ? string : undefined,
) =>
  phone
    ? ({
        client: {
          ...client,
          name: client.name.trim(),
          middle_name: client.middle_name?.trim(),
          surname: client.surname.trim(),
        },
      } as { client: Client })
    : ({
        contact: {
          name: client.name.trim(),
          middle_name: client.middle_name?.trim(),
          surname: client.surname.trim(),
          contact_type: "CONTACT",
        },
        client: null,
      } as { contact: Contact; client: null });

const useBookingFormReduxProxy = <P>({
  isRegisterVisit,
  isCreating = true,
  booking,
  isAnonym,
  clientData,
  initialFormValues,
  ...rest
}: FormProxyProps<P>) => {
  const restaurant = useSelector(restaurantSelector);

  const dispatchRedux = useDispatch();
  const startDate = useSelector(dateSelector);
  const currentPlace = useSelector(placeSelector);
  const visitTime = useSelector(timelineSelectors.getVisistTime);
  const place = useSelector(placeSelector);

  // Store prev state
  const formValuesRef = useRef(undefined);
  const activeTables = useSelector(activeTablesSelector);
  const { setActiveTables } = useHallSchemaActions();
  const { reset: resetForm, resetClient } = useFromProxyActions();
  const { reset: resetGuestsList } = useGuestListActions();
  const { currentUser } = useAppUsers();
  const { submitFn } = rest;
  const formData = useSelector(selectFormBooking);

  const onSubmit = useCallback(
    async (values: any) => {
      const payload = {
        ...values,
        placeId: currentPlace,
        statePlace: place,
        restaurant_id: restaurant.restaurant_id,
      };

      // FIXME: поправка на поле contact/client. отредактировать после полного перехода к полю contact
      const correctPayload = {
        ...payload,
        ...getClientOrContactInfo(values.client, values.phone),
      };

      await submitFn(correctPayload as FormBooking)?.then(() => {
        // После отправки формы обновляем начальные значения. Для корректной работы последующего редактирования
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateInitial(payload);
        dispatchRedux(invalidateHallSlots());
      });
    },
    [submitFn, dispatchRedux, startDate, currentPlace],
  );

  const phoneFromQuery = useQueryParam("phone") as string | undefined;
  const defaultCodePrefix = useMemo(() => {
    if (config.defaultPhoneNumber && isCreating && !formData.phone) {
      return config.defaultPhoneNumber;
    }

    return formData.phone;
  }, [formData]);
  const initialPhone = (clientData?.phone || phoneFromQuery)?.replace(
    /^([^\D])/g,
    "+$1",
  ) as E164Number;

  const closestInterval = useClosestInterval();

  const isClient = formData.client_id || formData.client?.client_id;

  const isClientFromDialogWithoutPhone = clientData && !clientData.phone;

  const phoneSection =
    formData.phone ||
    initialPhone ||
    (formData.phoneMode === undefined &&
      !isRegisterVisit &&
      !isClient &&
      !isClientFromDialogWithoutPhone)
      ? {
          phone: formatPhoneNumberIntl(initialPhone) || defaultCodePrefix,
          phoneMode: true,
        }
      : {
          phone: undefined,
          phoneMode: false,
        };

  // eslint-disable-next-line no-underscore-dangle
  const _initial = useMemo(
    () =>
      initialFormValues || {
        ...formData,
        client_id: clientData?.client_id || formData.client_id,
        ...phoneSection,
        client: clientData
          ? {
              client_id: clientData.client_id,
              name: clientData.name,
              surname: clientData.surname,
              phone: formatPhoneNumberIntl(initialPhone),
              email: clientData.email,
            }
          : formData.client,
        // @ts-ignore
        time: isRegisterVisit
          ? getTimeOption(closestInterval || formData?.booking_time)
          : getTimeOption(formData?.booking_time),
        hostess: currentUser,
        date:
          formData?.booking_date && formData.booking_time
            ? moment(
                `${formData?.booking_date} ${formData?.booking_time}`,
              ).toDate()
            : startDate.toDate(),
        deposit: formData?.deposit || {
          use_deposit: false,
          deposit_made: false,
          deposit_amount: 0,
        },
        restaurant_id: formData.restaurant_id || restaurant.restaurant_id,
        visit_time: formData.visit_time ?? 120,
        tables: formData.tables ?? activeTables ?? [],
        initialVisitTime: visitTime,
        persons: formData.persons ?? null,
      },
    [
      formData.bookingId,
      formData.tables,
      formData.phone,

      initialPhone,
      currentUser,
      isRegisterVisit,
      closestInterval,
      booking?.bookingId,
      initialFormValues,
    ],
  );

  // ЭТО КОСТЫЛЬ ЧТОБЫ ПРЕДОТВРАТИТЬ РЕ-РЕНДЕР ФОРМЫ
  const [initial, updateInitial] = useState(_initial);

  useUpdateEffect(() => {
    updateInitial(_initial);
  }, [
    _initial.bookingId,
    _initial.tables?.length,
    _initial.time?.label,
    _initial.hostess,
  ]);
  // КОНЕЦ КОСТЫЛЯ

  useUnmount(() => {
    setActiveTables({ activeTables: [] });
    resetForm();
    resetGuestsList();
    resetClient();
  });

  const dispatchEventFn = (formValues: any) => {
    formValuesRef.current = formValues;
  };

  return {
    onSubmit,
    initial,
    dispatchEventFn,
  };
};

export default useBookingFormReduxProxy;
