import { useApplicationContextActions } from "features/AppContex";
import { useTablesInfo } from "hooks/useTablesOptions";
import { isArray } from "lodash";
import { type ReactNode, useCallback, useEffect, useMemo } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { usePrevious, useUpdateEffect } from "react-use";

import { Option } from "../../../../common/components/MultiSelect/multi-select";
import { useHallSchemaActions } from "../../../../features/HallSchema";
import { activeTablesSelector } from "../../../../features/HallSchema/selectors";

const useChoiceTableRedux = (
  onChange: any,
  isFromManagement: boolean | undefined,
  value: number[],
) => {
  const intl = useIntl();
  const {
    input: { value: bookingId },
  } = useField("bookingId", { subscription: { value: true } });
  const {
    input: { value: placeForm },
  } = useField("placeId", { subscription: { value: true } });
  const tablesInfo = useTablesInfo();
  const { setPlace } = useApplicationContextActions();

  const { setActiveTables } = useHallSchemaActions();

  const tables = useSelector(activeTablesSelector);

  const renderOptions = useCallback(
    (
      renderLabel: (
        title: ReactNode,
        capacity: `(${number}-${number})`,
        busy: boolean | undefined,
      ) => JSX.Element,
    ) =>
      isFromManagement
        ? tablesInfo.reduce<Option<number, ReactNode>[]>(
            (
              options,
              { tableId, tableNumber, minCapacity, maxCapacity, busy },
            ) => (
              value.includes(tableId) &&
                options.push({
                  value: tableId,
                  label: renderLabel(
                    tableNumber,
                    `(${minCapacity}-${maxCapacity})`,
                    busy,
                  ),
                }),
              options
            ),
            [],
          )
        : tablesInfo.map<Option<number, ReactNode>>(
            ({ tableId, tableNumber, minCapacity, maxCapacity, busy }) => ({
              value: tableId,
              label: renderLabel(
                tableNumber,
                `(${minCapacity}-${maxCapacity})`,
                busy,
              ),
            }),
          ),
    [tablesInfo],
  );

  useEffect(() => {
    if (placeForm) {
      setPlace(placeForm);
    }
  }, [placeForm]);

  const handleOnChange = (e: Option<number>[] = []) => {
    onChange(e.map((t) => t.value));
    setActiveTables({ activeTables: e.map((t) => t.value) });
  };
  const prevBookingId = usePrevious(bookingId);

  useEffect(() => {
    if (!tables.length) {
      onChange([]);
    }
  }, [tables]);

  useEffect(() => {
    if (bookingId === prevBookingId) return;
    const activeTables = isArray(value) ? value : [];
    setActiveTables({ activeTables: activeTables.length ? activeTables : [] });
    onChange(activeTables.length ? activeTables : []);
  }, [value, bookingId, prevBookingId]);

  useUpdateEffect(() => {
    if (!tablesInfo.length) return;
    const selected = tablesInfo.filter((t) => tables.includes(t.tableId));
    !!selected.length && onChange(selected.map((t) => t.tableId));
  }, [tables, tablesInfo]);

  const getSelectedOptions = useCallback(
    <T extends Option<number, ReactNode>>(options: T[]) =>
      (value || []).reduce<T[]>((result, tableId) => {
        const option = options.find((o) => o.value === tableId);
        option && result.push(option);
        return result;
      }, []),
    [value],
  );

  return {
    intl,
    renderOptions,
    getSelectedOptions,
    handleOnChange,
  };
};

export default useChoiceTableRedux;
