import styles from "./BookingsHistory.module.scss";
import cn from "classnames";
import { BookingHistory } from "components/BookingHistory";
import { useBookingsList } from "features/api/bookings-api";
import { useAllStatuses } from "features/api/dictionaries-api";
import _ from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Booking, ManagerialTable, TUseBookingList } from "types/booking";
import { Spinner } from "ui-kit";

interface OwnProps {
  dateFrom?: Date | null;
  dateTo?: Date | null;
  searchWords: string;
  userid?: number | null;
}

type Props = OwnProps;

export const BookingsHistory: FunctionComponent<Props> = ({
  dateFrom,
  dateTo,
  searchWords,
  userid,
}) => {
  const [search, setSearch] = useState(searchWords);
  const { data: allStatuses } = useAllStatuses();
  const allStatusesSystemName = allStatuses.map((status) => status.system_name);
  const bookingPayload: TUseBookingList = {
    search,
    includeStatuses: allStatusesSystemName,
    fromDate: dateFrom,
    toDate: dateTo,
    userid,
    isPooling: false,
  };

  const { data, isLoading } = useBookingsList(bookingPayload);

  const debounceSearchWords = useCallback(
    _.debounce((val: string) => {
      setSearch(val);
    }, 500),
    [],
  );

  useEffect(() => {
    debounceSearchWords(searchWords);
  }, [searchWords]);

  const [activeBook, setActiveBook] = useState<
    Booking | ManagerialTable | null
  >(null);
  const bookingClickHandler = useCallback(
    (book: Booking | ManagerialTable) => {
      if (activeBook?.bookingId === book.bookingId) {
        setActiveBook(null);
        return;
      }
      setActiveBook(book);
    },
    [setActiveBook, activeBook],
  );

  if (isLoading) return <Spinner />;

  return (
    <div className={cn("bookingHistory", styles.root)}>
      {data &&
        data.bookings.map((book) => (
          <BookingHistory
            booking={book}
            isExpanded={activeBook?.bookingId === book.bookingId}
            key={book.bookingId}
            onClick={bookingClickHandler}
          />
        ))}
    </div>
  );
};
