import { ETranslations } from "../../types/translates";
import { Card, Tabs } from "../../ui-kit";
import type { TButtons } from "./GuestContent/Control/Control";
import { GuestContent } from "./GuestContent/GuestContent";
import { GuestHistory } from "./GuestHistory/GuestHistory";
import { GuestNotes } from "./GuestNotes/GuestNotes";
import { Client } from "models/client.model";
import { useState } from "react";
import { useIntl } from "react-intl";

type TabName = "Guest card" | "History" | "Notes";

interface TProps {
  guest?: Client;
  buttons: TButtons[];
}

const renderContent = (tab: TabName, props: TProps) => {
  switch (tab) {
    case "Guest card":
      return <GuestContent buttons={props.buttons} guest={props.guest} />;
    case "History":
      return (
        props.guest?.client_id && (
          <GuestHistory client_id={props.guest?.client_id} />
        )
      );
    case "Notes":
      return (
        props.guest?.client_id && (
          <GuestNotes client_id={props.guest?.client_id} />
        )
      );
    default:
      return null;
  }
};

export const GuestInfo = (props: TProps) => {
  const [tab, setTab] = useState<TabName>("Guest card");
  const intl = useIntl();

  return (
    <>
      <Card.HeaderTabs<TabName> activeTab={tab} onChange={setTab}>
        <Tabs.Item<TabName>
          tabName={"Guest card"}
          title={intl.formatMessage({ id: ETranslations.GUEST_CARD })}
        />
        <Tabs.Item<TabName>
          tabName={"History"}
          title={intl.formatMessage({ id: ETranslations.BASE_HISTORY })}
        />
        <Tabs.Item<TabName>
          tabName={"Notes"}
          title={intl.formatMessage({ id: ETranslations.NOTES })}
        />
      </Card.HeaderTabs>
      <Card.Content noPadding>{renderContent(tab, props)}</Card.Content>
    </>
  );
};
