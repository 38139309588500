import cn from "classnames";
import type { CRMTag } from "models/tags.model";
import { type ElementType, HTMLAttributes, ReactNode, VFC } from "react";

import styles from "./tag.module.scss";

export type TTagProps = {
  label?: ReactNode;
  color?: string;
  as?: ElementType;
} & HTMLAttributes<HTMLDivElement>;
export const Tag: VFC<TTagProps> = ({
  className,
  label,
  children,
  color,
  as,
  ...props
}) => {
  const Element = as || "em";
  return (
    <Element
      className={cn(styles.tag, className)}
      style={{ backgroundColor: color }}
      {...props}
    >
      {label}
      {children}
    </Element>
  );
};

export const Tags = ({
  tags,
  className,
}: {
  tags: CRMTag[];
  className?: string;
}) => (
  <ul className={cn(styles.tags, className)}>
    {tags?.map((tag) => (
      <Tag key={tag.tag_id} color={tag.color} as="li">
        {tag.description}
      </Tag>
    ))}
  </ul>
);
