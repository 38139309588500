import { useFetchBookingHistoryQuery } from "features/api/bookings-api";
import { FunctionComponent, useCallback } from "react";
import { Booking, ManagerialTable } from "types/booking";

import { BookingCard } from "../BookingCard";
import BookingHistoryLog from "./BookingHistoryLog";
import "./bookingHistory.scss";

type BookingHistoryProps = {
  isExpanded?: boolean;
  onClick?: (booking: Booking | ManagerialTable) => void;
} & (
  | {
      bookingId: number | `${number}`;
      booking?: undefined;
    }
  | { bookingId?: undefined; booking: Booking | ManagerialTable }
);

//FIXME: заменить booking на bookingId

export const BookingHistory: FunctionComponent<BookingHistoryProps> = ({
  booking,
  bookingId,
  isExpanded,
  onClick,
}) => {
  const { data } = useFetchBookingHistoryQuery(bookingId ?? booking.bookingId);
  const bookingClickHandler = useCallback(
    () => (booking ?? data?.booking) && onClick?.(booking! || data?.booking),
    [booking, onClick, data?.booking],
  );
  return (
    <div className="bookingHistory">
      <div className="bookingWrapper">
        {(data?.booking || booking) && (
          <BookingCard
            booking={data?.booking || booking!}
            compact
            onClick={bookingClickHandler}
            isDashboard
          />
        )}
      </div>
      {Boolean(data && isExpanded) && <BookingHistoryLog data={data!} />}
    </div>
  );
};
