import { HTMLAttributes, forwardRef } from "react";

import { clientFullName } from "../../../common/helpers";
import { Booking } from "../../../types/booking";
import styles from "./dndPreview.module.scss";

type TBookingCardDndLayoutProps = {
  booking: Booking;
} & Pick<HTMLAttributes<HTMLSpanElement>, "style">;

export const BookingCardDndLayout = forwardRef<
  HTMLSpanElement,
  TBookingCardDndLayoutProps
>((data, ref) => {
  const { booking, style } = data;
  return (
    <span className={styles.bookingCardPreview} ref={ref} style={style}>
      {clientFullName(booking.client)}
    </span>
  );
});
