import styles from "./TabTitle.module.scss";
import cn from "classnames";
import { ComponentType, ReactNode, useCallback } from "react";

export interface TabTitleProps<T> {
  title: ReactNode;
  onClick(key: T): void;
  tabName: T;
  isActive?: boolean;
  disabled?: boolean;
  icon?: ComponentType;
}

export function TabTitle<T>({
  isActive,
  title,
  onClick,
  tabName,
  disabled,
  icon: Icon,
}: TabTitleProps<T>) {
  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick(tabName);
  }, [title, tabName, disabled]);

  return (
    <li
      className={cn(styles.tabTitle, {
        [styles.active]: isActive,
        [styles.disabled]: disabled,
      })}
      data-active={isActive}
      onClick={handleClick}
    >
      {Icon && <Icon />}
      {title}
    </li>
  );
}
