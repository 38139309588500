import styles from "./HallControls.module.scss";
import { ICONS } from "common/helpers";
import React from "react";
import { Button } from "ui-kit";

export const HallControls: React.FC<{
  zoomIn: () => void;
  zoomOut: () => void;
  resetZoom: () => void;
}> = ({ zoomIn, zoomOut, resetZoom }) => (
  <div className={styles.root}>
    <Button className={styles.button} variant="phantom" onClick={zoomIn}>
      <ICONS.ZoomPlus />
    </Button>
    <Button className={styles.button} variant="phantom" onClick={zoomOut}>
      <ICONS.ZoomMinus />
    </Button>
    <Button className={styles.button} variant="phantom" onClick={resetZoom}>
      <ICONS.ZoomPlus className={styles.reset} />
    </Button>
  </div>
);
