import { skipToken } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import { Modal } from "components/modal";
import { DialogsFilter, useDialogs } from "features/Dialogs/SDK";
import { useGetClientsTagsQuery } from "features/api/guest-api";
import { ReactNode, useState } from "react";
import { type IntlShape } from "react-intl";
import { useLocation } from "react-router-dom";
import type { ClientId } from "types/client";
import { ETranslations } from "types/translates";
import { Button, Card, ICONS, Spinner } from "ui-kit";
import { isNonEmptyArray } from "utils";

import { DialogsError } from "../DialogsError";
import styles from "./DialogList.module.scss";
import { DialogListContent } from "./DialogListContent";

export const DialogList = ({
  dialogsPayload,
  filtersCounter,
  formatMessage,
  children,
}: {
  dialogsPayload: DialogsFilter;
  filtersCounter: number | undefined;
  formatMessage: IntlShape["formatMessage"];
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const parentSelector = document.querySelector("#dialogs");
  const { dialogs, loading, error } = useDialogs(dialogsPayload);
  const clientIds = Array.from(
    new Set(dialogs?.map((d) => d.customer.id)),
  ).sort();
  const { data } = useGetClientsTagsQuery(
    clientIds?.length ? (clientIds as `${ClientId}`[]) : skipToken,
  );

  const { pathname } = useLocation();

  return (
    <>
      <Card
        className={cn(styles.dialogList, {
          [styles.tabletHide]: pathname !== "/dialogs",
        })}
      >
        <Card.Header
          controls={
            <Button
              className={styles.filterButton}
              variant="thin"
              onClick={() => setIsOpen(true)}
            >
              {Boolean(filtersCounter) && (
                <span className={styles.counter}>{filtersCounter}</span>
              )}
              <ICONS.Filter />
            </Button>
          }
          title={formatMessage({ id: ETranslations.DIALOGS })}
        />
        <Card.Content noPadding>
          {loading ? (
            <Spinner />
          ) : error || !dialogs || !isNonEmptyArray(dialogs) ? (
            <DialogsError
              message={formatMessage(
                dialogs && !dialogs.length
                  ? { id: ETranslations.EMPTY_DIALOGS }
                  : { id: ETranslations.ERROR_DIALOGS },
                { error: JSON.stringify(error || "") },
              )}
            />
          ) : (
            <DialogListContent dialogs={dialogs} clientsTags={data} />
          )}
        </Card.Content>
      </Card>
      {parentSelector && (
        <Modal
          className={styles.modal}
          isOpen={isOpen}
          overlayClassName={styles.overlay}
          parentSelector={() => parentSelector as HTMLElement}
          portalClassName={styles.modalPortal}
          title={formatMessage({ id: ETranslations.PLURAL_FILTER })}
          onClose={() => setIsOpen(false)}
        >
          <Modal.Content className={styles.modalContent}>
            {children}
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
