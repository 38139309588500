import styles from "./CreateGroupTagsModal.module.scss";
import { AddTagToGroupRedux } from "./sub-renders/AddTagToGroup";
import { ChooseColorRedux } from "./sub-renders/ChooseColorComponent";
import TagsContainer from "components/TagsContainer/TagsContainer";
import { Modal } from "components/modal";
import { requiredField } from "components/registration/validators/validators";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import { BaseShiftsModal } from "components/shifts/Modal/BaseShiftsModal";
import { required } from "components/shifts/utils";
import { restaurantSelector } from "features/AppContex";
import { useCreateTagsMutation } from "features/api/tags-api";
import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Card, Input, Labeled, SelectBasic } from "ui-kit";

interface CreateGroupTagsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  selectedTag: string;
  isAdminOfRest: boolean;
}

export const CreateGroupTagsModal = ({
  isOpen,
  onClose,
  title,
  selectedTag,
  isAdminOfRest,
}: CreateGroupTagsModalProps) => {
  const intl = useIntl();
  const [createTag] = useCreateTagsMutation();
  const restaurant = useSelector(restaurantSelector);
  const [hasError, setHasError] = useState<string>("");

  const toggleServerError = () => {
    setHasError("");
  };

  const types = [
    {
      value: "GLOBAL",
      label: intl.formatMessage({ id: ETranslations.GLOBAL }),
    },
    { value: "LOCAL", label: intl.formatMessage({ id: ETranslations.LOCAL }) },
  ];

  const initalValue = useMemo(() => {
    return {
      name_of_group: "",
      color: "#F86767",
      tags: [],
      newTag: "",
      group_type: types[isAdminOfRest ? 1 : 0],
    };
  }, [isAdminOfRest]);

  const onSubmit = (vals: any) => {
    const isGlobal = vals.group_type.value === "GLOBAL";
    createTag({
      name: vals.name,
      destination: selectedTag,
      color: vals.color,
      group_type: vals.group_type.value.toUpperCase(),
      owner_type: isGlobal ? undefined : "RESTAURANT",
      owner_id: isGlobal ? undefined : `${restaurant.restaurant_id}`,
      tags: vals.tags,
    }).then((res) => {
      // @ts-ignore
      if (res?.error?.data?.errorMessage) {
        // @ts-ignore
        setHasError(res?.error?.data?.errorMessage as string);
        return;
      }
      onClose();
    });
  };

  return (
    <BaseShiftsModal isOpen={isOpen} title={title} onClose={onClose}>
      <Modal.Content className={styles.modalContentAccess}>
        <Card onClose={onClose}>
          <Form initialValues={initalValue} onSubmit={onSubmit}>
            {({ handleSubmit, invalid }) => {
              return (
                <form className={styles.form} onSubmit={handleSubmit}>
                  <div className={styles.content}>
                    <p className={styles.formTitle}>
                      {intl.formatMessage({ id: ETranslations.GROUP_PlURAL })}
                    </p>
                    <div>
                      <Labeled
                        label={intl.formatMessage({
                          id: ETranslations.GROUP_TYPE,
                        })}
                      >
                        <Field name="group_type" validate={required}>
                          {(props) => (
                            <SelectBasic
                              disabled={isAdminOfRest ? true : false}
                              options={types}
                              placeholder="asd"
                              value={props.input.value}
                              onChange={props.input.onChange}
                            />
                          )}
                        </Field>
                      </Labeled>

                      <Labeled
                        label={intl.formatMessage({
                          id: ETranslations.NAME_GROUP,
                        })}
                        required
                      >
                        <Field
                          name="name"
                          validate={(value) => requiredField(value)}
                        >
                          {(props) => (
                            <Input
                              value={props.input.value}
                              onChange={props.input.onChange}
                            />
                          )}
                        </Field>
                      </Labeled>
                    </div>
                    <Labeled
                      label={intl.formatMessage({
                        id: ETranslations.GROUP_COLOR,
                      })}
                      required
                    >
                      <Field component={ChooseColorRedux} name="color" />
                    </Labeled>
                    <p className={styles.formTitle}>
                      {intl.formatMessage({ id: ETranslations.TAGS })}
                    </p>
                    <Labeled
                      label={intl.formatMessage({ id: ETranslations.ADD_TAG })}
                      required
                    >
                      <Field
                        component={AddTagToGroupRedux}
                        name="newTag"
                        styles={styles}
                      />
                    </Labeled>
                    <Field name="tags" validate={required}>
                      {() => (
                        <div className={styles.tagsContainer}>
                          <TagsContainer />
                        </div>
                      )}
                    </Field>
                  </div>
                  <BottomBar className={styles.controls}>
                    <BottomBar.Part placement="left">
                      <Button
                        disabled={invalid}
                        variant="primary"
                        onClick={handleSubmit}
                      >
                        {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
                      </Button>
                      <Button variant="secondary" onClick={onClose}>
                        {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
                      </Button>
                    </BottomBar.Part>
                  </BottomBar>

                  {hasError && (
                    <ModalPopup
                      buttons={
                        <Button variant="secondary" onClick={toggleServerError}>
                          {intl.formatMessage({ id: ETranslations.BASE_CLOSE })}
                        </Button>
                      }
                      isOpen={!!hasError}
                      needIcon={true}
                      text={hasError}
                      title={intl.formatMessage({
                        id: ETranslations.BASE_ERROR,
                      })}
                      editBooking
                      onClose={toggleServerError}
                    />
                  )}
                </form>
              );
            }}
          </Form>
        </Card>
      </Modal.Content>
    </BaseShiftsModal>
  );
};
