import styles from "./ProgressRing.module.scss";
import { memo } from "react";

interface ProgressRingProps {
  radius?: number;
  stroke?: number;
  percent: number;
}

export const ProgressRing = memo(
  ({ radius = 9, stroke = radius / 3, percent }: ProgressRingProps) => {
    const circumference = radius * 2 * Math.PI;
    const size = (radius + stroke) * 2;
    const center = radius + stroke;

    return (
      <div className={styles.container}>
        <svg height={size} width={size}>
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke="var(--circle-color, var(--alto-2))"
            strokeWidth={stroke}
          />
          <circle
            className={styles.bar}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke="var(--progress-color, currentColor)"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - (percent * circumference) / 100}
            strokeWidth={stroke}
          />
        </svg>
      </div>
    );
  },
);
