import GuestColumn from "./GuestColumn";
import styles from "./GuestColumn.module.scss";
import { emptyStringIfNull, getMaskPhone } from "common/helpers";
import { config } from "config";
import dayjs from "dayjs";
import { useIntlUtils } from "hooks/useIntlUtils";
import { Client } from "models/client.model";
import React, { FC } from "react";
import { ETranslations } from "types/translates";
import { getBookingClientCommentShort } from "utils";

interface TProps {
  guest: Client;
}

const GuestInfoBigBlock: FC<TProps> = ({ guest }) => {
  const {
    phone,
    birthday,
    sex,
    work_phone,
    email,
    vip,
    alt_email,
    grade_name,
  } = guest;
  const { intl, getIntlDateOfStrict, isRussianLocale, getIntlLevelOf } =
    useIntlUtils();
  const personalInfo = {
    content: [
      {
        content: birthday && dayjs(birthday).format("DD.MM.YYYY"),
        title: getIntlDateOfStrict(ETranslations.OF_BIRTH),
      },
      {
        content: isRussianLocale ? sex?.name : sex?.nameEng,
        title: intl.formatMessage({ id: ETranslations.GENDER }),
      },
    ],
    mainTitle: intl.formatMessage({ id: ETranslations.PERSONAL_INFO }),
  };
  const contact = {
    content: [
      {
        content: getMaskPhone(emptyStringIfNull(phone)),
        title: intl.formatMessage({ id: ETranslations.PHONE }),
      },
      {
        content: getMaskPhone(emptyStringIfNull(work_phone)),
        title: intl.formatMessage({ id: ETranslations.WORK_PHONE }),
      },
      {
        content: email,
        title: intl.formatMessage({ id: ETranslations.EMAIL }),
      },
      {
        content: alt_email,
        title: intl.formatMessage({ id: ETranslations.ALT_EMAIL }),
      },
    ],
    mainTitle: intl.formatMessage({ id: ETranslations.CONTACT }),
  };
  const special = {
    content: [
      config.BRAND !== "WRF"
        ? {
            content: vip
              ? intl.formatMessage({ id: ETranslations.BASE_YES })
              : intl.formatMessage({ id: ETranslations.BASE_NO }),
            title: intl.formatMessage({ id: ETranslations.VIP }),
          }
        : {
            content: grade_name,
            title: getIntlLevelOf(ETranslations.OF_LOYALTY),
          },
    ],
    mainTitle: intl.formatMessage({ id: ETranslations.SPECIAL }),
  };
  const about = {
    content: [
      {
        content: getBookingClientCommentShort(guest),
        title: intl.formatMessage({ id: ETranslations.COMMENT }),
      },
    ],
    mainTitle: intl.formatMessage({ id: ETranslations.ABOUT }),
  };
  return (
    <div className={styles.dataBlock}>
      <GuestColumn {...personalInfo} />
      <GuestColumn {...contact} />
      <GuestColumn {...special} />
      <GuestColumn {...about} />
    </div>
  );
};

export default GuestInfoBigBlock;
