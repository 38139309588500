import { ManagerialClient } from "../../types/booking";
import styles from "./BookingClientInfo.module.scss";
import { ICONS, clientFullName } from "common/helpers";
import { Tag } from "components/Tag";
import { config } from "config";
import { forwardRef } from "react";
import { Client } from "types/client";

interface BookingClientInfoProps {
  client: Client | ManagerialClient;
  isManagerialBooking: boolean;
}

export const BookingClientInfo = forwardRef<
  HTMLSpanElement,
  BookingClientInfoProps
>(({ client, isManagerialBooking }, ref) => (
  <div className={styles.bookingClientInfo}>
    {config.vipSign && client.vip && (
      <img alt="vip" className="star" src={ICONS.StarA} width={20} />
    )}
    <span className={styles.clientName} ref={ref}>
      {clientFullName(client)}
    </span>
    {config.clientsHasLoyalty && client.grade_name && (
      <>
        <ICONS.DotIcon />
        <span className={styles.gradeName}>{client.grade_name}</span>
      </>
    )}
    {isManagerialBooking && (
      <Tag
        tag={{
          color: "#FA97FC",
          status: "management",
          name: "Manager",
        }}
      />
    )}
  </div>
));
