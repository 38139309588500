import { ETranslations } from "../../types/translates";
import { HideWhen } from "../HideWhen";
import styles from "./Auth.module.scss";
import { LocaleControls } from "./LocaleControls";
import cn from "classnames";
import { ICONS } from "common/helpers";
import { config } from "config";
import { dictionariesApi, useTranslates } from "features/api/dictionaries-api";
import { useUserLocale } from "hooks/useUserLocale";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services/auth.service";
import { Card, Input } from "ui-kit";

const Clear = () => {
  const dispatch = useDispatch();
  dispatch(dictionariesApi.util.invalidateTags(["Dicts"]));
  return null;
};

interface AuthState {
  login: string;
  password: string;
  loginSuccess: boolean;
  isValid: boolean;
  viewPassword: "password" | "text";
}

export const Auth = () => {
  const { userLocale, setUserLocale } = useUserLocale();
  const { translates } = useTranslates(userLocale);
  const navigate = useNavigate();
  const [authState, setAuthState] = useState<AuthState>({
    login: "",
    password: "",
    loginSuccess: false,
    isValid: true,
    viewPassword: "password",
  });

  const handleChangeLogin = (value: string) => {
    setAuthState((prev) => ({ ...prev, login: value }));
  };

  const handleChangePassword = (value: string) => {
    if (!/[а-яА-Я]/.test(value.slice(-1)) || value === "") {
      setAuthState((prev) => ({ ...prev, password: value }));
    }
  };

  const handleChangeViewPassword = () => {
    setAuthState((prev) => ({
      ...prev,
      viewPassword: prev.viewPassword === "password" ? "text" : "password",
    }));
  };

  const onLogin = async () => {
    const { login, password } = authState;
    if (!/[0-9a-z_]/g.test(login)) {
      setAuthState((prev) => ({ ...prev, isValid: false }));
    } else {
      await AuthService.login({ login, password, locale: userLocale }).then(
        () =>
          setAuthState((prev) => ({
            ...prev,
            isValid: true,
            loginSuccess: true,
          })),
        () => setAuthState((prev) => ({ ...prev, isValid: false })),
      );
    }
  };

  if (authState.loginSuccess) {
    global?.channels?.auth?.cb && global.channels.auth.cb(true);
    navigate("/dashboard");
  }

  const { isValid, login, password, viewPassword } = authState;

  const BRAND_LOGO =
    (ICONS[`${config.BRAND}_AUTH_LOGO` as keyof typeof ICONS] as string) ||
    ICONS.WRF_AUTH_LOGO;
  const BRAND_BG =
    (ICONS[`${config.BRAND}_BG` as keyof typeof ICONS] as string) ||
    ICONS.WRF_BG;

  return (
    <div id="auth" onKeyPress={(e) => e.charCode === 13 && onLogin()}>
      <div className="group">
        <div className="img-group">
          <div
            className="img"
            style={{ backgroundImage: `url(${BRAND_BG})` }}
          />
          <div className="logo-group">
            <HideWhen condition={!config.hasLogo}>
              <img alt="" src={BRAND_LOGO} />
            </HideWhen>
            <h1>{translates[ETranslations.AUTH_SYSTEM_NAME]}</h1>
          </div>
        </div>
        <div className="form-group">
          <Card.Header
            controls={
              <HideWhen condition={!config.hasMultiLanguage}>
                <LocaleControls onLocaleChange={setUserLocale} />
              </HideWhen>
            }
            title={translates[ETranslations.AUTH_SYSTEM_ENTRY]}
          />
          <div className="form-content">
            <div
              className="error"
              style={{ visibility: isValid ? "hidden" : "visible" }}
            >
              {translates[ETranslations.AUTH_INCORRECT_CREDENTIALS]}
            </div>
            <form>
              <h3>{translates[ETranslations.AUTH_LOGIN]}</h3>
              <Input
                className={isValid ? "" : "invalid"}
                type="text"
                value={login}
                autoFocus
                onChange={(e) => handleChangeLogin(e.target.value)}
              />

              <h3>{translates[ETranslations.AUTH_PASSWORD]}</h3>
              <div>
                <Input
                  className={isValid ? "" : "invalid"}
                  suffix={
                    <ICONS.Eye
                      className={cn(styles.icon, {
                        [styles.active]: viewPassword === "text",
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeViewPassword();
                      }}
                    />
                  }
                  type={viewPassword}
                  value={password}
                  onChange={(e) => handleChangePassword(e.target.value)}
                />
              </div>

              <button className="primary" type="button" onClick={onLogin}>
                {translates[ETranslations.AUTH_SING_IN]}
              </button>
            </form>
            <div className="footer">
              {(config.supportEmail || config.supportPhone) && (
                <>
                  <span className="title">
                    {translates[ETranslations.AUTH_SUPPORT_SERVICE]}:
                  </span>
                  {config.supportEmail && (
                    <a href={`mailto:${config.supportEmail}`}>
                      <span className="mail">
                        <img alt="" src={ICONS.mail} />
                        {config.supportEmail}
                      </span>
                    </a>
                  )}
                  {config.supportPhone && (
                    <a href={`tel:${config.supportPhone}`}>
                      <span className="mail">
                        <img alt="" src={ICONS.call} />
                        {config.supportPhone}
                      </span>
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Clear />
    </div>
  );
};
