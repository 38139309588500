import { createManagerialTablesPayload } from "types/managerialTables";

import { coreApi } from "./bookings-api/core";
import { SLOT_TAGS } from "./hallschema-api";

export const managerialTablesApi = coreApi
  .enhanceEndpoints({ addTagTypes: ["Slots"] })
  .injectEndpoints({
    endpoints: (build) => ({
      createManagerialTable: build.mutation({
        query: (payload: createManagerialTablesPayload) => ({
          url: "/management/create/batch",
          method: "POST",
          body: payload,
        }),
      }),

      editManagerialBooking: build.mutation({
        query: ({
          payload,
          id,
        }: {
          payload: createManagerialTablesPayload;
          id: number;
        }) => ({
          url: `/management/edit/${id}`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: (result, err, args) => [
          "Bookings",
          { type: "ManagerialTables", id: args.id },
          "TableOptions",
          "GlobalSearchBookings",
          ...SLOT_TAGS,
        ],
      }),

      getManagerialTabel: build.query({
        query: (id: number) => ({
          url: `/management/${id}`,
          method: "GET",
        }),
        providesTags: (rest, err, args) => [
          { type: "ManagerialTables", id: args },
        ],
      }),

      cancelManagerialTable: build.mutation({
        query: ({ id }: { id: number }) => ({
          url: `management/delete/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, err, args) => [
          "Bookings",
          { type: "ManagerialTables", id: args.id },
          "TableOptions",
          "GlobalSearchBookings",
          ...SLOT_TAGS,
        ],
      }),
    }),
  });

export const {
  useCreateManagerialTableMutation,
  useGetManagerialTabelQuery,
  useEditManagerialBookingMutation,
  useCancelManagerialTableMutation,
} = managerialTablesApi;
