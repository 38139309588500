import { Placement } from "@popperjs/core";
import { useBooleanState } from "hooks/useBooleanState";
import {
  Children,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { usePreview } from "react-dnd-preview";
import { usePopper } from "react-popper";
import { stopPropagation } from "utils";

import { Content, ContentProps, PopupRef, Trigger } from "./Popup";
import styles from "./Popup.module.scss";

const usePopup = ({
  children,
  ref,
  placement,
  onClose,
  onOpen,
}: {
  children: ReactNode;
  ref: Ref<PopupRef>;
  placement?: Placement;
  onClose?: () => void;
  onOpen?: () => void;
}) => {
  const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(
    null,
  );
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
  const [trigger, content] = useMemo(
    () =>
      Children.toArray(children).reduce<
        [ReactElement | undefined, ReactElement<ContentProps> | undefined]
      >(
        (acc, el) => {
          if (!isValidElement(el)) return acc;
          if (el.type === Trigger) {
            acc[0] = el;
            return acc;
          }
          if (el.type === Content) {
            acc[1] = el as ReactElement<ContentProps> | undefined;
            return acc;
          }
          return acc;
        },
        [undefined, undefined],
      ),
    [children],
  );

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: "fixed",
      placement,
    },
  );

  const [isOpen, open, _close] = useBooleanState();
  const preview = usePreview();
  const isDragging = preview.display;

  const close = useCallback(() => {
    onClose?.();
    _close();
  }, [onClose, _close]);

  useEffect(() => {
    isDragging && close();
  }, [isDragging]);

  useImperativeHandle(ref, () => ({
    close,
  }));
  useImperativeHandle(ref, () => ({
    close,
  }));

  /*     useEffect(() => {
  /*     useEffect(() => {
        window.addEventListener('scroll', close, true);
        return () => {
            window.removeEventListener('scroll', close, true);
        };
    }, []); */

  const handleClick = useCallback(
    (e: MouseEvent) => {
      stopPropagation(e);
      if (!isOpen) {
        onOpen?.();
      }
      const change = isOpen ? close : open;
      change();
    },
    [open, isOpen],
  );

  const paddingStyles = useMemo(() => {
    const { noPadding } = content?.props || {};
    if (!noPadding) return undefined;
    if (typeof noPadding === "boolean") {
      return { [styles.noPadding]: noPadding };
    }
    return {
      [styles.noLeftPadding]: noPadding.left,
      [styles.noRightPadding]: noPadding.right,
      [styles.noTopPadding]: noPadding.top,
      [styles.noBottomPadding]: noPadding.bottom,
    };
  }, [content?.props.noPadding]);

  return {
    trigger,
    isOpen,
    setPopperElement,
    popperStyles,
    attributes,
    paddingStyles,
    content,
    handleClick,
    close,
    setReferenceElement,
  };
};

export default usePopup;
