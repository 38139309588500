import { useDispatchActions } from "../../hooks/useDispatchActions";
import { Source, Tag } from "../../models/booking.model";
import { Place, Restaurant } from "../../models/restaurant.model";
import { Booking, BookingOrder } from "../../types/booking";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";

export interface State {
  restaurants: Restaurant[];
  tags: Tag[];
  sources: Source[];
  date: string;
  place: number | null;
  restaurant: Restaurant;
  // TODO тут по идее нужно подумать как хранить места только в одном атрибуте
  selectedPlaces: number[];
}

export const initialState: State = {
  restaurants: [],
  tags: [],
  sources: [],
  date: moment().startOf("day").toISOString(),
  place: null,
  restaurant: null!,
  selectedPlaces: [],
};

const slice = createSlice({
  name: "applicationContext",
  initialState,
  reducers: {
    setUpRestaurants(state, { payload }: PayloadAction<Restaurant[]>) {
      state.restaurants = payload;
    },
    setUpTags(state, action: PayloadAction<Pick<State, "tags">>) {
      state.tags = action.payload.tags;
    },
    setUpSources(state, action: PayloadAction<Pick<State, "sources">>) {
      state.sources = action.payload.sources;
    },
    setDate: {
      prepare(date: Date | Moment | null) {
        const momentDate = date
          ? moment(date).startOf("day")
          : moment().startOf("day");
        return { payload: momentDate };
      },
      reducer(state, { payload }: PayloadAction<Moment>) {
        const prevDate = moment(state.date);
        state.date = payload
          .clone()
          .set({ hour: prevDate.hour(), minute: prevDate.minute() })
          .toISOString();
      },
    },
    setPlace(state, { payload }: PayloadAction<number | null>) {
      state.place = payload;
    },
    setPlaceFromBooking(state, { payload }: PayloadAction<Booking>) {
      const [firstPlace] = payload.places;
      state.place = firstPlace?.placeId;
    },
    setPlaceFromOrder(state, { payload }: PayloadAction<BookingOrder>) {
      const { placeId } = payload.places;
      state.place = placeId;
    },
    setSelectedPlaces(state, { payload }: PayloadAction<number[]>) {
      state.selectedPlaces = payload;
    },
    setSelectedPlace(state, { payload }: PayloadAction<Place>) {
      state.selectedPlaces = [payload.id];
    },
    setRestaurant(state, { payload }: PayloadAction<Restaurant>) {
      const places = payload.places;
      state.restaurant = { ...payload, places };
      state.selectedPlaces = payload.places.map((p) => p.id);
      [state.place] = state.selectedPlaces;
    },
  },
});

export const {
  name: applicationContextSliceName,
  reducer: applicationContextReducer,
} = slice;
export const useApplicationContextActions = () =>
  useDispatchActions(slice.actions);
