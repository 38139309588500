import { Labeled } from "../Labeled";
import styles from "./Input.module.scss";
import { InputAddon } from "./InputAddon";
import cn from "classnames";
import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from "react";

type BaseInputProps = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends Omit<BaseInputProps, "prefix"> {
  label?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  inputClassName?: string;
  invalid?: boolean;
}

export const Input = forwardRef(
  (
    {
      label,
      className,
      prefix,
      suffix,
      required,
      inputClassName,
      invalid,
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => (
    <Labeled className={className} label={label} required={required}>
      <div className={styles.inputContainer}>
        <InputAddon className={styles.prefix}>{prefix}</InputAddon>
        <input
          {...props}
          className={cn(styles.input, inputClassName, {
            [styles.withPrefix]: !!prefix,
            [styles.withSuffix]: !!suffix,
            [styles.invalid]: !!invalid,
          })}
          ref={ref}
          required={required}
        />
        <InputAddon className={styles.suffix}>{suffix}</InputAddon>
      </div>
    </Labeled>
  ),
);
