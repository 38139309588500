import { skipToken } from "@reduxjs/toolkit/dist/query";
import { PopupRef } from "components/Popup";
import dayjs from "dayjs";
import { useFromProxyActions } from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useMoveBookingActions } from "features/MoveBooking";
import { useTimelineActions } from "features/Timeline";
import {
  useChangeBookingStatus,
  useGetBookingStatusQuery,
  usePrintBookingInfo,
} from "features/api/bookings-api";
import { useCancelManagerialTableMutation } from "features/api/managerialTables";
import { useBooleanState } from "hooks/useBooleanState";
import { useRecoveryBooking } from "hooks/useRecoveryBooking";
import { useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Booking, ManagerialTable } from "types/booking";
import { getBookingStartTime, isManagerialTable } from "utils";

import { useIntlUtils } from "../../../hooks/useIntlUtils";

const useBookingActions = (
  booking: Booking | ManagerialTable,
  onEdit?: (booking: Booking | ManagerialTable) => void,
) => {
  const { intl } = useIntlUtils();
  const { pathname } = useLocation();
  const { recovery } = useRecoveryBooking(booking);
  const { reset: closeEdit } = useFromProxyActions();
  const { switchMode, setActiveTables } = useHallSchemaActions();
  const { setTimestamp, setTime, setVisistTime } = useTimelineActions();

  const { print, isDisabled } = usePrintBookingInfo(booking.bookingId);

  const [wasOpen, setWasOpen] = useBooleanState(false);
  const [cancel] = useCancelManagerialTableMutation();

  const popup = useRef<PopupRef>(null);

  const { data: status, isLoading } = useGetBookingStatusQuery(
    wasOpen ? booking.bookingId ?? skipToken : skipToken,
  );
  const {
    force,
    nextStatus: goNextStatus,
    terminalStatus,
    resetForce,
  } = useChangeBookingStatus(
    booking.bookingId,
    booking.client?.client_id || NaN,
  );
  const handleEdit = useCallback(() => {
    onEdit?.(booking as Booking);
    popup.current?.close();
  }, [booking, onEdit]);

  const nextStatus = useMemo(() => status?.next || status?.terminal, [status]);

  const handleStatusChange = useCallback(async () => {
    try {
      popup.current?.close();
      // eslint-disable-next-line no-unused-expressions
      status?.next ? goNextStatus() : terminalStatus();
    } catch {
      //
    }
  }, [status]);

  const { selectMoveSource, selectSourceTableNumber } = useMoveBookingActions();
  const tableNumber = booking.places.map((place) => place.number);
  const handleMoveClick = useCallback(() => {
    setTime(getBookingStartTime(booking));
    booking.bookingId && selectMoveSource(booking.bookingId);
    selectSourceTableNumber(tableNumber);
  }, [booking]);

  const [isCancel, show, hide] = useBooleanState(false);

  const handlePrint = useCallback(() => {
    print();
    popup.current?.close();
  }, [print]);

  const createBooking = () => {
    switchMode(HallMode.MANAGERIAL_CREATE_BOOKING);
    const tables = booking.places.map((each) => each.id);
    setActiveTables({ activeTables: tables });
    setTimestamp(
      dayjs(`${booking.bookingDate} ${booking.bookingTime}`).valueOf(),
    );
    closeEdit();

    if (isManagerialTable(booking)) {
      //@ts-ignore
      setVisistTime(booking.visitTime);
    }
  };

  const isManagerialTableAndTimePassed = useMemo(() => {
    if (
      isManagerialTable(booking) &&
      dayjs().valueOf() >=
        dayjs(`${booking.bookingDate}: ${booking.bookingTime}`)
          .add(booking.visitTime, "minute")
          .valueOf()
    ) {
      return true;
    } else {
      return false;
    }
  }, [booking]);

  return {
    isLoading,
    isCancel,
    handleEdit,
    intl,
    isManagerialTableAndTimePassed,
    createBooking,
    cancel,
    handleMoveClick,
    setTimestamp,
    nextStatus,
    handleStatusChange,
    recovery,
    handlePrint,
    isPrintDisabled: isDisabled,
    show,
    hide,
    popup,
    setWasOpen,
    force,
    goNextStatus,
    resetForce,
    isFloorplan: pathname.includes("/hall-scheme"),
  };
};

export default useBookingActions;
