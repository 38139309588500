import { useIntlUtils } from "../../hooks/useIntlUtils";
import { Client } from "../../models/client.model";
import { ETranslations } from "../../types/translates";
import { GuestCard } from "./GuestCard";
import { GuestViewContext, useGuestView } from "./GuestViewContext";
import { HideWhen } from "components/HideWhen";
import { guestListSelectors, useGuestListActions } from "features/GuestsList";
import { SelectGuestFromList } from "features/GuestsList/components/select-guest-from-list";
import { useIsTablet } from "hooks/useIsTablet";
import { useQueryParam } from "hooks/useQueryParam";
import * as queryString from "querystring";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUnmount } from "react-use";
import { Spinner } from "ui-kit";

function GuestsContainer() {
  const { getIntlCreatingOf, isRussianLocale } = useIntlUtils();
  const [view, setView] = useGuestView();
  const selectedClient = useSelector(guestListSelectors.selectedClient);
  const { setSelectedClient } = useGuestListActions();

  const [selectedId, setSelectedId] = useState<number | undefined>();

  const isTablet = useIsTablet();
  const location = useLocation();

  useEffect(() => {
    const { clientId } = queryString.parse(location.search.replace("?", ""));
    if (clientId) setSelectedId(+clientId);
  }, [location]);

  useEffect(
    () => setSelectedId(selectedClient?.client_id),
    [selectedClient?.client_id],
  );

  const goToCreate = useCallback(() => setView("create"), [setView]);

  useUnmount(() => {
    setSelectedClient(undefined);
  });

  const selectClientHandler = (client: Client) =>
    setSelectedId(client.client_id);

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen
          condition={isTablet && (!!selectedClient || view === "create")}
          noUnmount
        >
          <SelectGuestFromList
            controls={
              isTablet ? (
                <button className="primary" type="button" onClick={goToCreate}>
                  {getIntlCreatingOf(
                    isRussianLocale
                      ? ETranslations.PLURAL_GUESTS_ALT
                      : ETranslations.PLURAL_GUEST,
                  )}
                </button>
              ) : undefined
            }
            onSelectGuest={selectClientHandler}
          />
        </HideWhen>
      </React.Suspense>
      <HideWhen
        condition={isTablet && !selectedClient && view === "view"}
        noUnmount
      >
        <GuestCard clientId={selectedId} />
      </HideWhen>
    </>
  );
}

export function Guests() {
  const phone = useQueryParam("phone");
  return (
    <GuestViewContext initial={phone ? "create" : "view"}>
      <GuestsContainer />
    </GuestViewContext>
  );
}
