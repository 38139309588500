import { useApplicationContextActions } from "features/AppContex";
import {
  fromProxySelectors,
  useFromProxyActions,
} from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useTimelineActions } from "features/Timeline";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import type { Booking } from "types/booking";
import { getBookingStartTime } from "utils";

export const useBookingList = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const isDashboard = /^\/dashboard(\/.*)?$/.test(pathname);

  const { setOnlyBooking, setEditMode } = useFromProxyActions();
  const selectedBooking = useSelector(
    fromProxySelectors.selectBooking,
  ) as Booking;
  const { setPlaceFromBooking } = useApplicationContextActions();
  const { switchMode } = useHallSchemaActions();

  const { setTime } = useTimelineActions();
  const onSelectBook = useCallback(
    (book: Booking) => {
      setTime(getBookingStartTime(book));
      setPlaceFromBooking(book);
      setOnlyBooking(book);
      switchMode(HallMode.TABLES);
    },
    [selectedBooking],
  );

  const onEditBook = useCallback(
    (book: Booking) => {
      switchMode(HallMode.TABLES);
      setPlaceFromBooking(book);
      setOnlyBooking(book);
      setEditMode(true);
    },
    [selectedBooking],
  );

  return {
    intl,
    onSelectBook,
    onEditBook,
    selectedBooking,
    isDashboard,
  };
};
