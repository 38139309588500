import styles from "./Card.module.scss";
import cn from "classnames";

type ContentProps = JSX.IntrinsicElements["div"] & {
  noPadding?: boolean;
};

export const Content = ({
  children,
  className,
  noPadding,
  ...props
}: ContentProps) => (
  <div
    className={cn(styles.content, className, { [styles.noPadding]: noPadding })}
    {...props}
  >
    {children}
  </div>
);
