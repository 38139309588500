import Checkbox from "common/components/checkbox";
import { placesChangeHandler } from "components/shifts/Form/EditShiftForm/utils";
import { restaurantSelector } from "features/AppContex";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { useSelector } from "react-redux";

const SchemaField: React.FC<FieldRenderProps<string, any>> = ({ input }) => {
  const restaurant = useSelector(restaurantSelector);
  const value: number[] = input.value as unknown as [];

  return (
    <>
      {restaurant.places.map((place) => {
        return (
          <Checkbox
            indent="confirmed"
            key={place.id}
            text={place.name as string}
            value={value.includes(place.id)}
            useStopPropagation
            onChange={(val) =>
              placesChangeHandler(val, input.value, place.id, input.onChange)
            }
          />
        );
      })}
    </>
  );
};

export default SchemaField;
