import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";

import type { BookingModeType, BookingsData } from "./types";

const UseDashboardBookings = () => {
  const intl = useIntl();
  const location = useLocation();
  const [bookingMode, setBookingMode] = useState<BookingModeType>(() =>
    location.search.includes("complete") ? "complete" : "active",
  );
  const [isManageableTableSelected, setIsManageableTableSelected] =
    useLocalStorage("isManageableTableSelected", true);

  const [bookingsData, setBookingsData] = useState<BookingsData>(() => ({
    bookings: [],
    waitList: [],
    statistics: undefined,
    includedStatuses: [],
    searchQuery: "",
    isLoading: true,
    extraStatusFilter: undefined,
  }));

  return {
    intl,
    bookings: bookingsData.bookings,
    statistics: bookingsData.statistics,
    waitList: bookingsData.waitList,
    includedStatuses: bookingsData.includedStatuses,
    searchQuery: bookingsData.searchQuery,
    isLoading: bookingsData.isLoading,
    extraStatusFilter: bookingsData.extraStatusFilter,
    isManageableTableSelected,
    setIsManageableTableSelected,
    bookingMode,
    setBookingMode,
    setBookingsData,
  };
};

export default UseDashboardBookings;
