import { useDispatchActions } from "../../hooks/useDispatchActions";
import { Client as OldClient } from "../../models/client.model";
import { TNullable } from "../../types/commons";
import { DeepPartial, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TManagerialForm } from "components/ManagerialTables/form/helpers/prepareData";
import { constant } from "lodash";
import { Booking, BookingOrder, BookingWithRest } from "types/booking";
import { Client } from "types/client";

export interface State {
  selectedTimeValue: string;
  // option for edit exits booking
  editMode: boolean;
  client: OldClient | Client | null;
  // TODO бронь здесь должна быть Booking без DeepPartial
  booking: DeepPartial<Booking> | Booking | BookingWithRest | null;
  order: DeepPartial<BookingOrder> | null;
  editClient?: boolean;
  managerialTable: TNullable<TManagerialForm>;
}

export const initialState: State = {
  selectedTimeValue: "",
  client: null,
  booking: null,
  order: null,
  editMode: false,
  editClient: false,
  managerialTable: null,
};

const slice = createSlice({
  name: "bookingFormSlice",
  initialState,
  reducers: {
    setTime(state, action: PayloadAction<Pick<State, "selectedTimeValue">>) {
      const { selectedTimeValue } = action.payload;
      state.selectedTimeValue = selectedTimeValue;
    },
    setClient(state, action: PayloadAction<Pick<State, "client">>) {
      const { client } = action.payload;
      state.client = client;
    },
    resetBooking(state) {
      state.booking = null;
      state.client = null;
      state.editMode = false;
    },
    setOnlyBooking(state, action: PayloadAction<State["booking"] | undefined>) {
      state.client = null;
      state.booking = action.payload ?? null;
      state.editMode = false;
    },
    setOnlyOrder(state, action: PayloadAction<State["order"] | undefined>) {
      const orderValue = action.payload ?? null;
      state.order = orderValue;
      if (orderValue) {
        const client = orderValue.client ?? null;
        state.client = client as Client;
      }
    },
    setBooking(
      state,
      action: PayloadAction<Pick<State, "client" | "booking">>,
    ) {
      const { client, booking } = action.payload;
      state.booking = booking;
      state.client = client;
      state.editMode = false;
    },

    setEditMode(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
      if (!action.payload) {
        state.editClient = false;
      }
    },
    resetClient(state) {
      state.client = null;
    },
    setEditClient(state, { payload }: PayloadAction<boolean>) {
      state.editClient = payload;
    },
    reset: {
      prepare: constant({ payload: undefined }),
      reducer(state) {
        state.selectedTimeValue = "";
        state.client = null;
        state.booking = null;
        state.order = null;
        state.editMode = false;
      },
    },
  },
});

export const {
  name: formProxySliceName,
  reducer: formProxySliceReducer,
  actions: bookingFormSliceActions,
} = slice;
export const useFromProxyActions = () => useDispatchActions(slice.actions);
