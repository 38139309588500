import cn from "classnames";
import { Header } from "components/root/header";
import { config } from "config";
import { ModalContext } from "contexts/ModalContext";
import { useApplicationContextActions } from "features/AppContex";
import { useFromProxyActions } from "features/BookingFormProxy";
import { api } from "features/api";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { useToggle } from "react-use";
import { AuthService } from "services/auth.service";

import { useOrderCount } from "../../features/api/booking-order-api";
import { Sidebar } from "../../ui-kit";
import styles from "./Layout.module.scss";
import "./Layout.scss";

export function Layout(): JSX.Element {
  const { closeModals, state } = useContext(ModalContext);
  const { setDate } = useApplicationContextActions();
  const { pathname } = useLocation();
  const pageName = pathname.replace(/^\/([\w-]+)\/?.*$/, "$1");
  const isDialogsBooking = /dialogs\/[\w-]+\/[\w]+-booking|edit/.test(pathname);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const { data: requestsCount = 0 } = useOrderCount();
  const [userApp] = useState(AuthService.getUser());
  const { reset } = useFromProxyActions();
  const dispatch = useDispatch();
  const [now, setNow] = useState(moment());
  const prevDate = useRef(now);
  const logout = useCallback(() => {
    AuthService.logout();
    dispatch(api.util.resetApiState());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextNow = moment().clone();
      setNow(nextNow);
      if (!nextNow.isSame(prevDate.current, "day")) {
        setDate(nextNow);
      }
      prevDate.current = nextNow;
    }, 10e3);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={cn("container", {
        modal: state.activeModal?.position === "root",
      })}
    >
      <Header onToggleMenu={toggleMenu} isDialogsBooking={isDialogsBooking} />
      <div id="content">
        <Sidebar
          isOpen={isMenuOpen}
          logout={logout}
          now={now}
          requestsCount={requestsCount}
          reset={reset}
          userApp={userApp}
          isDialogsBooking={isDialogsBooking}
        />
        <main
          className={cn(styles.main, styles[pageName], {
            [styles["booking"]]: isDialogsBooking,
          })}
          id={pageName}
          onClick={() => closeModals()}
        >
          <Outlet />
        </main>
        {config.CI_COMMIT_SHORT_SHA && (
          <span className={styles.version}>{config.CI_COMMIT_SHORT_SHA}</span>
        )}
      </div>
    </div>
  );
}
