import { config } from "../config";
import { Client } from "../models/client.model";
import { AuthService } from "../services/auth.service";
import { ClientsService } from "../services/clients.service";
import { RestaurantsService } from "../services/restaurants.service";
import { SourcesService } from "../services/sources.service";
import { UsersService } from "../services/users.service";
import { Broadcast } from "./helpers";
import _ from "lodash";
import { Source, SourceServer } from "models/booking.model";
import { Place, Restaurant, RestaurantExtended } from "models/restaurant.model";
import { User, UserExtended } from "models/user.model";

export default class PreData {
  users: UserExtended[] = [];
  sources: Source[] = [];
  loaded: Broadcast<void> = new Broadcast();
  rests: RestaurantExtended[] = [];
  defaultClient: Client | undefined = undefined;

  usersMapper: { [id: string]: UserExtended } = {};
  placesMapper: { [id: number]: Place[] } = {};
  sourcesMapper: { [id: string]: Source } = {};
  restsMapper: { [id: string]: RestaurantExtended } = {};

  constructor() {
    if (global.env === "test") {
      this.init().then();
    }
  }

  async initRests() {
    try {
      this.rests = (await RestaurantsService.getAll()).data.map(
        (r: Restaurant): RestaurantExtended => ({
          ...r,
          label: r.restaurant_name,
          value: r.restaurant_id,
          places: r.places.map((p) => ({ ...p, value: p.id, label: p.name })),
        }),
      );
      this.rests.forEach((rest) => {
        this.restsMapper[rest.restaurant_id] = rest;
        this.placesMapper[rest.restaurant_id] = rest.places;
      });
    } catch (e) {
      console.warn("Restaurants and places were not loaded");
    }
  }

  async initUser() {
    try {
      if (AuthService.getUserRole() !== "ADMINISTRATOR") {
        await this.updateUsers();
      }
    } catch (e) {
      console.warn("Users were not loaded");
    }
  }

  async initSources() {
    try {
      this.sources = (await SourcesService.getAll()).data.map(
        (s: SourceServer): Source => ({
          ...s,
          value: s.id,
          label: s.source_name,
        }),
      );
      this.sources = _.sortBy(this.sources, "source_name");
      this.sources.forEach(
        (source) => (this.sourcesMapper[source.id] = source),
      );
    } catch (e) {
      console.warn("Sources were not loaded");
    }
  }

  async init() {
    await Promise.all([this.initRests(), this.initUser(), this.initSources()]);
    // add default client
    if (config.autoGuestId) {
      this.defaultClient = (
        await ClientsService.getById(config.autoGuestId)
      ).data;
    }
    this.loaded.sendValueToCB();
  }

  restById(id?: number): RestaurantExtended | undefined {
    return id ? this.restsMapper[id] : undefined;
  }

  placesByIdRest(id?: number): Place[] {
    return id ? this.placesMapper[id] : [];
  }

  userById(id: number | undefined): UserExtended | undefined {
    if (id) {
      return this.usersMapper[id];
    }
    return undefined;
  }

  sourceById(id: number): Source | undefined {
    return this.sourcesMapper[id];
  }

  async updateRestList() {
    this.rests = (await RestaurantsService.getAll()).data.map(
      (r: Restaurant): RestaurantExtended => ({
        ...r,
        label: r.restaurant_name,
        value: r.restaurant_id,
        places: r.places.map((p) => ({ ...p, value: p.id, label: p.name })),
      }),
    );
    this.rests.forEach((rest) => {
      this.restsMapper[rest.restaurant_id] = rest;
      this.placesMapper[rest.restaurant_id] = rest.places;
    });
  }

  async updateUsers(idRest?: number) {
    this.users = (await UsersService.getHostess(idRest)).data.map(
      (u: User): UserExtended => ({ ...u, value: u.id, label: u.name }),
    );
    this.users.forEach((user) => (this.usersMapper[user.id] = user));

    this.users = _.sortBy(this.users, "name");
    const foundElem = this.users.find(({ name }) => name === "В комментариях");

    if (foundElem) {
      const foundIndexElem = this.users.findIndex(
        ({ name }) => name === "В комментариях",
      );
      this.users.push(foundElem);
      this.users.splice(foundIndexElem, 1);
    }
  }

  getRealUsers() {
    return this.users.filter(({ name }) => name !== "В комментариях");
  }
}
