import cn from "classnames";
import { HideWhen } from "components/HideWhen";
import { config } from "config";
import { Moment } from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";



import { Button, ModeSwitch } from "..";
import { UserApp } from "../../services/auth.service";
import { ETranslations } from "../../types/translates";
import { DashboardLogo, DialogsLogo, DotSquare, Exit, GridLogo, GuestsIcon, MiniBadge, SeatingChartLogo, Settings } from "../ICONS/icons";
import { NavLink } from "./NavLink";
import styles from "./Sidebar.module.scss";
import { UnansweredCounter } from "./UnansweredCounter";


interface Props {
  requestsCount: number;
  userApp: UserApp;
  now: Moment;
  logout: () => void;
  isOpen: boolean;
  reset?: () => void;
  isDialogsBooking?: boolean;
}

export const Sidebar: FC<Props> = ({
  userApp,
  now,
  logout,
  isOpen,
  reset,
  requestsCount,
  isDialogsBooking,
}) => {
  const intl = useIntl();

  const location = useLocation();

  return (
    <aside className={cn(styles.sidebar, isOpen && styles.open)}>
      <nav>
        <NavLink className={cn(styles.item)} resetFn={reset} to="/dashboard">
          <div className={styles.iconBlock}>
            <DashboardLogo className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.SIDEBAR_DASHBOARD })}
          </span>
        </NavLink>

        <NavLink
          className={cn(
            styles.item,
            location.pathname.includes("create-booking") &&
              !isDialogsBooking &&
              styles.active,
          )}
          resetFn={reset}
          to="/hall-scheme"
        >
          <div className={styles.iconBlock}>
            <DotSquare className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
          </span>
        </NavLink>

        <HideWhen condition={!config.hasRequests}>
          <NavLink className={styles.item} resetFn={reset} to="/requests">
            <div className={styles.iconBlock}>
              <MiniBadge className={styles.icon} />
            </div>
            <span className={styles.title}>
              {intl.formatMessage({ id: ETranslations.PLURAL_REQUESTS_NOM })}
            </span>
            {Boolean(requestsCount) && (
              <span className={styles.counter}>{requestsCount}</span>
            )}
          </NavLink>
        </HideWhen>

        <NavLink className={styles.item} resetFn={reset} to="/guests">
          <div className={styles.iconBlock}>
            <GuestsIcon className={styles.icon} height={40} width={48} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.GUEST_LIST })}
          </span>
        </NavLink>

        <NavLink className={cn(styles.item)} resetFn={reset} to="/dialogs">
          <div className={styles.iconBlock}>
            <DialogsLogo className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.SIDEBAR_DIALOGS })}
          </span>
          <UnansweredCounter />
        </NavLink>

        {config.requestGrid && (
          <NavLink className={cn(styles.item)} resetFn={reset} to="/grid">
            <div className={styles.iconBlock}>
              <GridLogo className={styles.icon} />
            </div>
            <span className={styles.title}>
              {intl.formatMessage({ id: ETranslations.SIDEBAR_GRID })}
            </span>
          </NavLink>
        )}

        <NavLink className={styles.item} resetFn={reset} to="/schedule-landing">
          <div className={styles.iconBlock}>
            <SeatingChartLogo className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({
              id: ETranslations.SIDEBAR_BOARDING_SCHEDULE,
            })}
          </span>
        </NavLink>

        <NavLink className={styles.item} resetFn={reset} to="/settings">
          <div className={styles.iconBlock}>
            <Settings className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.SIDEBAR_HANDLING })}
          </span>
        </NavLink>
      </nav>
      <div className={styles.user}>
        <Button className={styles.logout} variant="phantom" onClick={logout}>
          <Exit />
        </Button>
        <p className={styles.name}>{userApp?.name}</p>
        <div className={styles.time}>
          <p className={styles.timeFormat}>{now.format("HH:mm")}</p>
          <p className={styles.date}>
            <span className={styles.capitalize}>{now.format("dd")}</span>
            {`, ${now.format("D MMM")}`}
          </p>
        </div>
        <ModeSwitch className={styles.modeSwitch} />
      </div>
    </aside>
  );
};