import cn from "classnames";
import { ComponentType, PropsWithChildren } from "react";
import { Button, ButtonProps } from "ui-kit";

import styles from "./Menu.module.scss";

type ItemProps = {
  className?: string;
  icon?: ComponentType;
  disabled?: boolean;
} & Pick<ButtonProps, "onClick">;
function Item({
  children,
  className,
  onClick,
  icon: Icon,
  disabled,
}: PropsWithChildren<ItemProps>) {
  return (
    <li className={cn(styles.item, className)}>
      <Button
        variant="phantom"
        className={styles.button}
        onClick={onClick}
        disabled={disabled}
      >
        {Icon && (
          <span className={styles.icon}>
            <Icon />
          </span>
        )}
        {children}
      </Button>
    </li>
  );
}

export const Menu = Object.assign(
  ({
    children,
    className,
    onClick,
    disableDnD,
  }: PropsWithChildren<{
    className?: string;
    onClick?: React.MouseEventHandler<HTMLOListElement>;
    disableDnD?: boolean;
  }>) => (
    <ol
      className={cn(styles.menu, className)}
      onClick={onClick}
      {...(disableDnD
        ? {
            onMouseDown: (e) => {
              e.stopPropagation();
            },
            onTouchStart: (e) => {
              e.stopPropagation();
            },
            onTouchMove: (e) => {
              e.stopPropagation();
            },
            onTouchStartCapture: (e) => {
              e.stopPropagation();
            },
            onScroll: (e) => {
              e.stopPropagation();
            },
            onScrollCapture: (e) => {
              e.stopPropagation();
            },
            onDragStart: (e) => {
              e.stopPropagation();
            },
          }
        : {})}
    >
      {children}
    </ol>
  ),
  {
    Item,
  },
);
