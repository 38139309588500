import { StatusCollapse } from "./status-collapse";
import {
  FormattedStatuses,
  sortStatuses,
  transformStatuses,
} from "./transform-data";
import {
  useAllStatuses,
  useEditStatusMutation,
} from "features/api/dictionaries-api";
import type { Status, StatusId } from "types/status";
import { Spinner } from "ui-kit";

const renderCollapses = (
  statuses: Status[],
  partialModalStatus: (statusId: StatusId) => void,
  editStatus: ReturnType<typeof useEditStatusMutation>[0],
  isEditing: boolean,
) => {
  const formattedStatuses = transformStatuses(statuses);
  return (
    <section className="collapses">
      {Object.keys(formattedStatuses).map((categoryName) => (
        <StatusCollapse
          categoryName={categoryName}
          editStatus={editStatus}
          formattedStatuses={sortStatuses(
            formattedStatuses[categoryName as keyof FormattedStatuses],
          )}
          isEditing={isEditing}
          key={categoryName}
          partialModalStatus={partialModalStatus}
        />
      ))}
    </section>
  );
};

export const StatusManagement = ({
  onCallModalStatus,
}: {
  onCallModalStatus: (
    allStatuses: Status[],
    statusId: StatusId,
    editStatus: ReturnType<typeof useEditStatusMutation>[0],
    isLoading: boolean,
  ) => void;
}) => {
  const { data, isLoading } = useAllStatuses();
  const [editStatus, { isLoading: isEditing }] = useEditStatusMutation();
  const partialModalStatus = (statusId: StatusId) =>
    onCallModalStatus(data, statusId, editStatus, isEditing);
  return isLoading ? (
    <Spinner />
  ) : (
    renderCollapses(data, partialModalStatus, editStatus, isEditing)
  );
};
