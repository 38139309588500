import { z } from "zod";

export interface Client {
  name: string;
  surname: string;
  middle_name?: string;
  tags: number[];
  client_id: number;
  grade_name: string;
  grade_color?: string | null;
  email?: string | null;
  phone: string;
  vip?: boolean;
  editClient?: boolean;
}

export const ClientId = z.number().brand("ClientId");
export type ClientId = z.infer<typeof ClientId>;
const ContactId = z.number().brand("ContactId");
export type ContactId = z.infer<typeof ContactId>;
