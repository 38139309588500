import Control, { TButtons } from "../Control/Control";
import { GuestBadge } from "../GuestBadge/GuestBadge";
import styles from "./GuestForm.module.scss";
import useGuestForm from "./useForm";
import { buildDictionaryOptions, getDictionaryOptionById } from "./utils";
import Checkbox from "common/components/checkbox";
import { emptyStringIfNull, getMaskPhone } from "common/helpers";
import { HideWhen } from "components/HideWhen";
import { UserTags } from "components/UserTags";
import { phoneValidInput } from "components/registration/validators/validators";
import { required } from "components/shifts/utils";
import { config } from "config";
import { GUEST_COMMENT_LIMIT } from "constants/common";
import { TView } from "containers/Guests/GuestCard";
import { useGuestListActions } from "features/GuestsList";
import { useIntlUtils } from "hooks/useIntlUtils";
import { Client } from "models/client.model";
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { TNullable } from "types/commons";
import { ETranslations } from "types/translates";
import {
  Card,
  DatePicker,
  Input,
  Labeled,
  SelectBasic,
  Textarea,
} from "ui-kit";
import { toBase64 } from "utils";

interface TProps {
  guest?: Client;
  view: TView;
  buttons: TButtons[];
  setView: Dispatch<SetStateAction<TView>>;
}

const GuestForm: FC<TProps> = ({ guest, view, buttons, setView }) => {
  const { intl, getIntlDateOf, getIntlChooseEntity, isRussianLocale } =
    useIntlUtils();
  const { setSelectedClient } = useGuestListActions();
  const {
    salutationDictionary,
    genderDictionary,
    updateGuest,
    createGuest,
    getClient,
    isFormLoading,
  } = useGuestForm();

  const [selectedFile, setSelectedFile] = useState<TNullable<File>>(null);

  const init = useMemo(() => {
    if (guest) {
      return {
        ...guest,
        salutation:
          guest.salutation?.id &&
          salutationDictionary &&
          getDictionaryOptionById(
            salutationDictionary,
            guest.salutation.id,
            isRussianLocale,
          ),
        birthday: guest.birthday && new Date(guest.birthday),
        sex:
          guest.sex &&
          genderDictionary &&
          getDictionaryOptionById(
            genderDictionary,
            guest.sex?.id,
            isRussianLocale,
          ),
        vip: !!guest.vip,
        phone: getMaskPhone(emptyStringIfNull(guest.phone)),
        work_phone: getMaskPhone(emptyStringIfNull(guest.work_phone)),
      };
    } else {
      return {};
    }
  }, [guest, salutationDictionary, genderDictionary]);

  const onSubmit = async (formData: any) => {
    let clientPhoto;
    if (selectedFile) {
      clientPhoto = await toBase64(selectedFile);
    }

    const data = {
      ...formData,
      salutation_id: formData.salutation?.value,
      sex_id: formData.sex?.value,
      client_photo: clientPhoto || formData?.client_photo,
    };

    delete data.sex;
    delete data.salutation;

    if (view === "create") {
      const newClient = await createGuest(data);
      //@ts-ignore
      const clientId = newClient?.data?.data.client_id;
      if (clientId) {
        const updatedClient = await getClient(clientId);
        setSelectedClient(updatedClient.data);
        setView("info");
      }
    }

    if (view === "edit") {
      const response = await updateGuest(data);
      //@ts-ignore
      if (response?.error?.data) return;
      if (guest?.client_id) {
        await getClient(guest.client_id);
        setView("info");
      }
    }
  };

  return (
    <Form initialValues={init} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Card onClose={close}>
          <Card.Content noPadding>
            <form id="guest-form" onSubmit={handleSubmit}>
              <GuestBadge guest={guest} setSelectedFile={setSelectedFile}>
                <Control buttons={buttons} isLoading={isFormLoading} />
              </GuestBadge>
              <div className={styles.dataBlock}>
                <div className={styles.row}>
                  <Labeled
                    label={intl.formatMessage({
                      id: ETranslations.FIRST_NAME,
                    })}
                    required
                  >
                    <Field name="name" validate={required} required>
                      {({ input, meta }) => (
                        <Input {...input} {...meta} autoComplete="off" />
                      )}
                    </Field>
                  </Labeled>
                  <Labeled
                    label={intl.formatMessage({
                      id: ETranslations.MIDDLE_NAME,
                    })}
                  >
                    <Field name="middle_name">
                      {({ input, meta }) => (
                        <Input {...input} {...meta} autoComplete="off" />
                      )}
                    </Field>
                  </Labeled>
                  <Labeled
                    label={intl.formatMessage({
                      id: ETranslations.LAST_NAME,
                    })}
                    required
                  >
                    <Field name="surname" validate={required} required>
                      {({ input, meta }) => (
                        <Input {...input} {...meta} autoComplete="off" />
                      )}
                    </Field>
                  </Labeled>
                </div>

                <div className={styles.row}>
                  <Labeled
                    as={"div"}
                    label={getIntlDateOf(ETranslations.OF_BIRTH)}
                  >
                    <Field name="birthday">
                      {(props) => (
                        <DatePicker
                          minYear={1920}
                          selected={props.input.value}
                          onChange={props.input.onChange}
                        />
                      )}
                    </Field>
                  </Labeled>
                  <Field name="sex">
                    {({ input }) => (
                      <Labeled
                        label={intl.formatMessage({
                          id: ETranslations.GENDER,
                        })}
                      >
                        <SelectBasic
                          {...input}
                          isDisabled={input.disable}
                          options={buildDictionaryOptions(
                            genderDictionary || [],
                            isRussianLocale,
                          )}
                          placeholder={intl.formatMessage({
                            id: ETranslations.GENDER,
                          })}
                          changedMaxSize
                          onChange={input.onChange}
                        />
                      </Labeled>
                    )}
                  </Field>
                  <HideWhen condition={!config.vipSign}>
                    <Field name="vip" type="checkbox">
                      {({ input }) => (
                        <Checkbox
                          className={styles.checkbox}
                          indent="confirmed"
                          text={intl.formatMessage({ id: ETranslations.VIP })}
                          value={input.checked}
                          useStopPropagation
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </HideWhen>
                </div>

                <div className={styles.row}>
                  <Field
                    autocomplete="off"
                    name="phone"
                    validate={(value) => phoneValidInput(value)}
                    required
                  >
                    {({ input, meta }) => (
                      <Input.Phone
                        {...meta}
                        label={intl.formatMessage({
                          id: ETranslations.PHONE,
                        })}
                        value={input.value}
                        required
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <Field
                    name="work_phone"
                    validate={(value) => value && phoneValidInput(value)}
                  >
                    {({ input, meta }) => (
                      <Input.Phone
                        label={intl.formatMessage({
                          id: ETranslations.WORK_PHONE,
                        })}
                        value={input.value}
                        onChange={input.onChange}
                        {...meta}
                      />
                    )}
                  </Field>
                </div>

                <div className={styles.row}>
                  <Field name="email">
                    {({ input }) => (
                      <Input
                        label={intl.formatMessage({
                          id: ETranslations.EMAIL,
                        })}
                        placeholder="@"
                        size={1}
                        type="email"
                        value={input.value || ""}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <Field name="alt_email">
                    {({ input }) => (
                      <Input
                        label={intl.formatMessage({
                          id: ETranslations.ALT_EMAIL,
                        })}
                        placeholder="@"
                        size={1}
                        type="email"
                        value={input.value || ""}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div className={styles.item}>
                  <Field name="comment">
                    {({ input }) => (
                      <Textarea
                        label={intl.formatMessage({
                          id: ETranslations.GUEST_NOTE,
                        })}
                        maxLength={GUEST_COMMENT_LIMIT}
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <Field name="tags">
                  {({ input }) => (
                    <Labeled
                      label={intl.formatMessage({
                        id: ETranslations.PLURAL_TAGS_NOM,
                      })}
                    >
                      <UserTags
                        placeholder={getIntlChooseEntity(
                          ETranslations.PLURAL_TAGS_NOM,
                        )}
                        value={input.value}
                        onChange={input.onChange}
                      />
                    </Labeled>
                  )}
                </Field>
              </div>
            </form>
          </Card.Content>
        </Card>
      )}
    </Form>
  );
};

export default GuestForm;
