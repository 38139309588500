import styles from "../GuestBadge/GuestBadge.module.scss";
import React, { FC, ReactNode } from "react";
import { Button, ButtonProps } from "ui-kit";

export type TButtons = {
  child: ReactNode;
  props: ButtonProps;
};

type TProps = {
  buttons: TButtons[];
  isLoading?: boolean;
};
const Control: FC<TProps> = ({ buttons, isLoading }) => {
  return (
    <div className={styles.control}>
      {buttons.map(({ child, props }, i) => (
        <Button
          className={styles.button}
          key={i}
          {...props}
          disabled={isLoading}
        >
          {child}
        </Button>
      ))}
    </div>
  );
};

export default Control;
