import { Booking } from 'types/booking';
import { Button, ICONS, Tag } from 'ui-kit';
import { useVisitContext } from './VisitContext';
import { useIntl } from 'react-intl';
import { ETranslations } from '../../types/translates';
import styles from './BaseStatusTag.module.scss';
import { Menu } from 'components/Menu/Menu';
import { isBookingCanBeCanceled } from 'utils';
import { Popup } from 'components/Popup';
import { ConfirmBusyTable } from 'components/ConfirmBusyTable';
import { useStatusActions } from './hooks/useStatusActions';
import {
  useApplyExtraStatusMutation,
  useCleanExtraStatusMutation,
} from 'features/api/bookings-api';
import cn from 'classnames';
import type { ReactNode } from 'react';
import { transformCategoryName } from 'common/helpers';

export const BaseStatusTag = ({
  statusName,
  statusColor,
  className,
  children,
}: {
  statusName: string;
  statusColor: string;
  className: string;
  children?: ReactNode;
}) => {
  const intl = useIntl();
  const { isExpired, isLate } = useVisitContext();

  const getStatusTagProps = () => {
    if (isExpired)
      return {
        label: intl.formatMessage({ id: ETranslations.OUTSTAY }),
        className: cn(styles.bookingStatus, className),
      };
    if (isLate)
      return {
        label: intl.formatMessage({ id: ETranslations.LATE }),
        className: cn(styles.bookingStatus, className),
      };
    return {
      label: statusName,
      className,
      style: { backgroundColor: statusColor || 'gray' },
    };
  };

  return <Tag {...getStatusTagProps()}>{children}</Tag>;
};

export const StatusActions = ({
  booking,
  statusClassName,
}: {
  booking: Booking;
  statusClassName: string;
}) => {
  const {
    hide,
    popup,
    isOpen,
    setIsOpen,
    force,
    goNextStatus,
    resetForce,
    extraStatuses,
  } = useStatusActions(booking);
  const [applyExtraStatus, { isLoading: isApplying }]
    = useApplyExtraStatusMutation();
  const [cleanExtraStatus, { isLoading: isCleaning }]
    = useCleanExtraStatusMutation();
  const isLoading = isApplying || isCleaning;

  return booking.status.category === 'TERMINAL' ? (
    <BaseStatusTag
      statusName={booking.status.name}
      statusColor={booking.status.color}
      className={cn(statusClassName, { [styles.isOpen]: isOpen })}
    />
  ) : (
    <>
      <Popup
        placement="auto-start"
        ref={popup}
        onClose={hide}
        onOpen={setIsOpen}
      >
        <Popup.Trigger>
          <Button
            variant="phantom"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.trigger}
            onMouseDown={(e) => {
              e.preventDefault()
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
            onTouchMove={(e) => {
              e.stopPropagation();
            }}
            onTouchStartCapture={(e) => {
              e.stopPropagation();
            }}
          >
            <BaseStatusTag
              statusName={booking.status.name}
              statusColor={booking.status.color}
              className={cn(statusClassName, { [styles.isOpen]: isOpen })}
            >
              <ICONS.ArrowDown
                width={16}
                height={16}
                className={cn(styles.arrow, {
                  [styles.isOpen]: isOpen,
                })}
              />
            </BaseStatusTag>
          </Button>
        </Popup.Trigger>
        <Popup.Content noPadding>
          <Menu
            className={styles.menu}
            onClick={(e) => {
              e.stopPropagation();
            }}
            disableDnD
          >
            <h2 className={styles.category}>
              {transformCategoryName(booking.status.category)}
            </h2>
            {extraStatuses?.map((status) => (
              <Menu.Item
                className={cn(styles.item, {
                  [styles.active]: booking.extraStatus?.id === status.id,
                  [styles.disabled]: isLoading,
                })}
                key={status.id}
                onClick={() =>
                  (booking.extraStatus?.id === status.id
                    ? cleanExtraStatus(booking.bookingId)
                    : applyExtraStatus({
                        bookingId: booking.bookingId,
                        statusId: status.id,
                      })
                  ).then(popup.current?.close)
                }
              >
                <div
                  className={styles.circle}
                  style={{ backgroundColor: status.color }}
                />
                <span className={styles.statusName}>{status.name}</span>
              </Menu.Item>
            ))}
          </Menu>
        </Popup.Content>
      </Popup>
      <ConfirmBusyTable
        isOpen={force}
        confirm={goNextStatus}
        decline={resetForce}
      />
    </>
  );
};
