import { useGuestsList } from "../../api/guest-api";
import { selectedTags } from "../selectors";
import { restaurantSelector } from "features/AppContex";
import { selectedGuestSelector } from "features/BookingFormProxy/selectors";
import { useTagsOptions } from "features/api/tags";
import { useIntlUtils } from "hooks/useIntlUtils";
import _ from "lodash";
import { SingleOption } from "models/common";
import { useEffect } from "react";
import { useSelector } from "react-redux";

type SelectGuestFromListProps = {
  onClose?: () => void;
  onOneGuestLeft?: (client: Client) => void;
};

const useSelectGuestFromList = ({
  onClose,
  onOneGuestLeft,
}: SelectGuestFromListProps) => {
  const { getIntlChooseEntity, intl } = useIntlUtils();
  const userSelectedTags = useSelector(selectedTags);
  const selectedGuest = useSelector(selectedGuestSelector);
  const restaurant = useSelector(restaurantSelector).restaurant_id + "";
  const tagsOptions = useTagsOptions({
    type: "CLIENT",
    owner_type: restaurant,
    include_deleted: false,
  });
  const { guests, isLoading, handleScroll, updateFilter, filter } =
    useGuestsList();

  useEffect(() => {
    if (guests.length === 0 && filter.phone?.match(/\d/g)?.length === 11) {
      onClose?.();
    }
  }, [guests, onClose, filter]);

  useEffect(() => {
    if (guests.length === 1) {
      //@ts-ignore
      onOneGuestLeft?.(guests[0]);
      selectedGuest && onClose?.();
    }
  }, [guests, onOneGuestLeft, selectedGuest]);

  const handleOnInputTerm = (value: string) => {
    updateFilter({ term: value || undefined });
  };
  const handleTagsChange = (tags: SingleOption[] = []) => {
    const tagsIdArray = tags.map((it) => it.value);
    if (tagsIdArray) {
      updateFilter({ tags: tagsIdArray });
    }
  };

  const getFromFilter = () =>
    tagsOptions.filter((it) => _.includes(userSelectedTags, it.value));

  return {
    isLoading,
    intl,
    getIntlChooseEntity,
    getFromFilter,
    tagsOptions,
    handleOnInputTerm,
    handleScroll,
    guests,
    handleTagsChange,
  };
};

export default useSelectGuestFromList;
