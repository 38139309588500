import dayjs from "dayjs";
import en from "dayjs/locale/en";
import ru from "dayjs/locale/ru";
import {
  useAllStatuses,
  useFetchAllSourcesQuery,
  useTranslates,
} from "features/api/dictionaries-api";
import { useFetchAllRestaurants } from "features/api/restaurants";
import { useUserLocale } from "hooks/useUserLocale";
import { Restaurant } from "models/restaurant.model";
import moment from "moment";
import React from "react";
import { IntlProvider } from "react-intl";
import { ELocales } from "types/commons";
import { Spinner } from "ui-kit";

import { ModalProvider } from "./ModalContext";

type ApplicationContextProps = {
  restaurant: Restaurant;
};

const DAYJS_LOCALE_DISPATCH = {
  [ELocales.ru_RU]: ru,
  [ELocales.en_EN]: en,
};

export const ApplicationContextWrapper: React.FC<
  Omit<ApplicationContextProps, "restaurant"> & any
> = ({ children, ...rest }) => {
  const { userLocale } = useUserLocale();
  const { isSuccess: restFulfilled, restaurant } = useFetchAllRestaurants();
  const { isSuccess: sourcesFulfilled } = useFetchAllSourcesQuery({});
  const { isSuccess: statusesFulfilled } = useAllStatuses();
  const { isSuccess: translatesFulfilled, translates } =
    useTranslates(userLocale);

  const allQuery: boolean[] = [
    restFulfilled,
    sourcesFulfilled,
    statusesFulfilled,
    translatesFulfilled,
  ];
  const isAllFetched = allQuery.every(Boolean);
  const locale = userLocale?.split("_")[0].toLowerCase();

  if (!locale) return <Spinner />;

  moment.locale(locale);
  dayjs.locale(DAYJS_LOCALE_DISPATCH[userLocale]);

  return (
    <IntlProvider defaultLocale={locale} locale={locale} messages={translates}>
      {isAllFetched ? (
        <ModalProvider {...rest} restaurant={restaurant}>
          {children}
        </ModalProvider>
      ) : (
        <Spinner />
      )}
    </IntlProvider>
  );
};
