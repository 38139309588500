import { ColumnBar } from "..";
import styles from "./Header.module.scss";
import { ReactNode } from "react";

interface HeaderProps {
  logo: ReactNode;
  children: ReactNode;
  menuControl: ReactNode;
}

export function Header({ logo, menuControl, children }: HeaderProps) {
  return (
    <header className={styles.header}>
      <div className={styles.menu}>
        <div className={styles.logo}>{logo}</div>
        <div className={styles.menuControl}>{menuControl}</div>
      </div>
      <ColumnBar className={styles.headerContent}>{children}</ColumnBar>
    </header>
  );
}
