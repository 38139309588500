import { Button, ICONS } from "..";

interface OpenMenuProps {
  onClick: () => void;
}

export function OpenMenu({ onClick }: OpenMenuProps) {
  return (
    <Button variant="phantom" onClick={onClick}>
      <img alt="" src={ICONS.menu} />
    </Button>
  );
}
