import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { restaurantSelector } from "features/AppContex/selectors";
import { useTagsOptions } from "features/api/tags";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { TagOption } from "types/tag";
import { SelectTag } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

interface UserTagsProps {
  value: number[];
  onChange(value: number[]): void;
  placeholder?: string;
}

export function UserTags({ value, onChange, placeholder }: UserTagsProps) {
  const { getIntlSelectEntity } = useIntlUtils();
  const { restaurant_id } = useSelector(restaurantSelector);
  const tagsOptions = useTagsOptions({
    type: "CLIENT",
    owner_type: restaurant_id + "",
    include_deleted: false,
  });

  const selected = useMemo(
    () => tagsOptions.filter((t) => value.includes(t.value)),
    [value, tagsOptions],
  );

  const handleChange = useCallback(
    (tags: TagOption[]) => {
      onChange(tags.map((t) => t.value));
    },
    [onChange],
  );

  useEffect(() => {
    if (Array.isArray(value)) {
      const filteredValues = value?.filter((val) =>
        tagsOptions?.some((option) => option?.value === val),
      );
      onChange(filteredValues);
    }
  }, [tagsOptions]);

  const isTablet = useIsTablet();

  return (
    <SelectTag
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      menuPlacement={isTablet ? "top" : "bottom"}
      options={tagsOptions}
      placeholder={
        placeholder || getIntlSelectEntity(ETranslations.AVAILABLE_TAGS)
      }
      value={selected}
      isSearchable
      isTagsOrSource
      // @ts-ignore
      onChange={handleChange}
    />
  );
}
