import type { DeepPartial } from "@reduxjs/toolkit";
import { GuestFormContainer, TButtons } from "components/GuestInfo";
import { GuestInfo } from "components/GuestInfo/GuestInfo";
import { useAppUsers } from "contexts/AppUsersContext";
import { dateSelector } from "features/AppContex";
import type { FormBooking, Hostess } from "features/BookingFormProxy/types";
import { useGuestListActions } from "features/GuestsList";
import { useTableBookingListActions } from "features/TableBooking/slice";
import { useFetchClientQuery } from "features/api/guest-api";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useIsTablet } from "hooks/useIsTablet";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Card, ICONS, Spinner } from "ui-kit";

import { useFromProxyActions } from "../../features/BookingFormProxy";
import { Client } from "../../models/client.model";
import { useGuestView } from "./GuestViewContext";

export type TDictionary = {
  id: number;
  name: string;
  nameEng: string;
};

export type TView = "info" | "edit" | "create" | null;

type TProps = {
  style?: React.CSSProperties;
  clientId?: Client["client_id"];
};

export function GuestCard({ clientId, style }: TProps) {
  const isTablet = useIsTablet();
  const intlUtils = useIntlUtils();
  const {
    intl,
    isRussianLocale,
    getIntlEntityViewingAction,
    getIntlCreatingOf,
  } = intlUtils;

  const { clearSelectedClient, setSelectedClient } = useGuestListActions();
  const { setClient, setEditClient } = useFromProxyActions();
  const { currentUser } = useAppUsers();

  const [mainView, setMainView] = useGuestView();
  const startDate = useSelector(dateSelector);

  const { reset } = useTableBookingListActions();

  const { data: guest, isFetching } = useFetchClientQuery(clientId ?? 0, {
    skip: !clientId,
    refetchOnMountOrArgChange: 1,
  });
  const loading = isFetching && (!guest || guest?.client_id !== clientId);

  useEffect(() => {
    if (guest) {
      setSelectedClient(guest);
    }
  }, [guest]);

  const navigate = useNavigate();

  const [view, setView] = useState<TView>(null);

  const handleClose = useCallback(() => {
    if (guest) {
      clearSelectedClient();
    }
    setView(null);
  }, [guest, clearSelectedClient]);

  const INFO_BUTTONS: TButtons[] = [
    {
      child: intl.formatMessage({ id: ETranslations.CREATE_BOOKING }),
      props: {
        onClick: () => {
          reset();
          navigate("/create-booking", {
            state: {
              isBookingFormData: true,
              formData: {
                client: guest,
                phone: guest?.phone,
                phoneMode: Boolean(guest?.phone),
                //@ts-ignore
                date: startDate.toDate(),
                hostess: currentUser as Hostess,
              } satisfies DeepPartial<FormBooking>,
            },
          });
        },
        variant: "primary",
      },
    },
    {
      child: <ICONS.Edit />,
      props: {
        onClick: () => {
          setView("edit");
          setEditClient(true);
        },
        variant: "secondary",
      },
    },
  ];

  const CREATE_BUTTONS: TButtons[] = [
    {
      child: intl.formatMessage({ id: ETranslations.SAVE }),
      props: { variant: "primary", form: "guest-form", type: "submit" },
    },
    {
      child: <ICONS.CrossWhite />,
      props: {
        onClick: () => {
          setView("info");
          setEditClient(false);
        },
        variant: "secondary",
      },
    },
  ];

  useEffect(() => {
    if (!clientId) {
      setView(null);
    } else {
      setView("info");
    }
  }, [clientId]);

  useLayoutEffect(() => {
    if (isTablet && view === null) {
      setMainView("view");
    }
  }, [view]);

  useLayoutEffect(() => {
    if (mainView === "create") {
      setView("create");
    }
  }, [mainView]);

  if (loading) return <Spinner />;

  return (
    <Card style={style} onClose={handleClose}>
      {view === "info" && <GuestInfo buttons={INFO_BUTTONS} guest={guest} />}
      {view === "edit" && (
        <GuestFormContainer
          buttons={CREATE_BUTTONS}
          guest={guest}
          setView={setView}
          view={view}
        />
      )}
      {view === "create" && (
        <GuestFormContainer
          buttons={CREATE_BUTTONS}
          setView={setView}
          view={view}
        />
      )}
      {!isTablet && view === null && (
        <div className="guest-card-empty create-guest">
          <div className="img contact" />
          <p>
            {getIntlEntityViewingAction(
              ETranslations.PLURAL_GUESTS_THEY,
              ETranslations.PLURAL_GUESTS_ALT,
            )}
          </p>
          <button
            className="primary"
            type="button"
            onClick={() => setView("create")}
          >
            {getIntlCreatingOf(
              isRussianLocale
                ? ETranslations.PLURAL_GUESTS_ALT
                : ETranslations.PLURAL_GUEST,
            )}
          </button>
        </div>
      )}
    </Card>
  );
}
