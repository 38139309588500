import styles from "./GuestHistory.module.scss";
import { BookingClientHistory } from "components/BookingClientHistory";
import React, { FC } from "react";

type TProps = {
  client_id: number;
};
export const GuestHistory: FC<TProps> = ({ client_id }) => {
  return (
    <div className={styles.main}>
      <BookingClientHistory clientId={client_id} />
    </div>
  );
};
