import { GUEST_COMMENT_CONTEXT_MENU_LIMIT } from "../constants";
import { Client } from "../models/client.model";
import { DeepPartial } from "@reduxjs/toolkit";
import { isNull } from "lodash";
import moment, { Moment } from "moment";
import { Booking, BookingOrder, ManagerialTable } from "types/booking";

export function getBookingClientCommentShort(client: Client) {
  const { comment } = client;
  if (comment) {
    return `${comment.slice(0, GUEST_COMMENT_CONTEXT_MENU_LIMIT)}...`;
  }
  return "";
}

export function getBookingStartTime(
  booking: Booking | BookingOrder | ManagerialTable,
): Moment {
  return moment(
    `${booking.bookingDate} ${booking.bookingTime}`,
    "YYYY-MM-DD HH:mm",
  ).startOf("minute");
}

export function getBookingEndTime(booking: Booking): Moment {
  return getBookingStartTime(booking).add({ minutes: booking.visitTime });
}

export function isBookingOrder(
  booking: Booking | BookingOrder | ManagerialTable,
): booking is BookingOrder {
  return Boolean((booking as BookingOrder).orderId);
}

export function isBooking(
  booking: Booking | BookingOrder | ManagerialTable,
): booking is Booking | ManagerialTable {
  return Boolean((booking as Booking).bookingId);
}

export function getBookingId(
  booking: Booking | BookingOrder | ManagerialTable,
) {
  return isBooking(booking) ? booking.bookingId : booking.orderId;
}

export function getManagerialTableId(booking: ManagerialTable) {
  return booking.notes.management_table_id;
}

export function isManagerialTable(
  booking: Booking | BookingOrder | ManagerialTable,
): booking is ManagerialTable {
  return isNull((booking as Booking)?.bookingId);
}

export function isBookingCanBeCanceled(booking: DeepPartial<Booking>) {
  // todo: refactoring hardcode
  return !["IN_HALL", "CANCELED", "CLOSED", "NOT_COME"].includes(
    booking.status?.system_name || "",
  );
}
