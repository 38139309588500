import { IDialogsApi } from "./api";
import {
  Dialog,
  DialogChannels,
  DialogsFilter,
  DialogsMessage,
} from "./entities";
import { IDialogsSocketApi } from "./socket";
import { Subscription } from "./utils";

/*
    ____,-------------------------------,____
    \   |          Global Store         |   /
    /___|-------------------------------|___\
*/

const SOCKET_MESSAGE_THROTTLE_MS = 200;

// TODO: удалять по таймеру searches и dialogs без подписок
export class DialogsStore {
  constructor(
    private api: IDialogsApi,
    private socket: IDialogsSocketApi,
  ) {
    // 1. ресурсы

    this.searches = new Registry(this.fetchSearch.bind(this));
    this.dialogs = new Registry(this.fetchDialog.bind(this));
    this.dialogMessages = new Registry(this.fetchDialogMessages.bind(this));
    this.taskMessages = new Registry(this.fetchTaskMessages.bind(this));
    this.dialogAllowedMessengers = new Registry(
      this.fetchDialogAllowedMessengers.bind(this),
    );
    this.customerAllowedMessengers = new Registry(
      this.fetchCustomerAllowedMessengers.bind(this),
    );

    // 2. сокеты

    this.socket.onConnect(() => this.onSocketConnect());
    this.socket.onDisconnect(() => this.onSocketDisconnect());
    this.socket.onMessage((msg) => this.onSocketMessage(msg));

    this.armWaitForSocket();
  }

  // -- Ресурсы

  searches: Registry<string[], DialogsFilter>;
  dialogs: Registry<Dialog>;
  dialogMessages: Registry<DialogsMessage[]>;
  taskMessages: Registry<DialogsMessage[]>;
  dialogAllowedMessengers: Registry<DialogChannels[]>;
  customerAllowedMessengers: Registry<DialogChannels[]>;

  // -- Подгрузка ресурсов

  private async fetchSearch(filter: DialogsFilter): Promise<string[]> {
    if (this.socketPromise) await this.socketPromise;

    const data = await this.api.search(filter);
    const time = Date.now();

    return data.map((d) => {
      this.dialogs.upsert(d.id, d, time);
      return d.id;
    });
  }

  private async fetchDialog(id: string): Promise<Dialog> {
    if (this.socketPromise) await this.socketPromise;

    const dialog = await this.api.byId(id);
    if (!dialog) throw new Error(`Dialog with id ${id} not found`);

    return dialog;
  }

  private async fetchDialogMessages(id: string): Promise<DialogsMessage[]> {
    if (this.socketPromise) await this.socketPromise;

    return this.api.messages(id);
  }

  private async fetchTaskMessages(taskId: string): Promise<DialogsMessage[]> {
    if (this.socketPromise) await this.socketPromise;

    return this.api.taskMessages(taskId);
  }

  private async fetchDialogAllowedMessengers(
    dialogId: string,
  ): Promise<DialogChannels[]> {
    return this.api.allowedMessengers(dialogId);
  }

  private async fetchCustomerAllowedMessengers(
    customerId: string,
  ): Promise<DialogChannels[]> {
    return this.api.customerAllowedMessengers(customerId);
  }

  // -- Сокет

  private socketPromise: Promise<void> | undefined;
  private socketResolve: (() => void) | undefined;
  private timeoutUpdateDirty?: ReturnType<typeof setTimeout>;

  private armWaitForSocket() {
    // Создать промис, который зарезолвится при соединении сокета
    if (this.socket.state !== "connected") {
      this.socketPromise = new Promise((r) => {
        this.socketResolve = r;
      });
    }
  }

  private disarmWaitForSocket() {
    // Зарезолвить и подчистить промис
    this.socketResolve?.();
    this.socketPromise = this.socketResolve = undefined;
  }

  private async onSocketConnect() {
    this.disarmWaitForSocket();

    // 1. пометить все ресурсы как dirty

    this.markAllDirty();

    // 2. перезагрузить данные для всех dirty ресурсов c подписками, исключая диалоги

    this.searches.all().forEach(this.loadDirtySubscribed);
    this.dialogMessages.all().forEach(this.loadDirtySubscribed);
    this.taskMessages.all().forEach(this.loadDirtySubscribed);
    this.dialogAllowedMessengers.all().forEach(this.loadDirtySubscribed);
    this.customerAllowedMessengers.all().forEach(this.loadDirtySubscribed);

    // 3. перегрузить данные для dirty диалогов с подписками, которые НЕ входят в searches

    const toExclude = this.searches.all().reduce((sink, s) => {
      if (s.data) {
        sink.push(...s.data);
      }
      return sink;
    }, [] as string[]);

    this.dialogs
      .all()
      .filter((d) => !toExclude.find((id) => d.key === id))
      .forEach(this.loadDirtySubscribed);
  }

  private onSocketDisconnect() {
    this.armWaitForSocket();

    if (this.timeoutUpdateDirty) {
      clearTimeout(this.timeoutUpdateDirty);
      this.timeoutUpdateDirty = undefined;
    }
  }

  private onSocketMessage(msg: unknown) {
    const message = msg as {
      type?: string;
      data?: {
        dialog_id?: string;
        channel_id?: string;
      };
    };

    const type = message.type;
    const dialogId = message.data?.dialog_id;
    const channelId = message.data?.channel_id;

    // 1. Пометить searches как dirty
    // ОБЩЕЕ ПОРАВИЛО: реагировать на конкретное события "dialog.created" и "dialog.closed"

    if (type === "dialog.created" || type === "dialog.closed") {
      this.searches.markAllDirty();
    }

    // 2. Пометить dialogs как dirty
    // ОБЩЕЕ ПРАВИЛО: реагировать на любые события с data.dialog_id

    if (dialogId && this.dialogs.exists(dialogId)) {
      this.dialogs.get(dialogId).markDirty();
    }

    // 3. Пометить списки сообщений в диалогах и задачах как dirty
    // ОБЩЕЕ ПРАВИЛО: реагировать на любые события с data.channel_id
    //  - диалог — найти по каналу
    //  - задачу — не искать, пометить все (данных в сокете не хватает)
    if (channelId) {
      this.dialogs.all().forEach((d) => {
        // диалоги
        if (d.data?.channels.includes(channelId)) {
          if (this.dialogMessages.exists(d.data.id)) {
            this.dialogMessages.get(d.data.id).markDirty();
          }
        }
        // задачи
        this.taskMessages.markAllDirty();
      });
    }

    // 4. Запланировать обновление всех dirty сущностей

    if (this.timeoutUpdateDirty) return;

    this.timeoutUpdateDirty = setTimeout(() => {
      this.timeoutUpdateDirty = undefined;

      if (this.socket.state !== "connected") {
        // данные обновятся после переподключения
        return;
      }

      this.searches.all().forEach(this.loadDirtySubscribed);
      this.dialogs.all().forEach(this.loadDirtySubscribed);
      this.dialogMessages.all().forEach(this.loadDirtySubscribed);
      this.taskMessages.all().forEach(this.loadDirtySubscribed);
    }, SOCKET_MESSAGE_THROTTLE_MS);
  }

  private markAllDirty() {
    this.searches.markAllDirty();
    this.dialogs.markAllDirty();
    this.dialogMessages.markAllDirty();
    this.taskMessages.markAllDirty();
    this.dialogAllowedMessengers.markAllDirty();
    this.customerAllowedMessengers.markAllDirty();
  }

  private loadDirtySubscribed<X, Y>(r: Resource<X, Y>): Resource<X, Y> {
    if (r.dirty && r.hasSubscribers && r.state !== "loading") {
      r.load();
    }
    return r;
  }
}

// ------------------------------------------
// Загружаемый ресурс

export class Resource<T, TKey = string> {
  constructor(
    public key: TKey,
    private fetch: (id: TKey) => Promise<T>,
    initial?: { data: T; time: number },
  ) {
    this.subscription = new Subscription();

    if (initial) {
      this.toLoaded(initial.data, initial.time);
    } else {
      this.load();
    }
  }

  private promise?: Promise<T>;
  private subscription: Subscription<typeof this>;

  state: "<none>" | "loading" | "loaded" | "error" = "<none>";
  data?: T;
  error?: string;
  dirty = false;
  time?: number;

  set(data: T, time: number): void {
    this.toSideloaded(data, time);
  }

  markDirty(): void {
    this.dirty = true;
  }

  mayForce() {
    return this.state === "error" || (this.state === "loaded" && this.dirty);
  }

  mayExpire(ttl: number) {
    const now = Date.now();
    return (
      this.time !== undefined &&
      now > this.time + ttl &&
      this.state !== "loading"
    );
  }

  async load(): Promise<void> {
    try {
      const promise = this.fetch(this.key);

      this.toLoading(promise);

      const data = await promise;

      if (this.promise === promise) {
        const time = Date.now();
        this.toLoaded(data, time);
      }
    } catch (e) {
      this.toError(e?.toString());
    }
  }

  subscribe(cb: (r: Resource<T, TKey>) => void): () => void {
    this.subscription.subscribe(cb);
    return () => this.subscription.unsubscribe(cb);
  }

  get hasSubscribers(): boolean {
    return this.subscription.size > 0;
  }

  // -----

  private toLoading(promise: Promise<T>) {
    const prev = this.state;
    this.state = "loading";
    this.promise = promise;
    this.dirty = false;
    // о первой загрузке не сообщаем
    if (prev !== "<none>") this.subscription.publish(this);
  }

  private toLoaded(data: T, time: number): void {
    const prev = this.state;
    this.state = "loaded";
    this.data = data;
    this.time = time;
    this.error = undefined;
    this.promise = undefined;
    this.dirty = false;
    // о начальных данных не сообщаем
    if (prev !== "<none>") this.subscription.publish(this);
  }

  private toSideloaded(data: T, time: number) {
    const prevPromise = this.promise;
    this.toLoaded(data, time);
    this.promise = prevPromise;
  }

  private toError(e?: string) {
    this.error = e || "Failed to load";
    this.state = "error";
    this.promise = undefined;
    this.dirty = false;
    this.subscription.publish(this);
  }
}

// ------------------------------------------
// Реестр ресурсов

export class Registry<T, TKey = string> {
  constructor(private fetcher: (id: TKey) => Promise<T>) {}

  private data: Map<string, Resource<T, TKey>> = new Map();

  all(): Array<Resource<T, TKey>> {
    return Array.from(this.data.values());
  }

  exists(key: TKey): boolean {
    const id = this.keyToId(key);
    return this.data.has(id);
  }

  get(id: TKey, options?: { forceRetry?: boolean; ttl?: number }) {
    const r = this.getOrCreate(id);

    const { forceRetry, ttl } = options || {};

    const shouldUpdate = ttl !== undefined && r.mayExpire(ttl);
    const shouldForce = forceRetry && r.mayForce();

    if (shouldForce || shouldUpdate) {
      r.load();
    }

    return r;
  }

  upsert(id: TKey, data: T, time: number) {
    return this.getOrCreate(id, { data, time });
  }

  markAllDirty(): void {
    for (const r of this.data.values()) {
      r.markDirty();
    }
  }

  // -----

  private getOrCreate(key: TKey, initial?: { data: T; time: number }) {
    const id = this.keyToId(key);
    let resource = this.data.get(id);

    if (!resource) {
      resource = new Resource<T, TKey>(key, this.fetcher, initial);
      this.data.set(id, resource);
    } else if (initial) {
      resource.set(initial.data, initial.time);
    }

    return resource;
  }

  private keyToId(obj: unknown): string {
    if (typeof obj === "string") return obj;
    if (typeof obj === "number") return obj.toString();
    if (obj && typeof obj === "object") return this.serializeKey(obj);

    throw Error("Dialogs Registry<T,U>: invalid key type");
  }

  private serializeKey(obj: object): string {
    const sorted = toSortedObject(obj);
    return JSON.stringify(sorted);
  }
}

// Рекурсивно сортируем объект
// ОСТОРОЖНО: сортировка не стабильная для масивов объектов (в диалогах таких нет: только массивы строк)
function toSortedObject(value: unknown): unknown {
  return typeof value === "object"
    ? Array.isArray(value)
      ? value.slice().sort().map(toSortedObject)
      : Object.keys(value as object)
          .sort()
          .reduce(
            (sink, key) => {
              const v = (value as Record<string, unknown>)[key];
              sink[key] = toSortedObject(v);
              return sink;
            },
            {} as Record<string, unknown>,
          )
    : value;
}
