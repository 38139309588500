import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Source } from "../../models/booking.model";
import { SingleOption } from "../../models/common";
import { Restaurant, RestaurantExtended } from "../../models/restaurant.model";
import { dictionariesApi } from "../api/dictionaries-api";
import { State, applicationContextSliceName, initialState } from "./slice";

const restsMap = (input: Restaurant[]): RestaurantExtended[] =>
  input.map((rest) => ({
    ...rest,
    label: rest.restaurant_name,
    value: rest.restaurant_id,
  }));

const selectDomain = (state: any): State =>
  state[applicationContextSliceName] || initialState;

const selectDomainForRestaurants = (state: any): Restaurant[] =>
  state[dictionariesApi.reducerPath].queries["fetchAllRestaurants({})"].data ||
  [];
const selectDomainForSources = (state: any): Source[] =>
  state[dictionariesApi.reducerPath].queries["fetchAllSources({})"].data || [];

// restaurants selectors
export const allRestaurantSelector = createSelector(
  selectDomainForRestaurants,
  (restaurants) => restsMap(restaurants),
);
export const restaurantByIdSelector = createSelector(
  [selectDomainForRestaurants, (__, restId: number) => restId],
  (restaurants, restId) =>
    restsMap(restaurants.filter((it) => it.restaurant_id === restId)),
);
export const placeByRestSelector = createSelector(
  [selectDomainForRestaurants, (__, restId: number) => restId],
  (restaurants, restId) =>
    restaurants
      .filter((it) => it.restaurant_id === restId)
      .map((it) => it.places)
      .flat()
      .map((it) => ({ ...it, value: it.id, label: it.name })),
);

export const sourcesSelector = createSelector(
  selectDomainForSources,
  (sources) => _.sortBy(sources, "source_name"),
);

export const dateSelector = createSelector(selectDomain, (state) =>
  moment(state.date).clone(),
);
export const selectedPlacesSelector = createSelector(
  selectDomain,
  (state) => state.selectedPlaces,
);
export const restaurantSelector = createSelector(
  selectDomain,
  (state) => state?.restaurant || { restaurant: {} },
);
export const placeSelector = createSelector(
  [selectDomain, restaurantSelector],
  (state, rest) => state?.place || rest.places[0]?.id,
);
export const restaurantsSelector = createSelector(
  selectDomain,
  (state) => state.restaurants,
);
export const allPlacesSelector = createSelector(
  restaurantSelector,
  (rest) => rest.places,
);
export const placesOptionsSelector = createSelector(
  restaurantSelector,
  (rest) =>
    [...(rest.places || [])]
      .sort((a, b) => a.weight - b.weight)
      .map((p) => new SingleOption(p.id, p.name)),
);
export const fullPlacesSelector = createSelector(restaurantsSelector, (rests) =>
  rests.flatMap((r) => r.places),
);
export const isDateToday = createSelector(dateSelector, (currentDate) =>
  currentDate.isSame(moment(), "day"),
);

export const isDateFuture = createSelector(dateSelector, (currentDate) =>
  currentDate.isAfter(moment(), "day"),
);

export function useRestsOptions() {
  // const restaurants = {data: [] as Restaurant[]};
  const restaurants = useSelector(restaurantsSelector);

  return useMemo(
    () =>
      restaurants?.map((rest) => ({
        value: rest.restaurant_id,
        label: rest.restaurant_name,
      })),
    [restaurants],
  );
}
