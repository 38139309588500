import { ICONS, Spinner } from "..";
import { ETranslations } from "../../types/translates";
import { Input, InputProps } from "./Input";
import styles from "./SearchInput.module.scss";
import cn from "classnames";
import { memo, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useThrottle, useThrottleFn, useUpdateEffect } from "react-use";

interface SearchInputProps extends Omit<InputProps, "suffix" | "prefix"> {
  onSearch(value: string): void;
  className?: string;
  isLoading?: boolean;
  placeholder?: string;
  value?: string;
}

export const SearchInput = memo(
  ({
    onSearch,
    className,
    value = "",
    isLoading,
    placeholder,
    ...props
  }: SearchInputProps) => {
    const intl = useIntl();
    const [inputValue, setValue] = useState(value);
    const handleChange = useCallback(
      (e) => setValue(e.target.value),
      [setValue],
    );
    const delayedLoading = useThrottle(isLoading, 1e3);

    useThrottleFn(onSearch, 1e3, [inputValue]);

    useUpdateEffect(() => {
      setValue(value ?? "");
    }, [value]);

    return (
      <Input
        {...props}
        className={cn(styles.search, className)}
        placeholder={
          placeholder || intl.formatMessage({ id: ETranslations.BASE_SEARCH })
        }
        prefix={<img alt="" className={styles.icon} src={ICONS.search} />}
        suffix={
          (isLoading || delayedLoading) && (
            <Spinner className={styles.spinner} />
          )
        }
        value={inputValue}
        onChange={handleChange}
      />
    );
  },
);
