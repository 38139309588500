import { Button, ICONS } from "..";
import styles from "./Collapse.module.scss";
import cn from "classnames";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import { useToggle } from "react-use";

interface CollapseProps {
  header: React.ReactNode;
  onClick?: () => void;
  headerClassName?: string;
  iconClassName?: string;
  initialOpen?: boolean;
  autoHeight?: boolean;
  expendInHeader?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
  header,
  children,
  onClick,
  headerClassName,
  iconClassName,
  initialOpen,
  autoHeight,
  expendInHeader = true,
}) => {
  const [isActive, toggleIsActive] = useToggle(Boolean(initialOpen));

  const content = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    const { current: container } = content;
    if (!container) return;
    if (isActive) {
      container.style.height = "";
      const { scrollHeight } = container;
      container.style.height = autoHeight ? "auto" : `${scrollHeight}px`;
    } else {
      container.style.height = "0";
    }
  }, [children, isActive]);

  const toggle = useCallback(
    (e) => {
      e.stopPropagation();
      toggleIsActive();
    },
    [toggleIsActive],
  );

  return (
    <div className={styles.collapse}>
      <div
        className={cn(styles.header, headerClassName, {
          [styles.active]: isActive,
        })}
        onClick={expendInHeader ? onClick || toggle : () => {}}
      >
        {header}
        {children ? (
          <Button
            className={cn(styles.chevron__block, { [styles.rotate]: isActive })}
            variant="phantom"
            onClick={toggle}
          >
            <ICONS.ArrowDown className={cn(styles.icon, iconClassName)} />
          </Button>
        ) : null}
      </div>
      <div
        className={cn(styles["collapse-body"])}
        ref={content}
        style={{ height: "0" }}
      >
        {children}
      </div>
    </div>
  );
};
