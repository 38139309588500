import { DICTIONARY } from "./utils";
import {
  useCreateGuestMutation,
  useFetchDictionaryQuery,
  useLazyFetchClientQuery,
  useUpdateGuestMutation,
} from "features/api/guest-api";

const useGuestForm = () => {
  const {
    data: salutationDictionary,
    isLoading: isSalutationDictionaryLoading,
  } = useFetchDictionaryQuery(DICTIONARY.salutation);
  const { data: genderDictionary, isLoading: isgenderDictionaryLoading } =
    useFetchDictionaryQuery(DICTIONARY.gender);

  const [updateGuest, { isLoading: isUpdateGuestLoading }] =
    useUpdateGuestMutation();
  const [createGuest, { isLoading: isCreateGuestLoading }] =
    useCreateGuestMutation();
  const [getClient, { isLoading: isGetClientLoading }] =
    useLazyFetchClientQuery();

  const isFormLoading =
    isGetClientLoading ||
    isCreateGuestLoading ||
    isUpdateGuestLoading ||
    isSalutationDictionaryLoading ||
    isgenderDictionaryLoading;

  return {
    salutationDictionary,
    genderDictionary,
    updateGuest,
    createGuest,
    getClient,
    isFormLoading,
  };
};

export default useGuestForm;
