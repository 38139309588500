import styles from "./ToggleSwitch.module.scss";
import cn from "classnames";
import { useState } from "react";

export const ToggleSwitch = ({
  isEnabled,
  onToggle,
  disabled,
}: {
  isEnabled: boolean;
  onToggle: () => Promise<boolean | undefined>;
  disabled: boolean;
}) => {
  const [isActive, setIsActive] = useState(() => isEnabled);

  const handleChangeToggle = () => {
    setIsActive((prev) => !prev); //optimistic update
    onToggle().then((value) => setIsActive((prev) => value ?? !prev)); //real update
  };
  return (
    <label
      className={cn(styles["switch-toggle-container"], {
        [styles.disabled]: disabled,
      })}
    >
      <input
        checked={isActive}
        className={styles["switch-toggle-input"]}
        type="checkbox"
        onChange={handleChangeToggle}
      />
      <span className={styles["switch-toggle"]} />
    </label>
  );
};
