import { SvgForeignObjectBody } from "../../SvgForeignObject";
import styles from "./style.module.scss";
import cn from "classnames";
import React, { FC } from "react";
import { ICONS } from "ui-kit";

interface Props {
  isOverbooking: boolean;
  isVip: boolean;
  isEndingSoon: boolean;
  isManagerial: boolean;
  isDeposit: boolean;
}

export const Notifications: FC<Props> = ({
  isOverbooking,
  isDeposit,
  isVip,
  isManagerial,
  isEndingSoon,
}) => (
  <SvgForeignObjectBody as="div" className={styles.notifications}>
    {isManagerial && (
      <div className={cn(styles.iconWrapper, styles.lockWrapper)}>
        <ICONS.LockIcon className={cn(styles.lock)} />
      </div>
    )}
    {isDeposit && (
      <div className={cn(styles.iconWrapper, styles.deposit)}>$</div>
    )}
    {isOverbooking && (
      <div className={cn(styles.iconWrapper, styles.overbooking)}>!</div>
    )}
    {isVip && <ICONS.VipSign className={styles.vip} />}
    {isEndingSoon && <ICONS.ClockCircleScheme className={styles.endingSoon} />}
  </SvgForeignObjectBody>
);
