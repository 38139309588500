import cn from "classnames";
import { useStatusColor } from "components/HallEditor/BookingTable/useStatusColor";
import {
  moveBookingSelectors,
  useMoveBookingActions,
} from "features/MoveBooking";
import { useSliderDatetime } from "hooks/useSliderDatetime";
import { constant } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";

import { config } from "../../config";
import { EDraggableEntity } from "../../constants";
import { HallMode, useHallSchemaActions } from "../../features/HallSchema";
import {
  activeTablesSelector,
  hallModeSelector,
} from "../../features/HallSchema/selectors";
import {
  useSelectedTableId,
  useTableBookingListActions,
} from "../../features/TableBooking/slice";
import { timelineSelectors, useTimelineActions } from "../../features/Timeline";
import { HallSlotsQResponse } from "../../features/api/hallschema-api";
import { Booking } from "../../types/booking";
import { ICONS } from "../../ui-kit";
import { getBookingStartTime, isManagerialTable } from "../../utils";
import { SCHEME_FACTOR } from "../hall-scheme/redux/HallSchemaV2/hall-schema";
import { SvgForeignObjectBody } from "./SvgForeignObject";
import { Notifications } from "./components/Notifications/Notifications";
import { TableBorder } from "./components/TableBorder/TableBorder";
import { TableNumber } from "./components/TableNumber/TableNumber";
import styles from "./style.module.scss";
import { getSlotExtraOptions, getType, selectSlotByShift } from "./utils";

interface IIsCanDrop {
  ({
    booking,
    table,
  }: {
    booking?: Booking;
    table: HallSlotsQResponse["table"];
  }): boolean;
}

const isCanDrop: IIsCanDrop = ({ booking, table }) => {
  return !booking?.places.find((place) => place.id === table.table_id);
};

export const Table: React.FC<HallSlotsQResponse> = ({ table, slots }) => {
  const { setTime } = useTimelineActions();
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const { selectMoveSource, selectSourceTableNumber } = useMoveBookingActions();
  const { addTargetTables, removeTargetTable, selectTargetBooking } =
    useMoveBookingActions();

  const handleMoveClick = useCallback(
    (booking: Booking) => {
      const tableNumber = booking.places.map((place) => place.number);
      setTime(getBookingStartTime(booking));
      booking.bookingId && selectMoveSource(booking.bookingId);
      selectSourceTableNumber(tableNumber);
      const payload = {
        tableId: table.table_id,
        bookingId: booking?.bookingId,
        tableNumber: table.number.toString(),
      };
      addTargetTables(payload);
    },
    [table],
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [EDraggableEntity.BOOKING_TABLE, EDraggableEntity.BOOKING_CARD],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        booking: monitor.getItem<Booking>(),
        canDrop: isCanDrop({ booking: monitor.getItem<Booking>(), table }),
      }),
      drop: handleMoveClick,
      canDrop: (booking) => {
        return isCanDrop({ booking, table });
      },
    }),
    [table, slots],
  );

  const timeWithDate = useSliderDatetime();
  const hallMode = useSelector(hallModeSelector);
  const activeTables = useSelector(activeTablesSelector);
  const { selectTableInHall, switchMode } = useHallSchemaActions();
  const {
    min_capacity,
    max_capacity,
    table_id,
    schema: { x, y, width, height, shape },
  } = table;
  const slot = selectSlotByShift(slots, timeWithDate());
  const {
    tableColor,
    timeWord,
    timeString,
    tableStatus,
    slot: { booking } = {},
  } = getSlotExtraOptions(slot, timeWithDate());
  const statusColor = useStatusColor(booking, isActualTime, tableColor);
  const { setTable } = useTableBookingListActions();
  const selectedTableId = useSelector(useSelectedTableId);

  const checkBookingTime = useMemo(() => {
    if (!booking) return false;
    const timeOnLine = timeWithDate();
    const timeBooking = moment(
      `${booking?.bookingDate} ${booking?.bookingTime}`,
    ).add(booking?.visitTime, "minute");
    return timeOnLine <= timeBooking;
  }, [timeWithDate, booking]);

  const moveSource = useSelector(moveBookingSelectors.sourceBookingId);
  const isTableSelected = useMemo(
    () => moveBookingSelectors.isTableSelectedFactory(table.table_id),
    [table],
  );
  const isBookingSelected = useMemo(
    () =>
      booking
        ? moveBookingSelectors.isBookingSelectedFactory(booking.bookingId)
        : constant(false),
    [booking],
  );

  const isTableMoveTarget = useSelector(isTableSelected);
  const isBookingMoveTarget = useSelector(isBookingSelected);
  const isMoveTarget = useMemo(
    () => isTableMoveTarget || isBookingMoveTarget,
    [isTableMoveTarget, isBookingMoveTarget],
  );

  const moveBookingDisplayClass = () => {
    if (moveSource && moveSource === booking?.bookingId)
      return styles.moveSource;
    if (isMoveTarget) return styles.moveTarget;
    return undefined;
  };
  const modalType = getType(hallMode, booking?.bookingId);

  const isSelected = useSelector(isTableSelected);

  const tableColorClass =
    modalType === "select-table" &&
    !!activeTables.find((it) => it === table.table_id)
      ? cn(styles[tableColor], styles.selectedTable)
      : styles[tableColor];

  // todo: calculate classes selectedForMove
  const handleTableClick = (e: React.MouseEvent) => {
    // dont push this event higher to dom
    e.stopPropagation();
    if (modalType === "swap-captured") {
      const payload = {
        tableId: table.table_id,
        bookingId: booking?.bookingId,
        tableNumber: table.number.toString(),
      };
      if (isSelected) {
        removeTargetTable(payload);
        return;
      }

      if (booking && isManagerialTable(booking)) {
        // @ts-ignore
        selectTargetBooking({ ...payload, bookingId: null });
      } else if (booking?.bookingId && checkBookingTime) {
        selectTargetBooking(payload);
      } else {
        addTargetTables(payload);
      }
      return;
    }

    if (modalType === "select-table") {
      selectTableInHall(table_id);
      return;
    }

    setTable(table_id);

    if (hallMode !== HallMode.TABLE_BOOKINGS_LIST) {
      switchMode(HallMode.TABLE_BOOKINGS_LIST);
      return;
    }
    // no handle if disable
    if (hallMode === HallMode.TABLE_BOOKINGS_LIST) {
      selectTableInHall(table_id);
    }
  };

  const surname =
    slot?.booking?.client?.surname || slot?.booking?.contact?.surname || null;
  const name =
    slot?.booking?.client?.name || slot?.booking?.contact?.name || null;
  const fullname = useMemo(() => {
    if (!tableStatus) return null;
    return [surname, name]
      .filter((el) => el)
      .map((el, index) =>
        index === 0 ? el : `${el?.charAt(0).toUpperCase()}.`,
      )
      .join(" ");
  }, [slot, tableStatus]);

  // @ts-ignore
  const [, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_TABLE,
      canDrag: config.dragAndDrop && !!fullname,
      item: slot?.booking,
    }),
    [fullname, slot],
  );

  const hasOverbooking = slots.some(({ booking: b }) => b?.isOverbooking);

  const isEndingSoon = useMemo(() => {
    if (!booking || tableColor !== "green") return false;
    const timeOnLine = timeWithDate();
    const timeBooking = moment(
      `${booking?.bookingDate} ${booking?.bookingTime}`,
    ).add(booking?.visitTime, "minute");
    return timeBooking.diff(timeOnLine, "minute") <= 20;
  }, [booking, timeWithDate, tableColor]);

  return (
    <g
      className={`table-${table.number}`}
      ref={drop}
      onClick={handleTableClick}
    >
      <g
        className={cn(
          styles.tableGroup,
          tableColorClass,
          moveBookingDisplayClass(),
          { [styles.selectedTable]: isOver },
        )}
      >
        {/* Рамка стола если бронь на несколько столов */}
        <foreignObject
          height={SCHEME_FACTOR * height + 30}
          width={SCHEME_FACTOR * width + 30}
          x={SCHEME_FACTOR * x - 15}
          y={SCHEME_FACTOR * y - 15}
        >
          <TableBorder
            isShow={Boolean(
              selectedTableId === table.table_id ||
                (booking && booking.places.length > 1),
            )}
            shape={shape}
            statusColor={
              selectedTableId === table.table_id ? undefined : statusColor
            }
          />
        </foreignObject>

        {/* Стол */}
        <foreignObject
          className={cn(styles.tableWrapper, styles[shape])}
          height={SCHEME_FACTOR * height}
          style={{
            background: booking?.extraStatus
              ? `linear-gradient(to right top, ${statusColor} 50%, ${booking.extraStatus.color} 50%)`
              : statusColor,
          }}
          width={SCHEME_FACTOR * width}
          x={SCHEME_FACTOR * x}
          y={SCHEME_FACTOR * y}
        >
          <SvgForeignObjectBody
            aria-label="table-body"
            as="div"
            className={cn(styles.tableBody)}
            ref={drag}
          >
            <div className={cn(styles.tableContainer)}>
              <div className={styles.tableInfo}>
                <ICONS.GuestsIcon className={styles.guestIcon} />
                <span>
                  {tableStatus
                    ? booking?.persons
                    : `${min_capacity || 1}-${max_capacity || 1}`}
                </span>
              </div>
              <div className={styles.bookingInfo}>
                {Boolean(timeString) && <span>{timeString}</span>}
              </div>
            </div>
          </SvgForeignObjectBody>
        </foreignObject>

        {/* Номер стола */}
        <foreignObject
          height={20 + SCHEME_FACTOR}
          width={SCHEME_FACTOR * width}
          x={SCHEME_FACTOR * x}
          y={SCHEME_FACTOR * y - (20 + SCHEME_FACTOR) / 2}
        >
          <TableNumber
            isBookingSoon={timeWord === "BOOKING_SOON"}
            statusColor={
              selectedTableId === table.table_id ? undefined : statusColor
            }
            tableNumber={Number(table.number)}
          />
        </foreignObject>

        {/* Уведомления снизу стола */}
        <foreignObject
          height={20 + SCHEME_FACTOR}
          width={SCHEME_FACTOR * width}
          x={SCHEME_FACTOR * x}
          y={
            SCHEME_FACTOR * y +
            height * SCHEME_FACTOR -
            (20 + SCHEME_FACTOR) / 2
          }
        >
          <Notifications
            isDeposit={Boolean(booking?.useDeposit)}
            isEndingSoon={isEndingSoon}
            isManagerial={Boolean(booking && isManagerialTable(booking))}
            isOverbooking={hasOverbooking}
            isVip={Boolean(slot?.booking?.client?.vip)}
          />
        </foreignObject>

        {/* ФИО гостя */}
        <foreignObject
          height={20}
          width={SCHEME_FACTOR * width}
          x={SCHEME_FACTOR * x}
          y={SCHEME_FACTOR * y + SCHEME_FACTOR * height + 4}
        >
          <SvgForeignObjectBody as="div" className={styles.fullName}>
            <span ref={dragPreview}>{fullname}</span>
          </SvgForeignObjectBody>
        </foreignObject>
      </g>
    </g>
  );
};
