import { BookingCard } from "components/BookingCard";
import { TaskMessages } from "containers/Dialogs/Messages/Messages";
import type { Booking } from "types/booking";

import styles from "./BookingChat.module.scss";

export const BookingChat = ({ booking }: { booking: Booking }) => (
  <div className={styles.bookingChatContainer}>
    <BookingCard
      booking={booking}
      className={styles.bookingCard}
      isDashboard
      compact
    />
    <TaskMessages
      customerId={String(booking.client?.client_id)}
      taskId={booking.feed_id}
      className={styles.chat}
    />
  </div>
);
