import { useOptions } from "./hooks";
import styles from "./timeline.module.scss";
import cn from "classnames";
import dayjs from "dayjs";
import { defer } from "lodash";
import React, { FC, useEffect, useRef, useState } from "react";

let isShowCounter = 0;

export const TimelineOptions: FC = () => {
  const timelineRef = useRef<HTMLDataListElement>(null);
  const [optionsWidth, setOptionsWidth] = useState<number | null>(null);
  const optionsConfig = useOptions();

  useEffect(() => {
    // В некоторых компонентах не успевает строиться таймлайн, откладываем его построение
    defer(() => {
      if (!timelineRef.current) return;
      setOptionsWidth(timelineRef.current.offsetWidth);
    });
  }, []);

  // Высчитываем позицию лейбла на таймлайне
  const getPositionLabel = (value: number) => {
    if (!optionsWidth) return 0;
    const max = optionsWidth;
    const totalCount = optionsConfig.length - 1;
    const step = max / totalCount;
    const percent = (value * step) / max;
    return percent * 100;
  };

  return (
    <datalist className={styles.time} id="timeline-range" ref={timelineRef}>
      {optionsConfig.map(
        ({ value: el, isShow, shiftName, isShowShiftName }, i) => {
          const position = getPositionLabel(i);
          if (isShow) isShowCounter += 1;
          return (
            <span
              className={cn(styles.option)}
              key={el + i}
              style={{
                left: position + "%",
                transform: `translateX(-${position}%)`,
              }}
            >
              <span
                className={cn(
                  styles.label,
                  isShow && styles.labelShow,
                  isShowCounter % 2 && styles.labelUneven,
                )}
              >
                {dayjs(el).format("HH:mm")}
              </span>
              {isShowShiftName && (
                <span className={styles.shiftName}>{shiftName}</span>
              )}
            </span>
          );
        },
      )}
    </datalist>
  );
};
