import { BookingCard } from "components/BookingCard";
import React from "react";
import { Form } from "react-final-form";
import { ManagerialTable } from "types/booking";
import { Card, Spinner } from "ui-kit";

import ManagerialModalError from "./ManagerialModalError";
import { ManagerialTablesForm } from "./ManagerialTablesForm";
import useEditManagerialBooking from "./hooks/useEditManagerialBooking";
import stlyes from "./styles.module.scss";

interface EditManagerialBookingProps {
  booking: ManagerialTable;
}

export const EditManagerialBooking = ({
  booking,
}: EditManagerialBookingProps) => {
  const {
    isLoading,
    closeEdit,
    data,
    closeErrorModal,
    value,
    onSubmit,
    managerialError,
  } = useEditManagerialBooking(booking);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card onClose={closeEdit}>
      <div className={stlyes.content}>
        <BookingCard booking={data?.data} compact />
      </div>

      {value?.start_date ? (
        <Form
          initialValues={value}
          render={() => {
            return <ManagerialTablesForm editBooking />;
          }}
          onSubmit={onSubmit}
        />
      ) : null}
      {!!managerialError && (
        <ManagerialModalError
          isOpen={!!managerialError}
          text={managerialError}
          onClose={closeErrorModal}
        />
      )}
    </Card>
  );
};
