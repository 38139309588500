import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import BookingHistoryModal from "./BookingHistoryModal";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import type { BookingHistoryResponse } from "types/booking";

const BookingHistoryLog = ({ data }: { data: BookingHistoryResponse }) => {
  const { getIntlJoinedParts, getIntlShowEntity, intl } = useIntlUtils();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const changesParametersTitle = useMemo(
    () => intl.formatMessage({ id: ETranslations.DETAILS }),
    [],
  );

  const modalHandler = useCallback(() => {
    setIsModalOpen((state) => !state);
  }, [isModalOpen]);

  return (
    <div className="logContainer">
      <div className="logContainerHeader">
        <div className="logContainerHeader__item">
          {getIntlJoinedParts([
            ETranslations.BASE_DATE,
            ETranslations.AND,
            ETranslations.BASE_TIME,
          ]).toUpperCase()}
        </div>
        <div className="logContainerHeader__item">
          {intl.formatMessage({ id: ETranslations.BOOKED_BY }).toUpperCase()}
        </div>
        <div className="logContainerHeader__item">
          {intl
            .formatMessage({ id: ETranslations.PLURAL_ACTION })
            .toUpperCase()}
        </div>
        <div className="logContainerHeader__item">
          {changesParametersTitle.toUpperCase()}
        </div>
      </div>
      <div className="logContainerBody">
        {data?.events.map((event) => (
          <div key={event.historyId}>
            <div className="logContainerBody__item">
              {moment(event.time).format("DD.MM.YYYY HH:mm")}
            </div>
            <div className="logContainerBody__item">{event.user.name}</div>
            <div className="logContainerBody__item">
              {intl.formatMessage({ id: `HISTORY_LOG_${event.event}` })}
            </div>
            <div className="logContainerBody__item" onClick={modalHandler}>
              <span className="logContainerBody__item--active">
                {getIntlShowEntity(ETranslations.PLURAL_CHANGES_NOM)}
              </span>
            </div>
          </div>
        ))}
      </div>
      <BookingHistoryModal
        bookingId={data.booking.bookingId}
        isOpen={isModalOpen}
        title={changesParametersTitle}
        onClose={modalHandler}
      />
    </div>
  );
};

export default BookingHistoryLog;
