import styles from "./Sidebar.module.scss";
import cn from "classnames";
import React, { FC, useCallback } from "react";
import { NavLinkProps, NavLink as RouterLink } from "react-router-dom";

interface IProps extends NavLinkProps {
  resetFn?: () => void;
}

export const NavLink: FC<IProps> = ({ resetFn, onClick, ...props }) => {
  const handleClick = useCallback(
    (e) => {
      onClick?.(e);
      resetFn?.();
    },
    [resetFn, onClick],
  );

  return (
    <RouterLink
      {...props}
      className={({ isActive }) =>
        cn({ [styles.active]: isActive }, props.className)
      }
      onClick={handleClick}
    />
  );
};
