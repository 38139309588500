import { useIntlUtils } from "../../hooks/useIntlUtils";
import { useAppUsers } from "contexts/AppUsersContext";
import { usePageId } from "contexts/LayoutContext";
import { ModalContext } from "contexts/ModalContext";
import {
  dateSelector,
  restaurantSelector,
  selectedPlacesSelector,
} from "features/AppContex";
import { useGetAllUsersQuery } from "features/api/user-api";
import { useContext } from "react";
import { useSelector } from "react-redux";

const useSetting = () => {
  const intlUtils = useIntlUtils();
  const {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    setSettingsEditStatusModal,
  } = useContext(ModalContext);

  usePageId("settings");

  const date = useSelector(dateSelector);
  const restaurant = useSelector(restaurantSelector);
  const selectedPlaces = useSelector(selectedPlacesSelector);
  const { userList } = useAppUsers();
  const { data: allUsers } = useGetAllUsersQuery();

  return {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    intlUtils,
    date,
    restaurant,
    selectedPlaces,
    userList,
    allUsers,
    setSettingsEditStatusModal,
  };
};

export default useSetting;
