import { Labeled } from "../Labeled";
import styles from "./Textarea.module.scss";
import { TextareaHTMLAttributes } from "react";

type BaseTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextareaProps extends BaseTextareaProps {
  label?: string;
}

export function Textarea({ label, className, ...props }: TextareaProps) {
  return (
    <Labeled className={className} label={label} required={props.required}>
      <textarea {...props} className={styles.textarea} />
    </Labeled>
  );
}
