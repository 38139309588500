import cn from "classnames";
import { ICONS } from "common/helpers";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import {
  Children,
  ReactElement,
  cloneElement,
  isValidElement,
  memo,
} from "react";
import { Booking, BookingOrder, ManagerialTable } from "types/booking";
import { Button } from "ui-kit";
import { isBooking, stopPropagation } from "utils";

import styles from "./BookingCard.module.scss";
import { BookingClientInfo } from "./BookingClientInfo";
import { BookingHeader } from "./BookingHeader";
import { BookingVisitInfo } from "./BookingVisitInfo";
import { VisitProvider } from "./VisitContext";
import useBookingCard from "./hooks/useBookingCard";

export interface BookingCardViewProps {
  compact?: boolean;
  withDate?: boolean;
  showPhone?: boolean;
  showStatus?: boolean;
}

export interface BookingCardProps<
  T extends Booking | BookingOrder | ManagerialTable,
> extends BookingCardViewProps {
  booking: T;
  active?: boolean;
  onClick?: (booking: T) => void;
  className?: string;
  actions?:
    | ReactElement<{ className?: string }>
    | Array<ReactElement<{ className?: string }>>;
  canDrag?: boolean;
  isDashboard?: boolean;
}

const Card = <T extends Booking | BookingOrder | ManagerialTable>({
  booking,
  active,
  onClick,
  actions,
  className,
  compact,
  withDate,
  showPhone = true,
  showStatus = true,
  canDrag = true,
  isDashboard,
}: BookingCardProps<T>) => {
  const {
    handleClick,
    isExpired,
    isLate,
    label,
    statusClassName,
    statusClassNameManagement,
    phone,
    isManagerialTableBoolean,
    handleTouchStart,
    handleTouchEnd,
    handleContextMenuCapture,
    drag,
    dragPreview,
    isAnonym,
    isEndingSoon,
    isDragging,
  } = useBookingCard({ booking, onClick, canDrag });
  const isStatus = showStatus && isBooking(booking);
  const isPhone = Boolean(
    (showPhone && phone) ||
      (isBooking(booking) && booking.tags.length) ||
      booking.source,
  );

  return (
    <>
      <span ref={dragPreview}></span>
      <div
        onMouseDown={handleTouchStart}
        onMouseLeave={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        onContextMenuCapture={handleContextMenuCapture}
        ref={drag}
        aria-label={label}
        onClick={handleClick}
        className={cn(
          styles.bookingCard,
          styles[statusClassName],
          styles[statusClassNameManagement],
          className,
          {
            [styles.active]: active,
            [styles.interactive]: !!onClick,
            [styles.expired]: isExpired,
            [styles.late]: isLate,
            [styles.noStatusBorder]: !showStatus,
            [styles.dragging]: isDragging,
          },
        )}
      >
        <div className={styles.content}>
          <BookingHeader
            compact={compact}
            status={isStatus && (booking as Booking).status}
            extraStatus={isStatus && (booking as Booking).extraStatus}
            phone={isPhone && phone}
            useDeposit={booking.useDeposit}
            isDashboard={isDashboard}
            isEndingSoon={isEndingSoon}
            tags={isBooking(booking) ? booking.tags : undefined}
            booking={isBooking(booking) && (booking as Booking)}
          >
            <BookingClientInfo
              client={booking.client || booking.contact}
              isManagerialBooking={booking?.seatType === "MANAGER_BOOK"}
            />
          </BookingHeader>
          <BookingVisitInfo
            isManagerialTableBoolean={isManagerialTableBoolean}
            bookingTime={booking.bookingTime.replace(/(\d+:\d+).*/, "$1")}
            places={booking.places}
            persons={booking.persons}
            isOverbooking={isBooking(booking) && booking.isOverbooking}
            comment={booking.comment}
            compact={compact}
            date={withDate ? booking.bookingDate : undefined}
            seatType={booking.seatType}
          />
        </div>
        <div className={styles.actions} onClick={stopPropagation}>
          {Children.map(actions, (el) => {
            if (isValidElement(el)) {
              return cloneElement(el, {
                className: cn(el.props.className, styles.action),
              });
            }
            return null;
          })}
          {!isManagerialTableBoolean && !isAnonym && (
            <ClientInfoPopup
              clientId={booking?.client?.client_id || booking?.contact?.id}
              placement="auto"
            >
              <Button
                variant="phantom"
                type="button"
                className={styles.userInfo}
              >
                <ICONS.Question />
              </Button>
            </ClientInfoPopup>
          )}
        </div>
      </div>
    </>
  );
};

export const BookingCard = memo(
  (props: BookingCardProps<Booking | BookingOrder | ManagerialTable>) => (
    <VisitProvider booking={props.booking}>
      <Card {...props} />
    </VisitProvider>
  ),
) as typeof Card;
