import { useCallback, useMemo, useRef, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Booking, ManagerialTable } from 'types/booking';
import {
  useChangeBookingStatus,
  useGetBookingStatusQuery,
} from 'features/api/bookings-api';
import { useBooleanState } from 'hooks/useBooleanState';
import { useRecoveryBooking } from 'hooks/useRecoveryBooking';
import { useAllStatuses } from 'features/api/dictionaries-api';
import type { ExtraStatus } from 'types/status';
import { useIntlUtils } from 'hooks/useIntlUtils';
import type { PopupRef } from 'components/Popup';

export const useStatusActions = (
  booking: Booking | ManagerialTable,
) => {
  const { intl } = useIntlUtils();
  const { recovery } = useRecoveryBooking(booking);


  const [isOpen, setIsOpen, setIsClose] = useBooleanState(false);

  const popup = useRef<PopupRef>(null);

  const { data: status, isLoading } = useGetBookingStatusQuery(
    isOpen ? booking.bookingId ?? skipToken : skipToken
  );

  // Реализация убрана пока модель экстра статусов еще простая. При потребности - вернуть, но избавиться от слишком частых запросов
  //const { data: extraStatuses } = useGetBookingExtraStatusesQuery(booking.bookingId ?? NaN)
  const { data: statuses } = useAllStatuses();
  const extraStatuses = statuses.filter(
    (s) => s.is_extra && s.is_active && s.category === booking.status?.category
  ) as ExtraStatus[];

  const {
    force,
    nextStatus: goNextStatus,
    terminalStatus,
    resetForce,
  } = useChangeBookingStatus(booking.bookingId, booking.client?.client_id || NaN);

  const nextStatus = useMemo(() => status?.next || status?.terminal, [status]);

  const handleStatusChange = useCallback(async () => {
    try {
      popup.current?.close();
      // eslint-disable-next-line no-unused-expressions
      status?.next ? goNextStatus() : terminalStatus();
    } catch {
      //
    }
  }, [status]);

  const [popupShowCancelStatus, setPopupShowCancelStatus] = useState(false);

  const showCancel = () => setPopupShowCancelStatus(true);
  const hide = () => (setPopupShowCancelStatus(false), setIsClose());

  return {
    isLoading,
    intl,
    nextStatus,
    handleStatusChange,
    recovery,
    showCancel,
    hide,
    popup,
    isOpen,
    setIsOpen,
    force,
    goNextStatus,
    resetForce,
    extraStatuses,
  };
};
