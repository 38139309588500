import { ETranslations } from "../../../types/translates";
import { getRestName } from "../settings-helpers";
import cn from "classnames";
import { OptionAppointRole } from "models/settings.model";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Role } from "services/auth.service";
import { ICONS, Spinner } from "ui-kit";

type AppointRolesProps = {
  isLoading: boolean;
  rolesList?: OptionAppointRole[];
  selectedSectionId?: number;
  onCallModalUser: (user?: any, role?: Role) => void;
  onCallModalConfirm: (id: number, mode: string) => void;
  setActiveRole: (optionRole: OptionAppointRole) => void;
  userName: string;
  sectionDataDeclension: string;
};

export default function AppointRoles(props: AppointRolesProps) {
  const intl = useIntl();

  const intlTitles = useMemo(
    () => ({
      notFound: intl.formatMessage({ id: ETranslations.NOT_FOUND }),
    }),
    [],
  );

  const {
    rolesList,
    userName,
    setActiveRole,
    isLoading,
    selectedSectionId,
    onCallModalConfirm,
    onCallModalUser,
    sectionDataDeclension,
  } = props;

  const isActiveOption = (id: number) =>
    selectedSectionId === id || (rolesList && rolesList.length === 1);

  const getIntlUserName = useCallback(
    (user) => {
      const name = getRestName(user);
      if (name in ETranslations) {
        return intl.formatMessage({ id: name });
      }
      return name;
    },
    [rolesList],
  );

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          className={`appointRoles sections include-table ${
            rolesList?.length === 1 ? "one-tab" : ""
          }`}
        >
          {rolesList && rolesList.length ? (
            rolesList.map((option) => (
              <div
                className={`rest count-${option.data.length} ${
                  isActiveOption(option.id) ? "active" : ""
                }`}
                key={option.id}
              >
                <div
                  className="rest-info blocks"
                  onClick={() => setActiveRole(option)}
                >
                  <span>{intl.formatMessage({ id: option.label })}</span>
                  {rolesList?.length !== 1 && (
                    <figure
                      className={cn("arrow", {
                        active: isActiveOption(option.id),
                      })}
                    >
                      <ICONS.ArrowDown />
                    </figure>
                  )}
                </div>
                <div
                  className="table rows custom-scroll"
                  // onAnimationStart={(e: any) => e.target.classList.remove('opened')}
                  // onAnimationEnd={(e: any) => e.target.classList.add('opened')}
                >
                  {option.data && option.data.length ? (
                    option.data.map((user) => (
                      <div className="table-row blocks" key={user.id}>
                        <div className="details">
                          {user.role !== "RESTAURANT" && (
                            <span className="name">{user.name}</span>
                          )}

                          <div className="rest">
                            <ICONS.RestaurantIcon />
                            <span>{getIntlUserName(user)}</span>
                          </div>

                          {user.phone && user.role !== "ADMINISTRATOR" && (
                            <div className="phone">
                              <ICONS.Call />
                              <span>{user.phone}</span>
                            </div>
                          )}
                        </div>
                        <div className="controls">
                          <div
                            className="edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              onCallModalUser(user);
                            }}
                          />
                          {user.role !== "RESTAURANT" && (
                            <img
                              alt=""
                              src={ICONS.trashBin}
                              onClick={(e) => {
                                e.stopPropagation();
                                user.id &&
                                  onCallModalConfirm(user.id, user.role);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="message">
                      {userName !== ""
                        ? intlTitles.notFound
                        : sectionDataDeclension}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="empty-list">{intlTitles.notFound}</div>
          )}
        </div>
      )}
    </>
  );
}
