import cn from "classnames";
import { config } from "config";
import { TableOption, useTablesInfo } from "hooks/useTablesOptions";
import React, { useMemo } from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { useIntl } from "react-intl";
import { Button, Input, SelectBasic, SelectCheckbox } from "ui-kit";

import { Option } from "../../../common/components/MultiSelect/multi-select";
import { TABLES_TITLES } from "../../../constants";
import { ETranslations } from "../../../types/translates";
import { OpenScheme } from "./OpenScheme";
import useChoiceAutoTableInputRedux from "./hooks/useChoiceAutoTableInputRedux";
import useChoiceTableRedux from "./hooks/useChoiceTableRedux";
import styles from "./style.module.scss";
import { getAutoTable } from "./utils";

const ChoicePerson = ({ onChange, ...props }: any) => {
  const intl = useIntl();
  const tablesInfo = useTablesInfo();
  const {
    input: { onChange: changeTables, value },
  } = useField("tables");
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e);
    // Пока только для DUBAI
    if (config.BRAND === "DUBAI") {
      const visitors = Number(e.currentTarget.value);
      const autoTable = visitors && getAutoTable(tablesInfo, visitors);
      // Если хоть что-то нашли из столов, то выполняем функцию автовыбора стола
      autoTable &&
        // Если нашли подходящий стол, то выбираем его
        value?.[0] !== autoTable.currentTable?.tableId &&
        changeTables([autoTable.currentTable?.tableId]);
    }
  };

  return (
    <Input
      autoComplete="off"
      inputClassName={styles.boldText}
      min="1"
      placeholder={intl.formatMessage({ id: ETranslations.QUANTITY })}
      size={1}
      step="1"
      type="number"
      onChange={handleOnChange}
      {...props}
    />
  );
};

const ChoicePersonSelect = (props: any) => {
  const options = Array.from({ length: 10 }, (_, index) => {
    return { label: index + 1, value: index + 1 };
  });

  const { input } = useField<Option<string> | null>("persons", {
    allowNull: true,
    format: (value): any => {
      if (!value) {
        return { value: 1, label: 1 };
      }
      return { value, label: value };
    },
  });

  const { value, onChange } = input;

  const handleChange = (selectedOption: any) => {
    onChange(selectedOption.value);
  };

  return (
    <SelectBasic
      getOptionValue={(option) => option.value}
      inputClassName={styles.boldText}
      isDisabled={props.disabled}
      options={options}
      size={1}
      {...props}
      value={value}
      onChange={handleChange}
    />
  );
};

const sortTables = (a: TableOption, b: TableOption) =>
  a.number
    .toString()
    .localeCompare(b.number.toString(), undefined, { numeric: true });

const ChoiceTablesRedux = ({
  value = [],
  onChange,
  disabled,
  isTablet,
  isFromManagement,
}: {
  value: number[];
  onChange: (value: number[]) => void;
  disabled?: boolean;
  isTablet?: boolean;
  isFromManagement?: boolean;
}) => {
  const { intl, renderOptions, getSelectedOptions, handleOnChange } =
    useChoiceTableRedux(onChange, isFromManagement, value);

  const options = useMemo(
    () =>
      renderOptions((title, capacity, busy) => (
        <span className={styles.optionLabel}>
          <strong className={cn(styles.optionTitle, { [styles.busy]: busy })}>
            {title}
          </strong>
          <em>{capacity}</em>
        </span>
      )),
    [renderOptions],
  );

  return (
    <SelectCheckbox
      isDisabled={disabled}
      isFromManagement={isFromManagement}
      isValid={Boolean(value?.length)}
      maxMenuHeight={isTablet ? 180 : 220}
      menuPlacement="top"
      options={options as any}
      placeholder={intl.formatMessage({ id: ETranslations.BASE_NUMBER })}
      sortingFn={sortTables}
      titles={TABLES_TITLES}
      value={getSelectedOptions(options) as any}
      isClearable
      sortable
      // @ts-ignore
      onChange={handleOnChange}
    />
  );
};

export const ChoiceAutoTableInputRedux = ({
  disabled,
  isShowTableScheme = false,
  isCreateBooking = false,
}: {
  disabled: boolean;
  isShowTableScheme?: boolean;
  isCreateBooking?: boolean;
}) => {
  const { showSchema, isShowSchema, handleOnChange, isDisabled, intl } =
    useChoiceAutoTableInputRedux(disabled, isCreateBooking, isShowTableScheme);

  return (
    <div
      className={cn({
        [styles.oneElement]: !isShowSchema,
        [styles.twoElems]: isShowSchema,
      })}
    >
      {isShowSchema && (
        <button className={styles.openScheme} disabled={disabled}>
          <OpenScheme onClick={showSchema} />
        </button>
      )}

      <Button
        className="autoChoice"
        disabled={isDisabled}
        variant="phantom"
        onClick={handleOnChange}
      >
        {intl.formatMessage({ id: ETranslations.AUTO_CHOICE })}
      </Button>
    </div>
  );
};

export const ChoicePersonsInput: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChoicePerson {...input} {...meta} {...rest} />;

export const ChoicePersonsInputSelect: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChoicePersonSelect {...input} {...meta} {...rest} />;

export const ChoiceTablesInputRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChoiceTablesRedux {...input} {...meta} {...rest} />;
