import { State, guestListSliceName, initialState } from "./index";
import { createSelector } from "@reduxjs/toolkit";

const selectDomain = (state: any): State =>
  state[guestListSliceName] || initialState;

export const guestListFilter = createSelector(
  selectDomain,
  ({ filter }) => filter,
);
export const guestData = createSelector(
  selectDomain,
  ({ loadedGuests }) => loadedGuests,
);
export const isGuestAttachMode = createSelector(
  selectDomain,
  ({ attachMode }) => attachMode !== "none",
);
export const selectedTags = createSelector(
  selectDomain,
  ({ filter }) => filter.tags,
);
export const selectedClient = createSelector(
  selectDomain,
  (state) => state?.selectedClient,
);
