import cn from "classnames";
import { ClientInfoPopup } from "components/ClientInfoPopup";
import dayjs from "dayjs";
import { type Dialog, DialogStatus } from "features/Dialogs/SDK";
import type { ClientsTags } from "models/client.model";
import type { CRMTag } from "models/tags.model";
import { memo } from "react";
import { type IntlShape, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import type { ClientId } from "types/client";
import type { NonEmptyArray } from "types/commons";
import { ETranslations } from "types/translates";
import { Button, ICONS, Tags } from "ui-kit";

import commonStyles from "../Dialogs.module.scss";
import { createSortedDialogList } from "../utils";
import styles from "./DialogList.module.scss";

const DialogCard = memo(
  ({
    id,
    closed,
    customerName,
    unansweredCount,
    linkedCount,
    date,
    restaurantName,
    clientId,
    tags,
  }: {
    id: string;
    closed: string | false;
    customerName: string;
    unansweredCount: number;
    linkedCount: number | undefined;
    date: string;
    restaurantName?: string;
    clientId: number | `${number}`;
    tags: CRMTag[] | undefined;
  }) => (
    <article className={styles.dialogCard}>
      <NavLink
        className={({ isActive, isPending }) =>
          cn(styles.navLink, {
            [styles.active]: isActive,
            [styles.pending]: isPending,
          })
        }
        to={id}
      >
        {closed && (
          <p>
            <span className={styles.closedBadge}>{closed}</span>
          </p>
        )}
        <p>
          <span
            className={cn(styles.fullName, {
              [styles.withUnansweredBadge]: Boolean(unansweredCount),
              [styles.withLinkedBadge]: Boolean(linkedCount),
            })}
          >
            {customerName}
          </span>
          <span className={styles.badges}>
            {Boolean(unansweredCount) && (
              <span className={cn(styles.badge, styles.unansweredCounter)}>
                {unansweredCount}
              </span>
            )}
            {Boolean(linkedCount) && (
              <span className={cn(styles.badge, styles.linkedCounter)}>
                <ICONS.Link />
                {linkedCount}
              </span>
            )}
          </span>
        </p>
        <p>
          <span>{date}</span>
          {restaurantName && (
            <span className={styles.restaurantName}>
              &nbsp;·&nbsp;{restaurantName}
            </span>
          )}
        </p>
        {tags && <Tags className={styles.tags} tags={tags} />}
      </NavLink>
      <div className={styles.clientInfo}>
        <ClientInfoPopup clientId={clientId} placement="auto">
          <Button type="button" variant="phantom">
            <ICONS.Question />
          </Button>
        </ClientInfoPopup>
      </div>
    </article>
  ),
);

export const DialogListContent = ({
  dialogs,
  clientsTags,
}: {
  dialogs: NonEmptyArray<Dialog>;
  clientsTags: ClientsTags | undefined;
}) => {
  const { formatMessage } = useIntl();
  const closedBadgeText = formatMessage({ id: ETranslations.CLOSED });
  return (
    <section className={commonStyles.list}>
      {createSortedDialogList({
        dialogs,
        renderItem: (d) => (
          <DialogCard
            clientId={d.customer.id as `${number}`}
            closed={d.status === DialogStatus.CLOSED && closedBadgeText}
            date={dayjs(
              d.status === DialogStatus.CLOSED
                ? d.closed_at
                : d.last_customer_message_at ?? d.created_at,
            ).format("DD.MM.YY, HH:mm")}
            customerName={formatClientName(d)}
            id={d.id}
            key={d.id}
            restaurantName={d.partner?.name}
            unansweredCount={
              d.status === DialogStatus.CLOSED ? 0 : d.unanswered_count
            }
            linkedCount={d.tasks?.length}
            tags={clientsTags?.[d.customer.id as `${ClientId}`]}
          />
        ),
        unansweredHeader: formatMessage({ id: ETranslations.NOT_ANSWERED }),
        answeredHeader: formatMessage({ id: ETranslations.ANSWERED }),
        headerClassName: commonStyles.divider,
      })}
    </section>
  );
};

function formatClientName(d: Dialog) {
  if (d.customer.name) return d.customer.name;
  if (d.meta?.sender) return `N/A (${d.meta.sender})`;
  return "N/A";
}
