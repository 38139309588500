import { useGuestListActions } from "features/GuestsList";
import { useWebView } from "features/WebView";
import { useFetchClientQuery } from "features/api/guest-api";
import React, { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, Card, ICONS, Spinner } from "ui-kit";
import { getBookingClientCommentShort } from "utils";

import { GuestBadge } from "../GuestBadge/GuestBadge";
import { CountRow } from "./CountRow";
import { ShortInfo } from "./ShortInfo";
import styles from "./ShortUserInfo.module.scss";

interface Props {
  userId: number | `${number}`;
}

export const ShortUserDetail: FC<Props> = ({ userId }) => {
  const intl = useIntl();
  const { data: guest, isLoading } = useFetchClientQuery(userId);
  const { isWebView, notifyApp, createBackUrl } = useWebView();

  const navigate = useNavigate();
  const { setSelectedClient } = useGuestListActions();

  const redirectToGuests = useCallback(() => {
    if (!guest) return;

    if (isWebView) {
      notifyApp({
        type: "SHOW_GUEST",
        clientId: guest.client_id,
        backUrl: createBackUrl(),
      });
    } else {
      navigate("/guests");
      setSelectedClient(guest);
    }
  }, [guest]);

  if (isLoading || !guest)
    return (
      <div className={styles.info}>
        {" "}
        <Spinner />{" "}
      </div>
    );

  return (
    <div className={styles.info}>
      <Card>
        <Card.Header
          title={intl.formatMessage({ id: ETranslations.PROFILE })}
        />
        <Card.Content noPadding>
          <div className={styles.detail} onClick={redirectToGuests}>
            <GuestBadge guest={guest} isHideEditPhoto isShort />
            <Button className={styles.button} variant="phantom">
              <ICONS.ArrowDown className={styles.icon} />
            </Button>
          </div>
          <CountRow
            avgSpend={guest.avg_transaction}
            canceled={guest.cancel_count}
            noShow={guest.not_come_count}
            visits={guest.visit_count}
          />
          <ShortInfo
            birthday={guest.birthday}
            comment={getBookingClientCommentShort(guest)}
            email={guest.email}
            gradeName={guest.grade_name}
          />
        </Card.Content>
      </Card>
    </div>
  );
};
