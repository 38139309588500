import { SvgForeignObjectBody } from "../../SvgForeignObject";
import styles from "./style.module.scss";
import React, { FC } from "react";
import { ICONS } from "ui-kit";

interface Props {
  isBookingSoon: boolean;
  tableNumber: number;
  statusColor?: string;
}

export const TableNumber: FC<Props> = ({
  tableNumber,
  isBookingSoon,
  statusColor,
}) => (
  <SvgForeignObjectBody as="div" className={styles.tableNumberBody}>
    <div
      className={styles.tableNumber}
      style={{
        border: `7px solid ${
          statusColor || "var(--floorplanNumber_empty_stroke)"
        }`,
      }}
    >
      {isBookingSoon && (
        <ICONS.ClockAlarm className={styles.alarmIcon} height={35} width={35} />
      )}
      {tableNumber}
    </div>
  </SvgForeignObjectBody>
);
