import { TablePathSvg } from "./TablePathSvg";
import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import { SlotEvent, TTable } from "features/api/hallschema-api";
import React, { FC } from "react";

interface Props {
  slots: SlotEvent[];
  places: Record<number, TTable>;
}

export const TablePathTemplate: FC<Props> = ({ slots, places }) => {
  return (
    <Template name={Plugins.Sector.TEMPLATES.root}>
      {slots.map((slot) => (
        <TablePathSvg
          key={slot.booking.bookingId}
          places={places}
          slots={[slot]}
        />
      ))}
      <TemplatePlaceholder />
    </Template>
  );
};
