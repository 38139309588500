import styles from "./GuestColumn.module.scss";
import { useIntlUtils } from "hooks/useIntlUtils";
import React, { FC } from "react";
import { TNullable } from "types/commons";
import { ETranslations } from "types/translates";

type TProps = {
  mainTitle: string;
  content: TNullable<{
    title: string;
    content: TNullable<string>;
  }>[];
};
const GuestColumn: FC<TProps> = ({ mainTitle, content }) => {
  const { intl } = useIntlUtils();
  const notIndicated = intl.formatMessage({
    id: ETranslations.BASE_NOT_INDICATED,
  });

  return (
    <div>
      <h2 className={styles.title}>{mainTitle}</h2>
      {content.map(
        (c) =>
          c && (
            <div className={styles.titleBlock} key={c.title}>
              <h3>{c.title}</h3>
              <span className={!c.content ? styles.empty : ""}>
                {c.content || notIndicated}
              </span>
            </div>
          ),
      )}
    </div>
  );
};

export default GuestColumn;
