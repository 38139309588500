import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import BookingHistoryModalBook from "./BookingHistoryModalBook";
import BookingHistoryModalChanges from "./BookingHistoryModalChanges";
import BookingHistoryModalMoving from "./BookingHistoryModalMoving";
import BookingHistoryModalStatus from "./BookingHistoryModalStatus";
import { Modal, ModalProps } from "components/modal";
import { FunctionComponent, useState } from "react";
import { Tabs } from "ui-kit";

type Props = {
  bookingId: number;
} & Omit<ModalProps, "children">;

enum Tab {
  booking = "booking",
  historyChanges = "historyChanges",
  historyStatus = "historyStatus",
  movingPlace = "movingPlace",
}

const BookingHistoryModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  title,
  bookingId,
}) => {
  const { intl, getIntlHistoryOf } = useIntlUtils();
  const [tab, setTab] = useState<string>(Tab.booking);

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose}>
      <Modal.Content className="historyModal">
        <Tabs activeTab={tab} onChange={setTab}>
          <Tabs.Item
            tabName={Tab.booking}
            title={intl.formatMessage({ id: ETranslations.PLURAL_BOOKING })}
          >
            <BookingHistoryModalBook bookingId={bookingId} />
          </Tabs.Item>
          <Tabs.Item
            tabName={Tab.historyChanges}
            title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}
          >
            <BookingHistoryModalChanges bookingId={bookingId} />
          </Tabs.Item>
          <Tabs.Item
            tabName={Tab.historyStatus}
            title={getIntlHistoryOf(ETranslations.PLURAL_STATUSES_ALT)}
          >
            <BookingHistoryModalStatus bookingId={bookingId} />
          </Tabs.Item>
          <Tabs.Item
            tabName={Tab.movingPlace}
            title={getIntlHistoryOf(ETranslations.PLURAL_MOVES_ALT)}
          >
            <BookingHistoryModalMoving bookingId={bookingId} />
          </Tabs.Item>
        </Tabs>
      </Modal.Content>
    </Modal>
  );
};

export default BookingHistoryModal;
