import "./phone-menu.scss";
import cn from "classnames";
import React from "react";

export const PhoneMenu = (props: any) => {
  const { clickHandler } = props;
  return (
    <div className={cn("phone-menu")} onClick={clickHandler}>
      <div className={cn("wrapper")}>
        <div className={cn("bar")} />
        <div className={cn("bar")} />
        <div className={cn("bar")} />
      </div>
    </div>
  );
};
