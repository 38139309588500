import styles from "./SvgForeignObject.module.scss";
import cn from "classnames";
import React, { HTMLAttributes, forwardRef } from "react";

export const SvgForeignObject: React.FC<
  JSX.IntrinsicElements["foreignObject"]
> = ({ children, className, ...props }) => (
  <foreignObject {...props} className={cn(styles.foreignObject, className)}>
    {children}
  </foreignObject>
);

export const SvgForeignObjectBody = forwardRef<
  HTMLBodyElement,
  HTMLAttributes<HTMLBodyElement> & { as?: "div" | "body" }
>(({ children, as: Element = "body", ...props }, ref) => {
  if (process.env.NODE_ENV === "test") return <>{children}</>;
  return (
    // @ts-ignore
    <Element ref={ref} xmlns="http://www.w3.org/1999/xhtml" {...props}>
      {children}
    </Element>
  );
});
