import { ETranslations } from "../../types/translates";
import styles from "./BookingList.module.scss";
import { useBookingList } from "./useBookingList";
import cn from "classnames";
import { BookingActions } from "components/BookingActions";
import { OrderActions } from "components/BookingActions/OrderActions";
import { BookingCard, BookingCardViewProps } from "components/BookingCard";
import { memo } from "react";
import { Booking, BookingOrder, ManagerialTable } from "types/booking";
import { Spinner } from "ui-kit";
import {
  getBookingId,
  getManagerialTableId,
  isBooking,
  isManagerialTable,
} from "utils";

export interface BookingsListProps<
  T extends Booking | BookingOrder | ManagerialTable,
> extends BookingCardViewProps {
  bookings: T[];
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  withActions?: boolean;
  allowMove?: boolean;
  canDrag?: boolean;
  onClick?: (book: Booking) => void;
  onEdit?: ((order: BookingOrder) => void) | ((book: Booking) => void);
}

const List = <T extends Booking | BookingOrder | ManagerialTable>({
  bookings,
  className,
  emptyMessage,
  loading,
  withActions,
  allowMove,
  canDrag = true,
  onClick,
  onEdit,
  ...props
}: BookingsListProps<T>) => {
  const { intl, onSelectBook, onEditBook, selectedBooking, isDashboard } =
    useBookingList();

  if (!bookings.length) {
    return (
      <div className={cn(styles.list, className)}>
        <div className={styles.blank}>
          {emptyMessage ||
            intl.formatMessage({ id: ETranslations.BOOKING_LIST_EMPTY })}
          {loading && <Spinner className={styles.spinner} />}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.list, className)}>
      {bookings.map((b) => (
        <BookingCard
          booking={b as Booking}
          canDrag={canDrag}
          key={
            isManagerialTable(b)
              ? getManagerialTableId(b as ManagerialTable)
              : getBookingId(b)
          }
          onClick={onClick || onSelectBook}
          {...props}
          actions={
            withActions ? (
              isBooking(b) || isManagerialTable(b) ? (
                <BookingActions
                  allowMove={allowMove}
                  booking={b}
                  onEdit={onEdit || (onEditBook as any)}
                />
              ) : (
                <OrderActions order={b} onEdit={onEdit as any} />
              )
            ) : undefined
          }
          active={
            selectedBooking && getBookingId(selectedBooking) === getBookingId(b)
          }
          className={styles.card}
          isDashboard={isDashboard}
        />
      ))}
      {loading && <Spinner className={styles.spinner} />}
    </div>
  );
};
export const BookingsList = memo(List) as typeof List;
