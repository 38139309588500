import { SvgForeignObjectBody } from "../../SvgForeignObject";
import styles from "./style.module.scss";
import cn from "classnames";
import { TableSchema } from "features/api/hallschema-api";
import React, { FC } from "react";

interface Props {
  isShow: boolean;
  shape: TableSchema["shape"];
  statusColor?: string;
}

export const TableBorder: FC<Props> = ({ isShow, shape, statusColor }) => {
  if (!isShow) return null;

  return (
    <SvgForeignObjectBody
      as="div"
      className={cn(styles.border, styles[shape])}
      style={{
        border: `7px solid ${statusColor || "var(--floorplanNumber_default_text)"}`,
      }}
    />
  );
};
