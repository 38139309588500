import type {
  MutationLifecycleApi,
  QueryLifecycleApi,
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn, skipToken } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import {
  allPlacesSelector,
  dateSelector,
  placeSelector,
  restaurantSelector,
  selectedPlacesSelector,
} from "features/AppContex";
import { isDateFuture, isDateToday } from "features/AppContex";
import { bookingFormSliceActions } from "features/BookingFormProxy";
import { FormBooking } from "features/BookingFormProxy/types";
import {
  castFormToCreateDTO,
  castFormToUpdateDTO,
} from "features/BookingFormProxy/utils";
import { useIsTabVisible } from "hooks/useIsTabVisible";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Notification } from "services/notification";
import {
  Booking,
  BookingFilterParams,
  BookingRequestFilter,
  SearchResponse,
  TUseBookingList,
} from "types/booking";
import { ErrorResponse, Response } from "types/commons";
import { GlobalSearchParams } from "types/globalSearch";
import { PageableResponse } from "types/response";
import type { ExtraStatus, Status } from "types/status";

import { BookingResponse } from "../../../models/booking.model";
import { IResponse } from "../../../models/common";
import { BookingsFilter } from "../../../services/bookings.service";
import { TBookingUpdateParams } from "../../../types/IBookingDTO";
import { ETranslations } from "../../../types/translates";
import { apiFrontOffice } from "../api";
import { useAllStatuses } from "../dictionaries-api";
import { guestApi } from "../guest-api";
import { coreApi } from "./core";

type Api<
  QueryArg,
  BaseQuery extends BaseQueryFn,
  ResultType,
  ReducerPath extends string,
> =
  | QueryLifecycleApi<QueryArg, BaseQuery, ResultType, ReducerPath>
  | MutationLifecycleApi<QueryArg, BaseQuery, ResultType, ReducerPath>;

async function handleResponseError<
  QueryArg,
  ResultType = object,
  BaseQuery extends BaseQueryFn = BaseQueryFn,
  ReducerPath extends string = string,
>(
  { queryFulfilled }: Api<QueryArg, BaseQuery, ResultType, ReducerPath>,
  message: string,
) {
  try {
    await queryFulfilled;
  } catch (e) {
    console.error(e);
    if ((e as ErrorResponse)?.error?.name === "AbortError") return;
    Notification.error({
      title: message,
      message: (e as ErrorResponse).error?.data?.errorMessage,
    });
    throw e;
  }
}

export const bookingApi = coreApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBookingSearch: build.query<BookingResponse[], BookingsFilter>({
      query: (filter: BookingsFilter) => ({
        url: "reservation/booking/search",
        method: "POST",
        body: filter,
      }),
      transformResponse: (response: IResponse<BookingResponse[]>) =>
        response.data,
    }),
    fetchActiveBookings: build.query<SearchResponse, BookingRequestFilter>({
      query: (filter: BookingRequestFilter) => ({
        url: "v2/booking/search",
        method: "POST",
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ["Bookings"],
    }),
    fetchTerminateBookings: build.query<SearchResponse, BookingRequestFilter>({
      query: (filter: BookingRequestFilter) => ({
        url: "v2/booking/search/completed",
        method: "POST",
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ["Bookings"],
    }),
    getBookings: build.query<SearchResponse, BookingFilterParams>({
      query: (filter: BookingFilterParams) => ({
        url: "v2/booking/filter",
        method: "POST",
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>, _, args) => {
        const requestParamsDate = args.from;
        return { ...response.data, requestParamsDate };
      },
      providesTags: ["Bookings"],
      keepUnusedDataFor: 0,
      async onQueryStarted(filter, { queryFulfilled, dispatch }) {
        try {
          const { data: bookingsResponse } = await queryFulfilled;
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          if (errorData?.errorCode) {
            Notification.error({
              title: errorData?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    registerBooking: build.mutation<FormBooking, any>({
      query: ({
        data,
        force = false,
      }: {
        data: FormBooking;
        force?: boolean;
      }) => ({
        url: "v2/booking/register",
        params: { force },
        method: "POST",
        body: castFormToCreateDTO(data),
      }),
      invalidatesTags: ["Bookings", "TableOptions"],
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            apiFrontOffice.util.invalidateTags([
              {
                type: "Feeds",
                id: String(
                  args?.data?.client_id || args?.data?.client?.client_id,
                ),
              },
            ]),
          );
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          if (errorData?.errorCode) {
            Notification.error({
              title: errorData?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    getBooking: build.query<Booking, number | `${number}`>({
      query: (id) => ({
        url: `v2/booking/${id}`,
        method: "get",
      }),
      transformResponse: (response: IResponse<Booking>) => response.data,
      providesTags: (booking) => [{ type: "Booking", id: booking?.bookingId }],
      keepUnusedDataFor: 30,
      async onQueryStarted(id, state) {
        if (config.BRAND === "DUBAI") {
          try {
            const response = await state.queryFulfilled;
            const clientId = response.data.client?.client_id;
            if (clientId) {
              const client = await state.dispatch(
                guestApi.endpoints.fetchClient.initiate(clientId, {
                  forceRefetch: 30,
                }),
              );

              const data = client.data || response.data.client;
              state.dispatch(
                bookingApi.util.updateQueryData("getBooking", id, (draft) => {
                  draft.client = data;
                }),
              );
            }
          } catch (e) {
            console.error("error", e);
          }
        }
      },
    }),
    getBookingExtraStatuses: build.query<ExtraStatus[], number>({
      query: (bookingId) => ({
        url: `v2/status/extra/${bookingId}`,
        method: "get",
      }),
      transformResponse: (response: IResponse<ExtraStatus[]>) =>
        response.data.filter(({ is_active }) => is_active),
      providesTags: (_res, _err, bookingId) => [
        { type: "BookingExtraStatus", id: bookingId },
        "Statuses",
        "Bookings",
      ],
    }),
    createBooking: build.mutation<
      IResponse<Booking>,
      {
        data: FormBooking;
        isOverbooking?: boolean;
      }
    >({
      query: ({
        data,
        isOverbooking = false,
      }: {
        data: FormBooking;
        isOverbooking?: boolean;
      }) => ({
        url: "v2/booking/create",
        method: "POST",
        params: { force: isOverbooking || false },
        body: castFormToCreateDTO(data),
      }),
      invalidatesTags: ["Bookings", "TableOptions", "GlobalSearchBookings"],
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            apiFrontOffice.util.invalidateTags([
              {
                type: "Feeds",
                id: String(
                  args?.data?.client_id || args?.data?.client?.client_id,
                ),
              },
            ]),
          );
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          errorData?.errorCode !== 10100 &&
            Notification.error(
              errorData?.errorCode === 10600
                ? {
                    title: ETranslations.UNABLE_TO_CREATE_BOOKING_COVERAGE,
                  }
                : {
                    title: ETranslations.UNABLE_TO_CREATE_BOOKING,
                    message: errorData?.errorMessage,
                  },
            );
          throw err;
        }
      },
    }),
    updateBooking: build.mutation({
      query: ({ force, bookingId, ...body }: TBookingUpdateParams) => ({
        url: `v2/booking/${bookingId}`,
        method: "PUT",
        body,
        params: {
          force,
        },
      }),
      invalidatesTags: (result, err, args) => [
        "Bookings",
        { type: "Booking", id: args.bookingId },
        { type: "BookingHistory", id: args.bookingId },
        "TableOptions",
        "GlobalSearchBookings",
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;
          dispatch(
            apiFrontOffice.util.invalidateTags([
              { type: "Feeds", id: String(args?.client?.client_id) },
            ]),
          );
          dispatch(
            bookingFormSliceActions.setBooking({
              booking: data,
              client: data.client,
            }),
          );
          Notification.success({
            title: ETranslations.BOOKING_UPDATE_SUCCESSFULLY,
          });
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          if (errorData?.errorCode !== 10100) {
            Notification.error({
              title: ETranslations.UNABLE_TO_UPDATE_BOOKING,
              message: errorData?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    applyExtraStatus: build.mutation({
      query: ({
        bookingId,
        statusId,
      }: {
        bookingId: number;
        statusId: number;
      }) => ({
        url: `v2/status/applyExtra/${bookingId}`,
        method: "POST",
        params: { extra_status_id: statusId },
      }),
      invalidatesTags: (result, err, args) => [
        "Bookings",
        "GlobalSearchBookings",
        { type: "Booking", id: args.bookingId },
        { type: "BookingHistory", id: args.bookingId },
      ],
      async onQueryStarted(args, { queryFulfilled }) {
        queryFulfilled.catch((e) => {
          const error = (e as unknown as ErrorResponse)?.error;
          return error?.data?.errorCode === 10000
            ? Notification.error({
                title: error?.data?.errorMessage,
              })
            : Notification.error({
                title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
                message: error?.message,
              });
        });
      },
    }),
    globalSearch: build.query<PageableResponse<Booking>, GlobalSearchParams>({
      query: (params: GlobalSearchParams) => ({
        url: "/v2/booking/search/full",
        params,
      }),
      providesTags: ["GlobalSearchBookings"],
      onQueryStarted: async (params, api) => {
        try {
          const { data: bookingsResponse } = await api.queryFulfilled;
          if (config.BRAND === "DUBAI") {
            const userIds = new Set<number>();
            bookingsResponse.content.forEach(
              (book) =>
                book.client?.client_id && userIds.add(book.client.client_id),
            );

            if (userIds.size) {
              const { data: clientsResponse } = await api.dispatch(
                guestApi.endpoints.updateGuestsForBookings.initiate(
                  Array.from(userIds),
                  { forceRefetch: 30 },
                ),
              );

              const content = bookingsResponse.content.map((book) => ({
                ...book,
                client:
                  clientsResponse?.data.find(
                    (client) => client.client_id === book.client?.client_id,
                  ) || book.client,
              }));

              api.dispatch(
                bookingApi.util.updateQueryData(
                  "globalSearch",
                  params,
                  (draft) => {
                    draft.content = content as Booking[];
                  },
                ),
              );
            }
          }
        } catch (e) {
          console.error(e);
          if ((e as ErrorResponse)?.error?.name === "AbortError") return;
          Notification.error({
            title: ETranslations.SEARCH_REQUEST_FAILED,
            message: (e as ErrorResponse).error?.data?.errorMessage,
          });
          throw e;
        }
      },
    }),
  }),
});

export const {
  useFetchBookingSearchQuery,
  useFetchActiveBookingsQuery,
  useFetchTerminateBookingsQuery,
  useUpdateBookingMutation,
  useCreateBookingMutation,
  useRegisterBookingMutation,
  useGetBookingQuery,
  useLazyGlobalSearchQuery,
  useGetBookingsQuery,
  useGetBookingExtraStatusesQuery,
  useApplyExtraStatusMutation,
} = bookingApi;

export function useBookingsList({
  fromDate,
  forTables,
  search,
  toDate,
  includeStatuses,
  isPooling = true,
  userid,
  isSkip = false,
  withOutPlaces,
  isManageableTableSelected = false,
}: TUseBookingList) {
  const restaurant = useSelector(restaurantSelector);
  const selectedPlaces = useSelector(selectedPlacesSelector);
  const places = useSelector(allPlacesSelector);
  const startDate = useSelector(dateSelector);
  const isBookingForToday = useSelector(isDateToday);
  const isBookingForFuture = useSelector(isDateFuture);

  const includePlaces = useCallback(
    () =>
      selectedPlaces.filter((id) => places.some((place) => place.id === id)),
    [selectedPlaces, places],
  );

  const [currentDate, setCurrentDate] = useState(
    startDate.format("YYYY-MM-DD"),
  );

  // update current date
  useEffect(() => {
    if (fromDate && toDate) return undefined;
    if (!isBookingForToday) {
      setCurrentDate(startDate.format("YYYY-MM-DD"));
      return undefined;
    }
    const interval = setInterval(() => {
      setCurrentDate(moment().format("YYYY-MM-DD"));
    }, 1e3);
    return () => {
      clearInterval(interval);
    };
  }, [isBookingForToday, isBookingForFuture, startDate]);

  const requestParams: BookingFilterParams = {
    restaurant_id: restaurant.restaurant_id,
    from: fromDate ? moment(fromDate).format("YYYY-MM-DD") : currentDate,
    to: toDate ? moment(toDate).format("YYYY-MM-DD") : currentDate,
    only_tables: forTables,
    search_keyword: search,
    sort: [
      {
        param: "date",
        direction: "ASC",
      },
      {
        param: "time",
        direction: "ASC",
      },
    ],
    user_id: userid,
    statuses: includeStatuses,
    management_tables: isManageableTableSelected,
    places: withOutPlaces ? [] : includePlaces(),
  };
  const isTabVisible = useIsTabVisible();

  const result = useGetBookingsQuery(isSkip ? skipToken : requestParams, {
    pollingInterval:
      (isBookingForToday || isBookingForFuture) && isPooling ? 10e3 : undefined,
    skip: !isTabVisible,
    refetchOnFocus: true,
  });

  const isLoading =
    result.isLoading || result.data?.requestParamsDate !== requestParams.from;

  return { ...result, isLoading };
}

const getStatuses = (statuses?: Status[], tableId?: number): string[] => {
  const { data: allStatuses } = useAllStatuses();

  if (statuses?.length) return statuses.map((status) => status.system_name);

  const filterStatuses = allStatuses.reduce(
    (result, status) => (
      status.category !== "TERMINAL" &&
        !status.is_extra &&
        result.push(status.system_name),
      result
    ),
    Array<Status["system_name"]>(),
  );

  if (!tableId && config.BRAND === "DUBAI")
    filterStatuses.push("CANCELED", "NOT_COME");

  return filterStatuses;
};

function useBaseBookingsParams(
  search: string | undefined,
  statuses?: any[],
  tableId?: number,
): BookingFilterParams {
  const restaurant = useSelector(restaurantSelector);
  const date = useSelector(dateSelector);

  return {
    restaurant_id: restaurant.restaurant_id,
    from: date.format("YYYY-MM-DD"),
    to: date.format("YYYY-MM-DD"),
    statuses: getStatuses(statuses, tableId),
    search_keyword: search,
    sort: [
      {
        param: "date",
        direction: "ASC",
      },
      {
        param: "time",
        direction: "ASC",
      },
    ],
  };
}

export const useUpdateBookingHandler = () => {
  const [updateRequest] = useUpdateBookingMutation();

  const updateBookingHandler = useCallback((body: TBookingUpdateParams) => {
    return updateRequest(body);
  }, []);

  return { updateBookingHandler };
};

export const useUpdateBookingFromFormHandler = () => {
  const [updateRequest] = useUpdateBookingMutation();

  const updateBookingHandler = useCallback(
    (formData: FormBooking & Pick<TBookingUpdateParams, "force">) => {
      const data = castFormToUpdateDTO(formData);
      return updateRequest({ ...data, bookingId: formData.bookingId });
    },
    [],
  );

  return { updateBookingHandler };
};

export function useCurrentRestBookings(
  search: string | undefined,
  isTable: boolean,
  statuses?: Status[],
  loadOnlyCurrentPlace?: true,
  tableId?: number,
) {
  const place = useSelector(placeSelector);
  const places = useSelector(selectedPlacesSelector);
  const baseParams = useBaseBookingsParams(search, statuses, tableId);

  return useGetBookingsQuery(
    isTable
      ? skipToken
      : {
          ...baseParams,
          management_tables: true,
          places:
            loadOnlyCurrentPlace &&
            (config.BRAND === "DUBAI" ? places : [place]),
        },
  );
}
