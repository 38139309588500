import { useAllStatuses } from "../../../features/api/dictionaries-api";
import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import styles from "./BookingsReport.module.scss";
import cn from "classnames";
import { ICONS } from "common/helpers";
import { Modal } from "components/modal";
import {
  BookingsReportParams,
  useBookingsReportMutation,
} from "features/api/reports";
import { fillTimeIntervals } from "hooks/useWorkingTimeOptions";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useIntl } from "react-intl";
import type { BaseStatus } from "types/status";
import { DatePicker, SelectBasic, SelectCheckbox } from "ui-kit";

const normalize = ({
  dateFrom,
  dateTo,
  timeTo,
  timeFrom,
  statuses,
  ...rest
}: any): BookingsReportParams => ({
  ...rest,
  start_date: moment(dateFrom).format("YYYY-MM-DD"),
  end_date: moment(dateTo).format("YYYY-MM-DD"),
  start_time: timeFrom?.label,
  end_time: timeTo?.label,
  status: statuses?.map(
    (it: { label: BaseStatus["name"]; value: BaseStatus["system_name"] }) =>
      it.value,
  ),
});

const useLocalizedValidate = () => {
  const intl = useIntl();

  const { endDate, endTime, startTime, startDate } = useMemo(
    () => ({
      startDate: intl.formatMessage({ id: ETranslations.ERROR_START_DATE }),
      endDate: intl.formatMessage({ id: ETranslations.ERROR_END_DATE }),
      startTime: intl.formatMessage({ id: ETranslations.ERROR_START_TIME }),
      endTime: intl.formatMessage({ id: ETranslations.ERROR_END_TIME }),
    }),
    [],
  );
  const validate = (data: any) => {
    const {
      start_date: dateFrom,
      end_date: dateTo,
      start_time: timeFrom,
      end_time: timeTo,
    } = normalize(data);
    const hasTime = timeFrom && timeTo;
    return {
      dateFrom: moment(dateFrom).isAfter(dateTo) ? startDate : undefined,
      dateTo: moment(dateTo).isBefore(dateFrom) ? endDate : undefined,
      timeFrom:
        hasTime && moment(timeFrom, "HH:mm").isAfter(moment(timeTo, "HH:mm"))
          ? startTime
          : undefined,
      timeTo:
        hasTime && moment(timeTo, "HH:mm").isBefore(moment(timeFrom, "HH:mm"))
          ? endTime
          : undefined,
    };
  };

  return { validate };
};

const initial = {
  dateFrom: new Date(),
  dateTo: new Date(),
};
export interface ReportModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export function BookingsReport({ isOpen, closeModal }: ReportModalProps) {
  const {
    intl,
    getIntlSelectEntity,
    getIntlDateOf,
    getIntlStatusOf,
    getIntlBaseSelect,
    isRussianLocale,
    getIntlJoinedParts,
  } = useIntlUtils();
  const { validate } = useLocalizedValidate();
  const { data: statusList } = useAllStatuses();
  const statuses = statusList.filter((status) => !status.is_extra);
  const timeOptions = fillTimeIntervals("00:00:00", "23:59:59", 15);
  const [download] = useBookingsReportMutation();

  const checkboxTitles = useMemo(() => {
    return [
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUS,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUSES_ALT,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUSES,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
      getIntlJoinedParts([
        ETranslations.PLURAL_STATUSES_NOM,
        ETranslations.PLURAL_BOOKINGS_NOM,
      ]),
    ];
  }, []);

  const statusOptions = statuses.map((status) => ({
    value: status.system_name,
    label: status.name,
  }));
  const onSubmit = useCallback(
    async (data) => {
      await download(normalize(data)).unwrap();
    },
    [download],
  );

  return (
    <Form initialValues={initial} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, invalid }) => (
        <Modal
          isOpen={isOpen}
          title={intl.formatMessage({ id: ETranslations.BOOKING_REPORT })}
          onClose={closeModal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Content className={styles.report}>
              <div className={cn(styles["titled-block"], styles["date-time"])}>
                <h3 className={cn(styles.title, styles.required)}>
                  {getIntlSelectEntity(ETranslations.PLURAL_PERIOD)}
                </h3>
                <div>
                  <Field name="dateFrom">
                    {({ input }) => (
                      <DatePicker
                        placeholderText={getIntlDateOf(ETranslations.OF_START)}
                        selected={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <div className={styles.separ}>—</div>
                  <Field name="dateTo">
                    {({ input }) => (
                      <DatePicker
                        placeholderText={getIntlDateOf(ETranslations.OF_END)}
                        selected={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={cn(styles["titled-block"], styles.status)}>
                <h3 className={styles.title}>
                  {getIntlSelectEntity(ETranslations.PLURAL_STATUS)}
                </h3>
                <Field name="statuses" multiple>
                  {({ input }) => (
                    <SelectCheckbox
                      className={styles.status}
                      isClearable={false}
                      listSize={5}
                      maxMenuHeight={150}
                      options={statusOptions}
                      placeholder={getIntlBaseSelect(
                        getIntlStatusOf(
                          isRussianLocale
                            ? ETranslations.PLURAL_BOOKINGS_NOM
                            : ETranslations.PLURAL_BOOKING,
                        ),
                      )}
                      titles={checkboxTitles}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>
              <div className={cn(styles.time, styles["titled-block"])}>
                <h3 className={styles.title}>
                  {getIntlSelectEntity(ETranslations.BASE_TIME)}
                </h3>
                <div>
                  <Field name="timeFrom">
                    {({ input }) => (
                      <SelectBasic
                        maxMenuHeight={200}
                        menuPlacement="top"
                        openTo="top"
                        options={timeOptions}
                        placeholder={intl.formatMessage({
                          id: ETranslations.BASE_TIME,
                        })}
                        value={input.value}
                        changedMaxSize
                        isClearable
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <div className={styles.separ}>—</div>
                  <Field name="timeTo">
                    {({ input }) => (
                      <SelectBasic
                        maxMenuHeight={200}
                        menuPlacement="top"
                        openTo="top"
                        options={timeOptions}
                        placeholder={intl.formatMessage({
                          id: ETranslations.BASE_TIME,
                        })}
                        value={input.value}
                        changedMaxSize
                        isClearable
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </Modal.Content>
            <Modal.Footer>
              <button
                className="primary"
                disabled={invalid || submitting}
                type="submit"
              >
                {intl.formatMessage({ id: ETranslations.UPLOAD_TO_FORMAT })}{" "}
                .xls
                {submitting && <img alt="" src={ICONS.loadingSmall} />}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Form>
  );
}
