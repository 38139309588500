import { UserExtended } from "../../../models/user.model";
import { ETranslations } from "../../../types/translates";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { useIntl } from "react-intl";
import { SelectBasic } from "ui-kit";

type Props = FieldRenderProps<UserExtended, any>;

export const ChoiceHostess = ({
  value,
  onChange,
  disabled,
  options,
  openTo = "top",
  isClearable = false,
  invalid,
  ...rest
}: any) => {
  const intl = useIntl();

  return (
    <SelectBasic
      isClearable={isClearable}
      isDisabled={disabled}
      isValid={!invalid}
      menuPlacement="top"
      openTo={openTo}
      options={options}
      placeholder={intl.formatMessage({ id: ETranslations.BASE_CHOOSE })}
      value={value}
      changedMaxSize
      onChange={onChange}
      {...rest}
    />
  );
};

export const ChoiceHostessInput: React.FC<Props> = ({
  input,
  meta,
  ...rest
}: Props) => <ChoiceHostess {...input} {...meta} {...rest} />;
