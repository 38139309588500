import { BookingCard } from "components/BookingCard";
import { BookingChat } from "components/BookingChat/BookingChat";
import { BookingHistory } from "components/BookingHistory";
import { HideWhen } from "components/HideWhen";
import { EditManagerialBooking } from "components/ManagerialTables/form/EditManagerialBooking";
import { DashboardBookings } from "components/booking/DashboardBookings";
import { CreateBooking } from "components/hall-scheme/redux/Booking/CreateBooking";
import { HallSchema } from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import { EditBooking } from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import { SelectGuestFromList } from "features/GuestsList/components/select-guest-from-list";
import { HallMode } from "features/HallSchema";
import React, { useMemo } from "react";
import { Booking, ManagerialTable } from "types/booking";
import { ETranslations } from "types/translates";
import { Card, Spinner } from "ui-kit";

import useDashBoards, { Tab } from "./useDashBoards";

export function Dashboard() {
  const {
    hasBooking,
    isManagerialTableBooking,
    booking,
    emptyMessage,
    timestamp,
    isTablet,
    isCreatingBooking,
    mode,
    resetBooking,
    closeHall,
    intl,
    closeGuests,
    handleSetClient,
    isGuestMode,
    tab,
    getIntlHistoryOf,
    setTab,
    close,
  } = useDashBoards();

  const EditSection = useMemo(() => {
    if (hasBooking && !isManagerialTableBooking) {
      return <EditBooking bookingId={(booking as Booking).bookingId} />;
    } else if (hasBooking && isManagerialTableBooking) {
      return (
        <EditManagerialBooking
          booking={booking as unknown as ManagerialTable}
        />
      );
    } else {
      return emptyMessage;
    }
  }, [hasBooking, isManagerialTableBooking, booking, timestamp]);

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen
          condition={
            (isTablet && hasBooking) || (isTablet && isCreatingBooking)
          }
          noUnmount
        >
          <DashboardBookings />
        </HideWhen>
      </React.Suspense>
      {isCreatingBooking && (
        <>
          <Card
            style={{
              display: ![HallMode.MANAGERIAL_CREATE_BOOKING].includes(mode)
                ? "none"
                : "initial",
            }}
          >
            <HideWhen
              condition={![HallMode.MANAGERIAL_CREATE_BOOKING].includes(mode)}
              noUnmount
            >
              <CreateBooking
                key={booking?.bookingId}
                reset={resetBooking}
                isFromManagement
              />
            </HideWhen>
          </Card>

          <HideWhen
            condition={
              ![
                HallMode.MANAGERIAL_HALL,
                HallMode.MANAGERIAL_HALL_BOOKING,
              ].includes(mode)
            }
            noUnmount
          >
            <Card onClose={closeHall}>
              <Card.Header
                title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
              />
              <Card.Content>
                <HallSchema />
              </Card.Content>
            </Card>
          </HideWhen>
          <HideWhen
            condition={![HallMode.MANAGERIAL_OPEN_GUESTS].includes(mode)}
            noUnmount
          >
            <div>
              <SelectGuestFromList
                onClose={closeGuests}
                onSelectGuest={handleSetClient}
              />
            </div>
          </HideWhen>
        </>
      )}
      {(isTablet && !hasBooking) || isCreatingBooking ? null : (
        <>
          <CloseBookingContext.Provider value={hasBooking ? close : null}>
            <HideWhen
              condition={mode.includes("HALL") || isGuestMode}
              noUnmount
            >
              <Card onClose={hasBooking ? close : undefined}>
                <Card.HeaderTabs activeTab={tab} onChange={setTab}>
                  <Card.HeaderTabs.Item
                    tabName={Tab.Edit}
                    title={intl.formatMessage({
                      id: ETranslations.BOOKING_MANAGEMENT,
                    })}
                  />
                  <Card.HeaderTabs.Item
                    disabled={!hasBooking}
                    tabName={Tab.History}
                    title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}
                  />
                  {booking && (
                    <Card.HeaderTabs.Item
                      disabled={!booking.client?.client_id}
                      tabName={Tab.Chat}
                      title={intl.formatMessage({ id: ETranslations.CHAT })}
                    />
                  )}
                </Card.HeaderTabs>
                <HideWhen condition={tab !== Tab.Edit} noUnmount>
                  <Card.Content noPadding>{EditSection}</Card.Content>
                </HideWhen>
                <HideWhen condition={tab !== Tab.History}>
                  {booking ? (
                    <BookingHistory booking={booking} isExpanded />
                  ) : (
                    emptyMessage
                  )}
                </HideWhen>
                {booking && booking.client?.client_id && (
                  <HideWhen condition={tab !== Tab.Chat}>
                    <Card.Content noPadding>
                      <BookingChat booking={booking} />
                    </Card.Content>
                  </HideWhen>
                )}
              </Card>
            </HideWhen>
          </CloseBookingContext.Provider>
          {tab === Tab.Edit && (
            <>
              <HideWhen condition={!mode.includes("HALL")}>
                <Card onClose={closeHall}>
                  <Card.Header
                    title={intl.formatMessage({
                      id: ETranslations.HALL_SCHEME,
                    })}
                  />
                  <Card.Content>
                    <HallSchema />
                  </Card.Content>
                </Card>
              </HideWhen>
              <HideWhen
                condition={
                  ![
                    HallMode.BOOKING_GUEST,
                    HallMode.TABLE_BOOKINGS_EDIT_GUEST,
                  ].includes(mode)
                }
              >
                <div>
                  <SelectGuestFromList
                    onClose={closeGuests}
                    onSelectGuest={handleSetClient}
                  />
                </div>
              </HideWhen>
            </>
          )}
        </>
      )}
    </>
  );
}
