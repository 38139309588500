import { TButtons } from "../Control/Control";
import GuestForm from "./GuestForm";
import { TView } from "containers/Guests/GuestCard";
import { Client } from "models/client.model";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Card } from "ui-kit";

type TProps = {
  guest?: Client;
  buttons: TButtons[];
  view: TView;
  setView: Dispatch<SetStateAction<TView>>;
};
export const GuestFormContainer: FC<TProps> = ({
  buttons,
  view,
  guest,
  setView,
}) => {
  const intl = useIntl();

  return (
    <>
      <Card.Header
        title={intl.formatMessage({ id: ETranslations.GUEST_CARD })}
      />
      <Card.Content noPadding>
        <GuestForm
          buttons={buttons}
          guest={guest}
          setView={setView}
          view={view}
        />
      </Card.Content>
    </>
  );
};
