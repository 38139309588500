export const apiORD = "/api";
export const apiORD2 = "/api/v2";
export const apiINT = `${apiORD}/internal`;
export const apiEXT = `${apiORD}/external`;
export const apiINT2 = `${apiORD2}/internal`;
export const apiEXT2 = `${apiORD2}/external`;
export const apiFO = `${apiORD}/frontoffice`;

export const shortPollingInterval = 1e3; // - 1sec
export const middlePollingInterval = 15e3; // - 15sec
export const longPollingInterval = 60e3; // - 1min
