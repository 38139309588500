import { config } from "config";
import { useLocalStorage } from "react-use";
import { ELocales } from "types/commons";

const FALLBACK_LOCALE = ELocales.en_EN;

export function useUserLocale() {
  const [userLocale, setUserLocale] = useLocalStorage<ELocales>(
    "locale",
    config.defaultLocale ?? FALLBACK_LOCALE,
  );

  return {
    userLocale: userLocale ?? FALLBACK_LOCALE,
    setUserLocale,
  };
}
