import cn from "classnames";
import React, { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { components } from "react-select";

import { TPluralsTitles } from "../../types/commons";
import { ETranslations } from "../../types/translates";
import styles from "./Select.module.scss";
import { OptionSelectType, SelectBasic, SelectBasicProps } from "./SelectBasic";
import { SelectStyles, getTitle } from "./helpers";

interface Props extends SelectBasicProps<OptionSelectType> {
  titles: TPluralsTitles | string[];
  styleObject?: SelectStyles;
}

const optionStyles: {option: SelectStyles["option"]} = {
  option: {
    font: "var(--font-12-r)",
    color: "var(--gl_text_inverse)",
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: "transparent",
    },
  },
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className={styles.option}>
          <input checked={props.isSelected} type="checkbox" readOnly />
          <span
            className={cn(styles.mark, {
              [styles.markChecked]: props.isSelected,
            })}
          />
          <label className={styles.optionText}>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export const SelectCheckbox: FC<Props> = ({
  titles,
  isFromManagement,
  styleObject,
  ...props
}) => {
  const intl = useIntl();

  const ValueContainer = useCallback(({ children, ...containerProps }: any) => {
    const { getValue, hasValue, options, selectProps } = containerProps;
    const newChildren = [...children];
    const values = getValue();
    newChildren[0] = getTitle(
      values,
      options.length,
      selectProps.placeholder,
      (titles as TPluralsTitles).map((titleItem) => {
        const isTranslationToken = titleItem in ETranslations;

        return isTranslationToken
          ? intl.formatMessage({ id: ETranslations[titleItem] })
          : titleItem;
      }),
      intl.formatMessage({ id: ETranslations.BASE_ALL }),
      isFromManagement,
    );

    if (!hasValue) {
      return (
        <components.ValueContainer {...containerProps} className={styles.title}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...containerProps} className={styles.title}>
        {newChildren}
      </components.ValueContainer>
    );
  }, []);

  return (
    <SelectBasic
      closeMenuOnSelect={false}
      components={{
        Option,
        ValueContainer,
      }}
      hideSelectedOptions={false}
      isSearchable={false}
      styleObject={{ ...optionStyles, ...styleObject }}
      isClearable
      isMulti
      {...props}
    />
  );
};
