import { noop } from "lodash";
import { ViewGuest } from "models/common";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

// eslint-disable-next-line no-underscore-dangle, max-len
export const __GuestView = createContext<
  [
    ViewGuest | undefined,
    React.Dispatch<React.SetStateAction<ViewGuest | undefined>>,
  ]
>([undefined, noop]);

const GuestView = __GuestView;

export function GuestViewContext({
  children,
  initial = "view",
}: PropsWithChildren<{ initial?: ViewGuest }>) {
  const viewState = useState<ViewGuest | undefined>(initial);

  return <GuestView.Provider value={viewState}>{children}</GuestView.Provider>;
}

export function useGuestView() {
  return useContext(GuestView);
}
