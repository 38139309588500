import { ETranslations } from "../../../types/translates";
import { useVisitContext } from "../VisitContext";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Place } from "types/place";

const useBookingVisitInfo = ({ rawPlaces }: { rawPlaces: Place[] | Place }) => {
  const intl = useIntl();
  const places = useMemo(
    () => (Array.isArray(rawPlaces) ? rawPlaces : [rawPlaces]),
    [rawPlaces],
  );
  const hallTitle = intl.formatMessage({ id: ETranslations.PLURAL_HALL });

  const hallName = useMemo(
    () =>
      Array.from(
        new Set(
          places.map(({ place: { name } }) =>
            name.toLowerCase() === hallTitle
              ? name
              : name.toLowerCase().replace(hallTitle, ""),
          ),
        ),
      ).join(", "),
    [places],
  );

  const { isExpired, isLate, inHall, visitPercents, visitTime } =
    useVisitContext();

  const showProgress = isExpired || isLate || inHall;

  return {
    intl,
    hallName,
    visitPercents,
    visitTime,
    showProgress,
    places,
  };
};

export default useBookingVisitInfo;
