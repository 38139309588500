import { ICONS } from "common/helpers";
import { Menu } from "components/Menu/Menu";
import {
  useCancelBookingMutation,
  useNotComeBookingMutation,
} from "features/api/bookings-api";
import {
  HTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
} from "react";
import { useIntl } from "react-intl";
import { Booking } from "types/booking";

import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import styles from "./CancelActions.module.scss";

export const CancelActions = ({
  booking,
  close,
  className,
}: {
  booking: Booking;
  close?: () => void;
  className?: string;
}) => {
  const { formatMessage } = useIntl();
  const [cancel] = useCancelBookingMutation();
  const [notComeStatus] = useNotComeBookingMutation();
  const cancelBooking = useCallback(() => {
    const { bookingId, client } = booking;
    cancel({ bookingId, clientId: client?.client_id || NaN });
    close?.();
  }, [booking, close]);

  const notCome = useCallback(async () => {
    const { bookingId } = booking;
    await notComeStatus(bookingId).unwrap();
    close?.();
  }, [booking, close]);
  return (
    <>
      <Menu.Item
        onClick={cancelBooking}
        icon={ICONS.Cancel}
        className={className}
      >
        <div className={styles.cancel}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
          <span className={styles.hint}>
            {formatMessage({
              id: ETranslations.CANCELLATION_NOTIFICATION,
            })}
          </span>
        </div>
      </Menu.Item>
      <Menu.Item
        disabled={booking.status?.category === "IN_SERVICE"}
        onClick={notCome}
        icon={ICONS.Eye}
        className={className}
      >
        {formatMessage({ id: ETranslations.DID_NOT_COME })}
      </Menu.Item>
    </>
  );
};

export function CancelActionsContainer({
  back,
  children,
}: {
  back: MouseEventHandler;
  children: ReactNode;
}) {
  const { getIntlReasonOf, isRussianLocale } = useIntlUtils();
  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.header} onClick={back}>
        <ICONS.PrevIcon />
        {getIntlReasonOf(
          isRussianLocale
            ? ETranslations.PLURAL_CANCELLATION_NOM
            : ETranslations.PLURAL_CANCELLATION,
        )}
      </div>
      <Menu>{children}</Menu>
    </div>
  );
}
