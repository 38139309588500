import { apiINT } from "./api-data";
import { AuthService } from "./auth.service";
import { RequestWrapper } from "./request-wrapper";
import { Source, SourceServer } from "models/booking.model";
import { Response } from "models/common";

export class SourcesService {
  static async getAll(): Promise<Response<SourceServer[]>> {
    return RequestWrapper.fetch(`${apiINT}/v2/sources`).then((response) =>
      response.json(),
    );
  }

  static mockAll(): Source[] {
    return [
      new Source("Source_1", 1, 1, "Source_1"),
      new Source("Source_3", 3, 3, "Source_3"),
      new Source("Source_2", 2, 2, "Source_2"),
    ];
  }
}
