import { useTranslates } from "../features/api/dictionaries-api";
import { ETranslations } from "../types/translates";
import { ICONS } from "common/helpers";
import { useUserLocale } from "hooks/useUserLocale";
import { ComponentType, Fragment, ReactNode, useMemo } from "react";
import { ToastOptions, toast } from "react-toastify";
import "styles/notifications.scss";

type NotificationData =
  | {
      title: ReactNode;
      message?: ReactNode | string;
      icon?: ComponentType;
      options?: Omit<ToastOptions, "icon">;
    }
  | string;

function NotificationContent({
  title,
  message,
  icon: Icon = Fragment,
}: Pick<Exclude<NotificationData, string>, "title" | "message" | "icon">) {
  const { userLocale } = useUserLocale();
  const { translates } = useTranslates(userLocale);

  const intlTitle = useMemo(() => {
    if (typeof title === "string" && title in ETranslations) {
      return translates[title as ETranslations];
    }
    return title;
  }, [title]);

  return (
    <div className="Toastify__notificationContent">
      <div className="Toastify__notificationContent-header">
        <Icon />
        <h3>{intlTitle}</h3>
      </div>
      {message && <div className="Toastify__message">{message}</div>}
    </div>
  );
}

const CloseButton = ({ closeToast }: { closeToast(): void }) => (
  <ICONS.Cross onClick={closeToast} className="Toastify__close" />
);

const getArgs = (params: NotificationData) => {
  const { title, message, options, icon } =
    typeof params === "string"
      ? ({ title: params } as Exclude<NotificationData, string>)
      : params;
  return [
    <NotificationContent title={title} message={message} icon={icon} />,
    { ...options, icon: false, closeButton: CloseButton },
  ] as const;
};

export namespace Notification {
  export function info(params: NotificationData) {
    toast.info(...getArgs(params));
  }
  export function success(params: NotificationData) {
    toast.success(...getArgs(params));
  }
  export function warning(params: NotificationData) {
    toast.warning(...getArgs(params));
  }
  export function error(params: NotificationData) {
    toast.error(...getArgs(params));
  }
}
