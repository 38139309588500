import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { Tag } from "../../../models/booking.model";
import { ETranslations } from "../../../types/translates";
import "./style.module.scss";
import { colourStyles } from "common/helpers";
import { restaurantSelector } from "features/AppContex";
import { useTagsOptions } from "features/api/tags";
import { SingleOption } from "models/common";
import React from "react";
import { FieldRenderProps } from "react-final-form";
import { useSelector } from "react-redux";
import { SelectTag } from "ui-kit";

type Props = FieldRenderProps<Tag[], any>;

const ChoiceTags = ({ value = [], onChange, disabled }: any) => {
  const { getIntlChooseEntity } = useIntlUtils();
  const restaurant = useSelector(restaurantSelector).restaurant_id + "";
  const tagOptions = useTagsOptions({
    type: "BOOKING",
    owner_type: restaurant,
    include_deleted: false,
  });
  const selectedOptions = tagOptions.filter(
    (o: SingleOption) =>
      value && value.some((v: SingleOption) => v.value === o.value),
  );

  return (
    <SelectTag
      blurInputOnSelect={false}
      className="multiple-tags"
      closeMenuOnSelect={false}
      isDisabled={disabled}
      menuPlacement="top"
      openTo="top"
      options={tagOptions}
      placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
      styles={colourStyles}
      value={selectedOptions}
      changedMaxSize
      isMulti
      isSearchable
      isTagsOrSource
      onChange={onChange}
    />
  );
};

export const ChoiceTagsInput: React.FC<Props> = ({ input, meta, ...rest }) => (
  <ChoiceTags {...input} {...meta} {...rest} />
);
