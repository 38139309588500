import { TDictionary } from "containers/Guests/GuestCard";
import { TNullable } from "types/commons";

export const DICTIONARY: Record<string, number> = {
  salutation: 116,
  gender: 122,
};

export const buildDictionaryOptions = (
  dictionary: TDictionary[],
  isRussianLocale: boolean,
) =>
  dictionary.map((item) => ({
    label: isRussianLocale ? item.name : item.nameEng,
    value: item.id,
  }));
export const getDictionaryOptionById = (
  dictionary: TDictionary[],
  id: number,
  isRussianLocale: boolean,
): TNullable<{ label: string; value: number }> => {
  const item = dictionary.find((d) => d.id === id);
  if (!item) {
    return null;
  }
  return { label: isRussianLocale ? item.name : item.nameEng, value: item.id };
};
