import { Booking } from "../../types/booking";
import { BookingCardDndLayout } from "./layouts/BookingCardDndLayout";
import { EDraggableEntity } from "constants/dragAndDrop";
import { usePreview } from "react-dnd-preview";

export const DndPreviewContainer = () => {
  const preview = usePreview<Booking, HTMLDivElement>();
  if (!preview.display) {
    return null;
  }
  const { item, style, ref, itemType } = preview;
  if (itemType === EDraggableEntity.TABLE_TEMPLATE) return null;

  return <BookingCardDndLayout booking={item} ref={ref} style={style} />;
};
