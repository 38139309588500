import { useGuestListActions } from "features/GuestsList";
import {
  guestData,
  guestListFilter,
  isGuestAttachMode,
} from "features/GuestsList/selectors";
import type { Tag } from "models/booking.model";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useUnmount } from "react-use";
import { Notification } from "services/notification";
import type { ClientId } from "types/client";
import { v4 as uuid } from "uuid";

import { TDictionary } from "../../containers/Guests/GuestCard";
import {
  Client,
  ClientsTags,
  type ClientsTagsDTO,
} from "../../models/client.model";
import { IResponse } from "../../models/common";
import { ClientsFilter } from "../../services/clients.service";
import { ErrorResponse, TNullable } from "../../types/commons";
import { ETranslations } from "../../types/translates";
import { api } from "./api";

interface CreateGuest {
  data: {
    data: {
      card_number: string;
      client_id: number;
    };
  };
}

interface IUpdateResponse {
  data: {};
  status: "ERROR" | "OK";
  error: TNullable<{
    message: string;
  }>;
}

export const guestApi = api
  .enhanceEndpoints({
    addTagTypes: ["Guests", "Guest"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchGuestsPage: build.query<Client[], ClientsFilter>({
        query: (filter: ClientsFilter) => ({
          url: "v2/client/search",
          method: "POST",
          body: filter,
        }),
        transformResponse: (response: IResponse<Client[]>) =>
          response.data.map(
            (client) => (
              (client.phone =
                client.phone && client.phone.replace(/^([^+])/g, "+$1")),
              client
            ),
          ),
        providesTags: ["Guests"],
        keepUnusedDataFor: 0,
      }),
      fetchDictionary: build.query<TDictionary[], number>({
        query: (id: number) => ({
          url: `v2/dictionary/${id}`,
        }),
        transformResponse: (response: IResponse<TDictionary[]>) =>
          response.data,
      }),
      fetchClient: build.query<Client, number | string>({
        query: (id: number) => ({
          url: `v2/client/${id}`,
        }),
        providesTags: ["Guest"],
        transformResponse: (response: IResponse<Client>) => response.data,
      }),
      fetchGuestDetail: build.query<Client, number | string>({
        query: (clientId: number) => ({
          url: `v2/client/${clientId}`,
        }),
        transformResponse: (response: IResponse<Client>) => response.data,
        providesTags: (result) => [{ type: "Guest", id: result?.client_id }],
      }),
      createGuest: build.mutation<IResponse<Client>, Partial<Client>>({
        query: (data) => ({
          url: "v2/client",
          method: "POST",
          body: {
            login: uuid(),
            type_authorization: "AUTH",
            ...data,
          },
        }),
        invalidatesTags: ["Guests"],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data.status === "ERROR") {
              throw data;
            }
          } catch (err) {
            const e = err as ErrorResponse;
            if (e?.error?.data?.errorCode === 10000) {
              Notification.error({
                title: e.error.data.errorMessage,
              });
            }

            if (e.status === "ERROR") {
              Notification.error({
                title: ETranslations.ERROR_GUEST_CREATION,
                message: e?.error?.message,
              });
            }
            throw e;
          }
        },
      }),
      updateGuest: build.mutation<IUpdateResponse, Client>({
        query: (data) => ({
          url: "v2/client",
          method: "PUT",
          body: {
            login: uuid(),
            type_authorization: "AUTH",
            ...data,
          },
        }),
        invalidatesTags: (r, e, args) => [
          "Guests",
          { type: "Guest", id: args.client_id },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
        // transformResponse: (response: IResponse<IUpdateResponse>) => response,
      }),
      updateGuestsForBookings: build.query<
        { data: Array<Client> },
        Array<number>
      >({
        query: (ids) => ({
          url: "v2/client/clients",
          method: "GET",
          params: {
            client_ids: ids,
          },
        }),
      }),
      getClientsTags: build.query<ClientsTags, (ClientId | `${ClientId}`)[]>({
        query: (clientIds) => ({
          url: `/v2/client/clients/tags`,
          params: { clients: clientIds },
        }),
        providesTags: (r, e, args) =>
          args.map((clientId) => ({ type: "Guest", id: Number(clientId) })),
        transformResponse: (response: IResponse<ClientsTagsDTO>) => {
          const result = ClientsTags.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : {};
        },
      }),
    }),
  });

export const {
  useFetchGuestsPageQuery,
  useFetchGuestDetailQuery,
  useFetchClientQuery,
  useLazyFetchClientQuery,
  useLazyFetchGuestDetailQuery,
  useCreateGuestMutation,
  useFetchDictionaryQuery,
  useUpdateGuestMutation,
  useLazyUpdateGuestsForBookingsQuery,
  useGetClientsTagsQuery,
} = guestApi;

export function useGuestsList() {
  const attachMode = useSelector(isGuestAttachMode);
  const {
    onScrollConcatLists,
    applyFilterConcat,
    loadGuests,
    updateFilter,
    reset,
  } = useGuestListActions();
  const filter = useSelector(guestListFilter);
  const { data, isLoading, status } = useFetchGuestsPageQuery(filter);
  const guests = useSelector(guestData);

  useEffect(() => {
    if (status === "fulfilled" && data) {
      if (attachMode) {
        onScrollConcatLists(data);
      } else {
        loadGuests(data);
      }
    }
  }, [status, data]);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      e.stopPropagation();
      const { offsetHeight, scrollTop, scrollHeight } = e.currentTarget;
      if (scrollTop > 0) {
        if (offsetHeight + scrollTop === scrollHeight) {
          const nextOffset = filter.offset + filter.count;
          applyFilterConcat({ offset: nextOffset, count: filter.count });
        }
      }
    },
    [filter, applyFilterConcat],
  );

  useUnmount(reset);

  return {
    isLoading,
    handleScroll,
    updateFilter,
    guests,
    filter,
  };
}
