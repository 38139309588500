import moment from "moment";
import type { IntlShape } from "react-intl";
import { ETranslations } from "types/translates";

export const MAX_NOTE_CHAR_COUNT = 500;

export const formateTime = (dateTime: string) =>
  dateTime.replace(
    /(\d+:\d+)\s([p | a])(m)+/gi,
    (match, time, meridiem1, meridiem2) =>
      time + ` ${meridiem1}.${meridiem2}`.toLowerCase(),
  );
export const formateDate = (
  dateString: string,
  formatMessage: IntlShape["formatMessage"],
) => {
  const date = moment.utc(dateString).utcOffset(moment().utcOffset() - 180); // -180 так как время на сервере UTC +3
  const now = moment();
  if (now.isSame(date, "day")) {
    return formateTime(
      `${formatMessage({
        id: ETranslations.TODAY,
      })}, ${date.format("LT")}`,
    );
  }
  if (now.subtract(1, "days").isSame(date, "day")) {
    return formateTime(
      `${formatMessage({
        id: ETranslations.YESTERDAY,
      })}, ${date.format("LT")}`,
    );
  }
  return formateTime(date.format("MMMM D, LT"));
};

export type NoteModalSettings =
  | {
      mode: "create" | undefined;
    }
  | { mode: "edit"; noteId: number; note: string }
  | { mode: "delete"; noteId: number; note: "test—delete" };

export const defaultNoteModalSettings: NoteModalSettings = {
  mode: undefined,
};
