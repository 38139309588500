import styles from "./Button.module.scss";
import cn from "classnames";
import { ButtonHTMLAttributes, forwardRef } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant:
    | "primary"
    | "secondary"
    | "dangerous"
    | "status"
    | "light"
    | "thin"
    | "phantom";
  square?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = "button", variant, className, square, ...props }, ref) => (
    <button
      {...props}
      className={cn(styles.button, styles[variant], className, {
        [styles.square]: square,
      })}
      ref={ref}
      type={type}
    />
  ),
);
