import { TAGS_TITLES } from "../../../constants";
import styles from "./select-guest-from-list.module.scss";
import useSelectGuestFromList from "./useSelectGuestFromList";
import cn from "classnames";
import { ICONS, emptyStringIfNull, getMaskPhone } from "common/helpers";
import { ClientInfoPopup } from "components/ClientInfoPopup";
import { TagsById } from "components/Tags";
import { config } from "config";
import { Client } from "models/client.model";
import React, { CSSProperties, ReactElement } from "react";
import { ETranslations } from "types/translates";
import { Button, Card, Collapse, Input, SelectCheckbox, Spinner } from "ui-kit";

type SelectGuestFromListProps = {
  style?: CSSProperties;
  onClose?: () => void;
  onSelectGuest: (guest: Client) => void;
  controls?: ReactElement;
  onOneGuestLeft?: any;
};

export const SelectGuestFromList: React.FC<SelectGuestFromListProps> = ({
  style,
  onClose,
  onSelectGuest,
  controls,
  onOneGuestLeft,
}) => {
  const {
    isLoading,
    intl,
    getIntlChooseEntity,
    getFromFilter,
    tagsOptions,
    handleOnInputTerm,
    handleScroll,
    handleTagsChange,
    guests,
  } = useSelectGuestFromList({ onClose, onOneGuestLeft });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Card style={style} onClose={onClose}>
          <Card.Header
            controls={controls}
            title={intl.formatMessage({ id: ETranslations.GUEST_LIST })}
          >
            <div className={styles.subHeader}>
              <SelectCheckbox
                className={styles.filter}
                options={tagsOptions}
                placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
                titles={TAGS_TITLES}
                value={getFromFilter()}
                // @ts-ignore
                onChange={handleTagsChange}
              />

              <Input.Search
                className={styles.filter}
                onSearch={handleOnInputTerm}
              />
            </div>
          </Card.Header>
          <Card.Content style={{ overflowY: "auto" }} onScroll={handleScroll}>
            <div>
              {guests?.map((g: Client) => (
                <Collapse
                  header={
                    <section
                      className={cn("guest-card", "view", "variant-B")}
                      style={{ width: "100%", border: "none" }}
                    >
                      <div className={styles.wrapper}>
                        <div className={cn("badge")}>
                          <div className="data">
                            <div className="loyalty-bar null" />
                            <ICONS.USER_PHONE />
                            <h3 className="name">
                              {config.vipSign && g.vip && (
                                  <img
                                      className="star"
                                      src={ICONS.StarA}
                                      alt="vip"
                                  />
                              )}
                              {emptyStringIfNull([
                                g.name,
                                g.middle_name as string,
                                g.surname,
                              ])}
                            </h3>
                            <span className="phone">
                              {getMaskPhone(g.phone)}
                            </span>
                            {g.tags && g.tags?.length !== 0 && (
                              <div className={styles.tags}>
                                <TagsById tagsIDs={g.tags} />
                              </div>
                            )}
                          </div>
                        </div>
                        <ClientInfoPopup
                          clientId={g.client_id}
                          placement="auto"
                        >
                          <Button
                            className={styles.guestDetail}
                            type="button"
                            variant="phantom"
                          >
                            <ICONS.Question />
                          </Button>
                        </ClientInfoPopup>
                      </div>
                    </section>
                  }
                  headerClassName={styles.guest}
                  key={g.client_id}
                  onClick={() => onSelectGuest(g)}
                ></Collapse>
              ))}
            </div>
          </Card.Content>
        </Card>
      )}
    </>
  );
};
