import type {
  BookingListData,
  BookingModeType,
  SelectCheckboxOption,
  SeparatedStatuses,
} from "./types";
import type { SearchResponse } from "types/booking";
import type { ActiveExtraStatus, BaseStatus, Status } from "types/status";

export const getOptions = (
  statusList: Status[],
  statusFilter?: SelectCheckboxOption[] | null,
): SelectCheckboxOption[] =>
  statusFilter
    ? statusList.reduce<SelectCheckboxOption[]>(
        (result, status) => (
          statusFilter?.some((option) => option.category === status.category) &&
            result.push({
              value: status.id,
              label: status.name,
              category: status.category,
            }),
          result
        ),
        [],
      )
    : statusList.map((it) => ({
        value: it.id,
        label: it.name,
        category: it.category,
      }));

export const separateStatuses = (allStatuses: Status[]) => {
  const separatedStatuses = allStatuses.reduce<SeparatedStatuses>(
    (result, status) => {
      if (status.is_extra) {
        status.is_active &&
          result.extraStatuses.push(status as ActiveExtraStatus);
        return result;
      }
      if (status.system_name === "WAIT_LIST") {
        result.waitListStatus = status;
        return result;
      }
      result.statuses.push(status);
      return result;
    },
    { statuses: [], extraStatuses: [], waitListStatus: undefined },
  );
  separatedStatuses.extraStatuses.sort(
    (a, b) =>
      b.category.localeCompare(a.category) || a.name.localeCompare(b.name),
  );
  return separatedStatuses;
};

export const isStatusInFilter = (
  filter: SelectCheckboxOption[],
  status: Status,
) => filter.some((option) => option.value === status.id);

/* export const isExtraStatusEnabled = (
  statusId: number,
  extraStatuses: ActiveExtraStatus[]
) => extraStatuses.some((status) => status.id === statusId); */

export const getIncludedStatuses = (
  statuses: Status[],
  statusFilter: SelectCheckboxOption[] | null | undefined,
  waitListStatus?: BaseStatus,
): Status["system_name"][] =>
  statusFilter?.length
    ? statuses.reduce(
        (result, status) => (
          isStatusInFilter(statusFilter, status) &&
            result.push(status.system_name),
          result
        ),
        [waitListStatus?.system_name || "WAIT_LIST"],
      )
    : [waitListStatus?.system_name || "WAIT_LIST"];

export const correctExtraStatusesFilter = (
  extraStatuses: Status[],
  extraStatusFilter: SelectCheckboxOption[],
) =>
  extraStatusFilter.filter((option) =>
    extraStatuses.some((status) => status.id === option.value),
  );

export const filterBookingsListData = (
  data: SearchResponse | undefined,
  filter: SelectCheckboxOption[] | null | undefined,
) => {
  // Инициализация объекта с данными по умолчанию
  const defaultData: BookingListData = {
    bookings: [],
    waitList: [],
    statistics: data?.statistics,
  };

  // Если данные отсутствуют, возвращаем объект с данными по умолчанию
  if (!data) return defaultData;

  // Используем reduce для обхода массива бронирований и применения фильтра или раскидывания в wait list
  return data.bookings.reduce<BookingListData>(
    (result, booking) =>
      (
        //Если это менеджерское бронирование и все столы уже распределены, то не добавляем его никуда
        (booking.seatType === "MANAGEMENT" && !booking.places.length) ||
          // Если статус бронирования WAIT_LIST, добавляем его в массив waitList в объекте result и переходим к следующему элементу booking
          (booking.status?.system_name === "WAIT_LIST" &&
            result.waitList.push(booking)) ||
          // Если фильтр не задан или пуст, добавляем бронирование в массив bookings в объекте result
          // и переходим к следующему элементу booking
          // todo: а при полном фильтре как себя вести?
          (!filter?.length && result.bookings.push(booking)) ||
          // Если экстра статус бронирования соответствует фильтру, добавляем его в в массив bookings в объекте result
          // и переходим к следующему элементу booking
          (booking.extraStatus &&
            isStatusInFilter(filter!, booking.extraStatus) &&
            result.bookings.push(booking)),
        //возвращаем result для следующего элемента в reduce
        result
      ),
    defaultData,
  );
};
export const filterLocalStates = (
  localStatuses: SelectCheckboxOption[],
  statuses: BaseStatus[],
  bookingMode: BookingModeType,
) =>
  bookingMode === "active"
    ? localStatuses.reduce(
        (result, option) => (
          option.category !== "TERMINAL" &&
            statuses.some(
              (status) =>
                status.id === option.value && status.name === option.label,
            ) &&
            result.push(option),
          result
        ),
        Array<SelectCheckboxOption>(),
      )
    : localStatuses.reduce(
        (result, option) => (
          option.category === "TERMINAL" &&
            statuses.some(
              (status) =>
                status.id === option.value && status.name === option.label,
            ) &&
            result.push(option),
          result
        ),
        Array<SelectCheckboxOption>(),
      );
