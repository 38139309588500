import { EDraggableEntity } from "../../../constants";
import { getBookingStartTime } from "../../../utils";
import { TTableSlots } from "../../hall-scheme/redux/HallSchemaV2/NewScheme/utils";
import { isCanDrag } from "./utils";
import { clientShortName } from "common/helpers";
import { useMoveBookingActions } from "features/MoveBooking";
import { useTimelineActions } from "features/Timeline";
import type { SlotEvent } from "features/api/hallschema-api";
import { useDrag, useDrop } from "react-dnd";
import type { Booking } from "types/booking";
import type { BookingTable } from "types/table";

export type TBookingTableTemplateProps = {
  onClick?: () => void;
  tableSlots: TTableSlots[number];
  table: BookingTable;
};

export const useDragNDropTables = (
  slot: SlotEvent | undefined,
  tableId: BookingTable["tableId"],
  tableNumber: BookingTable["tableNumber"],
) => {
  const { setTime } = useTimelineActions();
  const { selectMoveSource, selectSourceTableNumber, addTargetTables } =
    useMoveBookingActions();
  const fullName = clientShortName(
    slot?.booking?.contact || slot?.booking?.client,
  );

  const handleMoveClick = (draggedBooking: Booking) => {
    const bookingTableNumber = draggedBooking.places.map(
      (place) => place.number,
    );
    setTime(getBookingStartTime(draggedBooking));
    draggedBooking.bookingId && selectMoveSource(draggedBooking.bookingId);
    selectSourceTableNumber(bookingTableNumber);
    const payload = {
      tableId: tableId,
      bookingId: draggedBooking?.bookingId,
      tableNumber: tableNumber.toString(),
    };
    addTargetTables(payload);
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [EDraggableEntity.BOOKING_TABLE, EDraggableEntity.BOOKING_CARD],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        booking: monitor.getItem<Booking>(),
        canDrop: true,
      }),
      drop: handleMoveClick,
      canDrop: (booking) => booking.bookingId !== slot?.booking.bookingId,
    }),
    [tableId, slot],
  );

  const [isDragging, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_TABLE,
      canDrag: () => isCanDrag(slot?.booking, tableId),
      item: slot?.booking,
      previewOptions: { captureDraggingState: true },
      collect: (monitor) => monitor.isDragging(),
    }),
    [fullName, slot],
  );
  return {
    isOver,
    drag,
    dragPreview,
    drop,
    isDragging,
  };
};
