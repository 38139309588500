import cn from "classnames";
import { useColorMode } from "hooks/useColorMode";

import { Button, ICONS } from "..";
import styles from "./ModeSwitch.module.scss";

export function ModeSwitch({ className }: { className?: string }) {
  const { setLight, setDark } = useColorMode();

  return (
    <div className={cn(className, styles.root)}>
      <Button className={styles.dark} variant="phantom" onClick={setLight}>
        <ICONS.DarkTheme />
      </Button>
      <Button className={styles.light} variant="phantom" onClick={setDark}>
        <ICONS.LightTheme />
      </Button>
    </div>
  );
}
