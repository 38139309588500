import { Tag } from "components/Tag";
import { useTags } from "features/api/tags";
import { Tag as ITags } from "types/tag";

interface TagsProps {
  tagsIDs: number[];
}

export function Tags({ tags }: { tags: ITags[] }) {
  return (
    <div className="tags">
      {tags.map((t) => (
        <Tag key={t.id} tag={t} />
      ))}
    </div>
  );
}

export function TagsById({ tagsIDs }: TagsProps) {
  const tags = useTags(tagsIDs);
  return <Tags tags={tags} />;
}
