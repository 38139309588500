import { ETranslations } from "../../types/translates";
import { Calendar } from "../ICONS/icons";
import { Input } from "../Input/Input";
import styles from "./DatePicker.module.scss";
import cn from "classnames";
import moment from "moment";
import { Ref, forwardRef, useMemo } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { useIntl } from "react-intl";

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
  onClick?(): void;
  onChange?(): void;
  format?: string;
  customInputClassName?: string;
}

export const DatePickerInput = forwardRef(
  (
    {
      value,
      onClick,
      placeholderText,
      format,
      disabled,
      customInputClassName,
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const intl = useIntl();
    const transformDate = useMemo(() => {
      return value ? moment(value, "DD.MM.YYYY").format(format) : "";
    }, [value]);

    return (
      <>
        <Input
          disabled={disabled}
          inputClassName={cn(styles.input, customInputClassName)}
          placeholder={
            placeholderText ||
            intl.formatMessage({ id: ETranslations.BASE_DATE })
          }
          ref={ref}
          suffix={<Calendar className={styles.inputIcon} />}
          value={transformDate}
          readOnly
          onClick={onClick}
        />
      </>
    );
  },
);
